import React, {useEffect, useState} from 'react';
import { Button } from "semantic-ui-react";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { createDonSasBase, postDonSaS_Card_subscription } from '../../../utils/axios';
import { getUser } from '../../../utils/Common';

const CheckoutForm = ({datas}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [myUrl, setMyUrl]= useState()
  const [isDisable, setisDisable]=useState(true)
  const [isLoading, setIsLoading]=useState(false)

  useEffect(()=>{

    const url = window.location.href
    if(window.location.href.split("/")[3] === "eofy22"){
      console.log(window.location.href.split("/"))
    }
    setMyUrl(url)
    if(!stripe || !elements){
      //element stripe pas encore chargé!
      setisDisable(true)
    }else{
      setisDisable(false)
    }
  },[elements, stripe])

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setisDisable(true)
    setIsLoading(true)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setErrorMessage("Stripe loaded");
      setisDisable(true)
      setIsLoading(true)
      return;
    }else{

      const data ={
        address_contact__c: datas.expo.step1.adress,
        email__c: getUser() ? getUser().email : datas.expo.step1.mail,
        first_name__c: datas.expo.step1.firstName,
        name: datas.expo.step1.lastName,
        montant__c: datas.expo.step1.price,
        country_contact__c: datas.expo.step1.country,
        address__c: datas.billing.address,
        city_contact__c: datas.expo.step1.city,
        city__c: datas.billing.city,
        country__c: datas.billing.country,
        state_contact__c: datas.expo.step1.province,
        state__c: datas.billing.province,
        zip_contact__c: datas.expo.step1.zip,
        zip__c: datas.billing.zip,
       }

      //  createDonSasBase(data)

       const datasf = ""
       const type_pay="Credit Card"
      postDonSaS_Card_subscription(datasf, datas, type_pay, 'Classique', "TECH_backup")

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: myUrl+"success",
        },
      })
  
      if (result.error) {
        setErrorMessage(result.error.message);
        setisDisable(false)
        setIsLoading(false)
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

    }
  };

  return (
    <form onSubmit={handleSubmit}>
          <PaymentElement/>
          <Button disabled={isDisable} loading={isLoading} >Submit</Button>
          
      {/* Show error message to your customers */}
      <div className="error" role="alert">
            <center style={{ color: "red" }}> {errorMessage && <div>{errorMessage}</div>} </center>
      </div>
    </form>
  )
};

export default CheckoutForm;
