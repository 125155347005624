import periodReducer from './reducers/periode';
import priceReducer from './reducers/priceReducer';
import getDataSF from './reducers/getDataSf';
import users from './reducers/user/user';
import getAccount from './reducers/salesforce/getAccount';
import tributeReducer from './reducers/tribute/tributeReducer';
import expoReducer from './reducers/expo/expoReducer';
import feeReducer from './reducers/fees/feesReducer';
import billingReducer from './reducers/billing/billingReducer';
import accountReducer from './reducers/account/accountReducer';
import subscriptionReducer from './reducers/subscription/subscriptionReducer';

import {combineReducers} from 'redux';

const allReducers = combineReducers({
    periode: periodReducer,
    price: priceReducer,
    utils: getDataSF,
    user: users,
    account: getAccount,
    tribute: tributeReducer,
    expo: expoReducer,
    fees: feeReducer,
    billing: billingReducer,
    // eslint-disable-next-line
    account: accountReducer,
    subscription: subscriptionReducer
})

export default allReducers;