import React from 'react'
import { useState } from 'react'

import FormDonsExpo2022 from '../components/Expo2020/FormExpo2022'
import AbonnentExpo2022 from '../components/Expo2020/PayementExpo2022'
import GuestExpo2022 from '../components/Expo2020/GuestExpo2022'
import FooterPage from '../components/inc/FooterPage'
import NavLinkPages from '../components/NavLinkPages'
import { Grid, Image } from 'semantic-ui-react'
import { useEffect } from 'react'

/**
 *
 * Ce composant est un composant apart entière , qui va être appelé en dehors
 * des autres core composant , Il a pour but d'être utiliser lors d'une exposition
 * en mai 2022
 *
 */

const initialStates = {
  step1: {
    isAttend: undefined,
    pax: 0,
    pax_names: [],
  },
  step2: {
    error: null,
    price: ``,
    firstName: '',
    lastName: '',
    mail: '',
    adress: '',
    city: '',
    zip: '',
    province: '',
    country: '',
    isDedicate: false,
    isHonor: null,
    tribute: '',
    mailToNotify: '',
    obligatory: '*',
    isRecurring: false,
    subscription: 'oneTimeGift',
    message: '',
  },
}

const PivotExpo2022 = () => {
  // State pour le pas de formulaire
  const [step, setStep] = useState(0)
  // state pour les valeurs de chaques step
  const [data, setData] = useState(initialStates)

  // Action pour persister les données au parent et passer au page suivante
  const onFormulaireNextPersist = (step, values, nextStep) => {
    setData({ ...data, [step]: values })
    setStep(nextStep)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
  return (
    <>
      <NavLinkPages type={'expo2022'} />
      <div className="guest-expo-2022">
        <Grid className="Grid-guest-expo-2022">
          <Grid.Column className="image-left" width={8}>
            <Image src={require('../../src/public/img/img_pivot.PNG')} />
          </Grid.Column>
          <Grid.Column width={8} className="grid-guest-padding mobilpadding">
            {/* STEP 0 : composant pour les invités */}
            {step === 0 && (
              <GuestExpo2022
                next={onFormulaireNextPersist}
                datas={data.step1}
              />
            )}

            {/* STEP 1 : composant pour les apropos du don*/}
            {step === 1 && (
              <FormDonsExpo2022
                datas={data.step2}
                prev={() => setStep(0)}
                onNext={onFormulaireNextPersist}
              />
            )}

            {/* STEP 2 : composant pour le requete et apropos du payment*/}
            {step === 2 && (
              <AbonnentExpo2022
                prev={() => setStep(0)}
                allDatasOnEveryStep={data}
              />
            )}
          </Grid.Column>
        </Grid>
      </div>
      <FooterPage />
    </>
  )
}

export default PivotExpo2022
