import React from 'react';

const DonationFAQ = () => {
    return (
        <>
                     <div>
                        <h2>Donation FAQs</h2>
            
                            <h5 className='ui header'>Is my donation tax-deductible?</h5>
                            <span>Yes. As a 501(c)(3) not for profit organization, Pivot has not provided any goods or services, 
                                in whole or in part, to you in consideration for this voluntary cash contribution. 
                                Our tax ID / EIN is 46-3075530</span> 

                            <h5 className='ui header'>Can I send a check?</h5>
                            <span>Yes, please make check payable to “Pivot” and send to: <br/>
                                75 N. Main St. #2075<br/>
                                Randolph, MA 02368</span>

                            <h5 className='ui header'>Can I give through stock, or wire transfer?</h5>
                            {/* eslint-disable-next-line */}
                            <span>For wire transfers and gifts of stock, please write us at <a>giving@pivotworks.org</a></span>

                            <h5 className='ui header'>Can I make a recurring donation?</h5>
                            <span>Monthly gifts are some of our most important contributions, because they allow us to sustain this work throughout the year. 
                                To set up, please go to the donate page and click recurring donation.</span>

                            <h5 className='ui header'>Can I give through a donor-advised fund?</h5>
                            <span>If you have a donor-advised fund with Fidelity Charitable, Schwab Charitable, Vanguard, National Philanthropic Trust, or Benevity,
                                 please use the DAF Direct link to automatically submit a recommendation to your advisor. 
                                Otherwise, please contact your financial institution to make a recommendation.</span>

                            <h5 className='ui header'>Can I make a gift through my employer’s giving program?</h5>
                            <span>You could double your impact through a matching gift from your employer. 
                                Contact your company’s Human Resources office to add Pivot to your employee giving platform.</span>

                            <h5 className='ui header'>Can I participate in planned giving?</h5>
                            <span>Thank you for considering a legacy gift helps ensure your support of Pivot goes long into the future, 
                                and has a lasting impact.<br/> <br/>
                                <p>A legacy gift typically refers to a gift that requires estate and/or financial planning. 
                                    You can update your estate or financial plans to include us as a beneficiary of your will, trust, retirement plan,
                                     or life insurance plan, among other options that can be tailored to fit your needs.</p></span>

                            <h5 className='ui header'>Other questions?</h5>
                            {/* eslint-disable-next-line */}
                            <span>Email <a>giving@pivotworks.org</a>  with any donation-related inquiries.</span>
                </div>
        </>
    )
  }
    

export default DonationFAQ;