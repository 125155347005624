const initialState = {
    data: []
}
const expoReducer = (state = initialState, action) =>{
    switch(action.type){
        case 'STEP':
            return action.payload
        default:
            return state
    }
}

export default expoReducer