import React, { Component } from 'react'
import {
  Image,
  Container,
  Button,
  Icon,
  Form,
  Header,
  // Dimmer,
  Loader,
} from 'semantic-ui-react'

import { userAction } from '../redux/actions/user/user'
import store from '../redux/store'

import axios from 'axios'
import { setUserSession } from '../utils/Common'
// import SocialButton from "../components/socialLogin/socialButton";
import {
  getAccountByEmail,
  getUtilsDataByEmail,
  postAccount,
  postStripe,
  generateTokenSocialLogin,
  createUser,
  checkCode6,
  updatePassword,
} from '../utils/axios'
import { Link } from 'react-router-dom'
import ModalPivot from '../components/Stripe/ModalRecuperation'

class ConnectPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      portail: 'PIVOT',
      Username: '',
      Password: '',
      errorEmail: '',
      forget: false,
      message: false,
      code6: null,
      displayError6: 'none',
      showUpdatePassword: false,
      updatePassword: '',
      new_password: '',
      old_password: '',
      confirm_password: '',
      errorPassword: 'none',
      successPasword: 'none',
      isPasswordShow: false,
      typePassword: 'password',
      eyePassword: 'eye slash',
      showpassord: 'Show password',
      errorFormPassword: 'none',
      displayButton: 'block',
    }
  }

  login = () => {
    this.setState({ isLoading: true })
    const data = {
      username: this.state.Username,
      password: window.btoa(this.state.Password),
      portail: process.env.REACT_APP_SITE_TITLE,
    }
    axios
      .post(process.env.REACT_APP_API_URL + '/user/authenticate', {
        username: data.username,
        // password:  window.btoa(data.password),
        password: data.password,
        portail: data.portail,
      })
      .then((response) => {
        const email = response.data.email
        const portail_id = response.data.portail_id
        const security = response.data.securite
        const premier = response.data.premier
        const token = response.headers['session-token']

        getAccountByEmail(data.username).then((res) => {
          if (res.data[0] !== undefined) {
            const account_id = res.data[0].Id
            const data = {
              token,
              email,
              portail_id,
              premier,
              security,
              accountId: account_id,
              name: res.data[0].Name,
            }

            // dispatch(userAction(data))
            // handleUserAction(data)
            store.dispatch(userAction(data))

            setUserSession(token, { ...data })
            this.createCustomerStripe({ email, account_id })
            this.setState({ isLoading: false })
            window.location.href= "/"
          }
        })
        // this.props.history.push(`/`)
        // window.location.href('/')
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            this.setState({
              errorEmail: 'Email not found, Please create an account!',
            })
          }

          if (error.response.status === 400) {
            this.setState({ forget: true })
          }
        }
      })
  }

  handleClickUsername = (event) => {
    this.setState({ Username: event.target.value })
    this.setState({ errorEmail: null })
  }
  handleClickPassword = (event) => {
    this.setState({ Password: event.target.value })
    this.setState({ forget: false })
  }
  handleChange = (event) => {
    const input = event.target
    const value = input.type === 'checkbox' ? input.checked : input.value

    this.setState({ [input.name]: value })
  }

  showErrorMessageLogin = (errorMsg) => {
    this.setState({
      isLoading: false,
      isDisable: false,
      errorEmail: errorMsg
     })
    setTimeout(() => {this.setState({errorEmail: ""});}, 5000);
  }

  forgotPassword = () => {
    const data = {
      username: this.state.Username,
      portail: process.env.REACT_APP_SITE_TITLE,
    }
    if (this.state.forget) {
      getAccountByEmail(this.state.Username).then(async(emailResponse) =>{
        if(emailResponse.data.length >=1){
          //email exist
          axios.post(process.env.REACT_APP_API_URL + '/user/recup', {
            email_recup: data.username,
            portail: data.portail,
          })
          .then(() => this.setState({ message: true }))
          .catch((error) => console.error(error))
        }else{
          //email not exist
          this.showErrorMessageLogin("L'email n'existe pas")
        }
      })
      
    }
    this.setState({ showCode6: true })
  }

  componentDidMount = () => {
    // const rememberMe = localStorage.getItem('rememberMe') === 'true'
    // const user = rememberMe ? localStorage.getItem('user') : ''
    // this.setState({ user, rememberMe })
    // const maVar = window.btoa("test123")
    // console.log(maVar)
  }

  createCustomerStripe = (customer) => {
    getUtilsDataByEmail(customer.email).then((res) => {
      if (res.data.rows.length === 0) {
        postStripe(customer, 'create-customer').then((res) => {
          postStripe(
            {
              account_id: customer.account_id,
              customer_id: res.data.customer.id,
              email: customer.email,
            },
            'create-utils-data',
          )
        })
      }
    })
  }

  responseGoogle = (response) => {
    this.handleSocialLogin({
      Name: response.profileObj.familyName,
      Prenom__c: response.profileObj.givenName,
      Email__c: response.profileObj.email,
      Origine_connecteur__c: 'google',
      accessToken: response.accessToken,
    })
  }

  responseFacebook = (user) => {
    this.handleSocialLogin({
      Origine_connecteur__c: user._provider,
      Name: user._profile.firstName,
      Prenom__c: user._profile.lastName,
      Email__c: user._profile.email,
    })
  }

  handleSocialLogin = (user) => {
    if (user) {
      getAccountByEmail(user.Email__c).then((res) => {
        let accountId = ''
        if (res.data.length === 0) {
          postAccount(user).then((accountPost) => {
            let password = Math.round(
              Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10),
            )
              .toString(36)
              .slice(1)
            createUser({
              email: user.Email__c,
              password,
              Securite__c: accountPost.data.ret.id,
            })
            this.createCustomerStripe({
              email: user.Email__c,
              account_id: accountPost.data.ret.id,
            })
            this.props.history.push(`/new-don/${accountPost.data.ret.id}`)
          })
        } else {
          accountId = res.data[0].Id
          this.createCustomerStripe({
            email: user.Email__c,
            account_id: accountId,
          })
        }

        generateTokenSocialLogin(user).then((response) => {
          setUserSession(response.headers['session-token'], {
            email: user.Email__c,
            accountId,
            name: user.Name + ' ' + user.Prenom__c,
          })
          this.props.history.push(`/new-don/${accountId}`)
        })
        if (accountId !== undefined) {
          this.props.history.push(`/new-don/${accountId}`)
        }
      })
    }
  }

  handleSocialLoginFailure = (err) => {
    console.error(err)
  }

  togglePasswordVisibility = () => {
    const { isPasswordShow } = this.state
    this.setState({ isPasswordShow: !isPasswordShow })
    // this.setState({eyePassword : 'eye slash'})
    if (isPasswordShow === false) {
      this.setState({ eyePassword: 'eye' })
      this.setState({ typePassword: 'text' })
    } else {
      this.setState({ eyePassword: 'eye slash' })
      this.setState({ typePassword: 'password' })
    }
  }

  togglePasswordVisibilityModal = () => {
    const { isPasswordShow } = this.state
    this.setState({ isPasswordShow: !isPasswordShow })
    // this.setState({eyePassword : 'eye slash'})
    if (isPasswordShow === false) {
      this.setState({ showpassord: 'Hide password' })
      this.setState({ typePassword: 'text' })
    } else {
      this.setState({ showpassord: 'Show password' })
      this.setState({ typePassword: 'password' })
    }
  }

  sendEmail = () => {
    const { code6, Username } = this.state
    checkCode6(Username, code6).then((code) => {
      if (code.data !== null) {
        this.setState({ showCode6: false })
        this.setState({ showUpdatePassword: true })
      } else {
        this.setState({ displayError6: '' })
      }
    })
  }

  changeCode6 = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
    this.setState({ displayError6: 'none' })
  }

  submitUpdatePassword = () => {
    const { new_password, confirm_password } = this.state
    if (new_password.match(/[0-9]/) != null) {
      if (new_password.match(/[a-z]/) != null) {
        if (new_password.length > 4) {
          if (new_password === confirm_password) {
            this.setState({ displayButton: 'none' })
            const email = this.state.Username
            const old_password = this.state.code6
            this.setState({ successPasword: '' })
            this.setState({ errorPassword: 'none' })
            updatePassword(email, { old_password, new_password }).then(
              (res) => {
                this.props.history.push(`/login`)
                window.location.reload()
              },
            )
          } else {
            this.setState({ errorPassword: '' })
            this.setState({ successPasword: 'none' })
          }
        } else {
          this.setState({ errorFormPassword: '' })
        }
      } else {
        this.setState({ errorFormPassword: '' })
      }
    } else {
      this.setState({ errorFormPassword: '' })
    }
  }

  changePassword = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
    this.setState({ errorPassword: 'none' })
    this.setState({ errorFormPassword: 'none' })
  }

  render() {
    const { new_password, confirm_password } = this.setState
    return (
      <>
        <ModalPivot
          title="Email sent"
          onClose={() => this.setState({ showCode6: false })}
          show={this.state.showCode6}
        >
          <Form size="large" id="MonForm">
            <Header as="h4" textAlign="center">
              Please enter the 6-digit code sent to your email
            </Header>
            <div className="segment-recup-mdp">
              <Form.Input
                fluid
                icon="lock open"
                iconPosition="left"
                type="code6"
                name="code6"
                value={this.state.code6}
                onChange={this.changeCode6}
                placeholder="6-digit code"
              />
              <p
                style={{
                  display: this.state.displayError6,
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                Invalide code
              </p>
              <Button className="send-code" onClick={this.sendEmail}>
                Send
              </Button>
            </div>
          </Form>
        </ModalPivot>

        <ModalPivot
          title="Change your password"
          onClose={() => this.setState({ showUpdatePassword: false })}
          show={this.state.showUpdatePassword}
        >
          <Form size="large" id="MonForm">
            <div className="segment-recup-mdp">
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                name="new_password"
                value={new_password}
                placeholder="New password"
                onChange={this.changePassword}
                type={this.state.typePassword}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                name="confirm_password"
                value={confirm_password}
                placeholder="Confirm password"
                onChange={this.changePassword}
                type={this.state.typePassword}
              />

              <span
                className="show-pswd"
                onClick={this.togglePasswordVisibilityModal}
              >
                {this.state.showpassord}
              </span>
              <p
                style={{
                  display: this.state.errorPassword,
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                Please enter the same password
              </p>
              <p
                style={{
                  display: this.state.errorFormPassword,
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                Password must contain letter, number and 5+ characters!
              </p>
              <p
                style={{
                  display: this.state.successPasword,
                  color: 'green',
                  textAlign: 'center',
                }}
              >
                Password changed successfully, you can reconnect
              </p>
              <Button
                className="btn waves-effect waves-light mail"
                fluid
                size="large"
                onClick={this.submitUpdatePassword}
                style={{ display: this.state.displayButton }}
              >
                Modify password
              </Button>
            </div>
          </Form>
        </ModalPivot>

        <div className="page-connect">
          <Image
            className="logo-connect"
            src={require('../public/img/pivot-logo.png')}
          />
          <Container text className="container-loug">
            <div className="form-connect">
              <div className="row">
                <h2 className="title-connect">Login</h2>
                <div className="input-group input-group-icon">
                  <input
                    value={this.state.Username}
                    name="Username"
                    type="text"
                    onChange={this.handleClickUsername}
                    placeholder="Email"
                  />
                  <div className="input-icon">
                    <Icon className="user" />
                  </div>
                </div>
                <div className="input-group input-group-icon">
                  <input
                    value={this.state.Password}
                    name="Password"
                    type={this.state.typePassword}
                    onChange={this.handleClickPassword}
                    placeholder="Password"
                    className="required"
                  />
                  <div className="input-icon">
                    <Icon name="lock" />
                  </div>
                  <div className="eye-icon">
                    <Icon
                      onClick={this.togglePasswordVisibility}
                      name={this.state.eyePassword}
                    />
                  </div>
                </div>
              </div>
              <Button className="btn-sign" onClick={this.login}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {this.state.isLoading && (
                    <div style={{ marginRight: '10px' }}>
                      <Loader size="mini" active inline />
                    </div>
                  )}
                  <div>Sign in</div>
                </div>
              </Button>

              {this.state.forget ? (
                <div className="mdp-forget" onClick={this.forgotPassword}>
                  Forgot your password ?
                </div>
              ) : (
                <div
                  className="error-mail"
                  onClick={() => this.props.history.push('/inscription')}
                >
                  {this.state.errorEmail}
                </div>
              )}
            </div>
            <Link to={'/inscription'}>
              <span className="create-acc">
                No account yet? <span> Create your account </span>
              </span>
            </Link>
          </Container>
        </div>
      </>
    )
  }
}

export default ConnectPage
