import React, {useEffect, useState} from 'react';
import { Button } from 'semantic-ui-react';
import {getAccountByEmail } from '../../utils/axios';
import { getUser } from '../../utils/Common';

export default function BankPayment(props) {

    const [last, setLast] = useState("");
    const [routing, setRouting] = useState("");
    // eslint-disable-next-line
    const [firstName, setFirstName] = useState("");
    // eslint-disable-next-line
    const [lastName, setLastName] = useState("");
    // eslint-disable-next-line
    const [active, setActive] = useState(false);
    const email = getUser().email;
    // eslint-disable-next-line
    useEffect(() => {
        setLast(props.last4)
        setRouting(props.routing)

        getAccountByEmail(email).then(responsUser => {
            setFirstName(responsUser.data[0].FirstName)
            setLastName(responsUser.data[0].LastName)
        })
    },[props.last4, props.routing, email])

    const submit = async (evt) => {
        evt.preventDefault();
    }

    return (
            <form id="payment-form" className="col s12" onSubmit={submit}>
                <div className="input-group input-group-icon">
                    <label>Routing number</label>
                    <input type="text" name="RoutingNumber" value={routing} disabled />
                </div>
                <div className="input-group input-group-icon">
                    <label>Account number</label>
                    <input type="text" name="AccountNumber" value={`*******${last}`} disabled/>
                </div>
                <Button className="btn-pay" disabled={active} ><span id="button-text">Pay now</span></Button>
            </form>  
    )
}
