export const getUser = () => {
  return JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
};

// return the token from the session storage
export const getToken = () => {
  if(localStorage.getItem('token') !== null){
    return localStorage.getItem('token') || sessionStorage.getItem('token');
  }
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
};

export const generateTmpPassword = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz-@!#';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
  }
  return result;
}

export const validateEmailAllChar = (mail) =>
{ return /^[a-z0-9]+([_|.-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.-]{1}[a-z0-9]+)*[.][a-z]{2,6}$/.test(mail); }
