/**
 * Public routes
 */
export const ROOT_PAGE = '/autre'
export const LOGIN_PAGE = '/login'
export const REGISTER_PAGE = '/inscription'
export const MAKE_A_DON_PAGE = '/'
export const FAQ_PAGE = '/faqs'
export const ABONNENT_PAGE = '/abonnent'
export const SUCCESS_PAGE = '/success'
export const SUCCESS_PAGE_EOFY = '/eofy22success'
export const ERROR_PAGE = '/error'

/**
 * Private routes
 */

export const HOME_PAGE = '/home'
export const DONATION_INFORMATION = '/donation-information'
export const DONOR_INFORMATION_PAGE = '/donor-information'
export const EDIT_DONOR_INFORTMATION_PAGE = '/update-donor-information'
export const RECEIPE_PAGE = '/receipe'

/**
 * for EVENTS routes
 */
export const EXPO2022_PAGE = '/expo2022'
export const EXPO2023_PAGE = '/expo2023'
export const EOFY22_PAGE = '/eofy22'

/**
 * GENERICS Route
 */

export const GENERICS = '*'
