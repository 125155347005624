import axios from 'axios'
import jwt from 'jsonwebtoken'
import { removeUserSession } from '../../utils/Common'

export const isAuth = () => {
  if (localStorage.getItem('token') === null) {
    return false
  } else {
    return true
  }
}

// A ne pas oublier : Changer le mot secret avec le variable d'environnement process.env.JWT_SECRET
export const getUsersMailFromToken = () => {
  if (localStorage.getItem('token') !== null) {
    try {
      let decoded = jwt.verify(localStorage.getItem('token'), 'P0rt$ls3cr3t')
    console.log(decoded.name)
    return decoded?.email
    } catch (error) {
      if(error.message === "jwt expired"){
        removeUserSession()
      }
    }
    
  }
}

export const getAPIData = (apiUrl) => {
  const api = `${process.env.REACT_APP_API_URL}/api/pivot/${apiUrl?.endpoint}`
  return new Promise((resolve, reject) => {
    axios
      .get(api)
      .then((response) => {
        resolve({
          name: `[DONE] : ${apiUrl?.name}`,
          data: response?.data[0] ? response?.data[0] : {},
        })
      })
      .catch((error) => {
        reject({ name: `[FAILED] : ${apiUrl?.name}`, data: { error } })
      })
  })
}
