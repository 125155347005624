const initialStateUtils = {

    utilsData: []
    
}

export const getDataSF = (state = initialStateUtils, action) => {
    switch (action.type) {
        case 'UTILS':
            return action.payload
        default: return state
    }
}

export default getDataSF;