import React, { Component } from 'react'
import { Button, Icon, Select, Grid, Image } from 'semantic-ui-react'
import NavLinkPages from '../NavLinkPages'
import { updateAccount } from '../../utils/axios'
import {getUser} from '../../utils/Common';
import FooterPage from '../inc/FooterPage'
import SplitFieldsDemo from './UpdateCardInformation'

const countryOptions = [
  { key: 'au', value: 'Australia', text: 'Australia' },
  { key: 'ca', value: 'Canada', text: 'Canada' },
  { key: 'fr', value: 'France', text: 'France' },
  { key: 'gr', value: 'Germany', text: 'Germany' },
  { key: 'as', value: 'Liechtenstein', text: 'Liechtenstein' },
  { key: 'mg', value: 'Madagascar', text: 'Madagascar' },
  { key: 'nz', value: 'New Zealand', text: 'New Zealand' },
  { key: 'sw', value: 'Sweden', text: 'Sweden' },
  { key: 'sd', value: 'Switzerland', text: 'Switzerland' },
  { key: 'tk', value: 'Turkey', text: 'Turkey' },
  { key: 'ug', value: 'Uganda', text: 'Uganda' },
  { key: 'uk', value: 'United Kingdom', text: 'United Kingdom' },
  { key: 'us', value: 'United States', text: 'United States' },
]
class ModifyAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      AccountId: '',
      LastName: '',
      FirstName: '',
      email: '',
      PersonMobilePhone: '',
      PersonMailingStreet: '',
      PersonMailingCity: '',
      PersonMailingState: '',
      PersonMailingCountry: '',
      PersonMailingPostalCode: '',
      DisplayBtn: '',
      setError: null,
      isCardInfoUpdate: false,
    }
  }

  selectCountry = (e, data) => {
    this.setState({ PersonMailingCountry: data.value })
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (this.props.location.aboutProps !== undefined) {
      this.setState({ LastName: this.props.location.aboutProps.datas.lastName })
      this.setState({
        FirstName: this.props.location.aboutProps.datas.firstName,
      })
      this.setState({ email: getUser().email })
      this.setState({
        PersonMobilePhone: this.props.location.aboutProps.datas.phone,
      })
      this.setState({
        PersonMailingStreet: this.props.location.aboutProps.datas.addresse,
      })
      this.setState({
        PersonMailingCity: this.props.location.aboutProps.datas.ville,
      })
      this.setState({
        PersonMailingCountry: this.props.location.aboutProps.datas.country,
      })
      this.setState({
        PersonMailingPostalCode: this.props.location.aboutProps.datas
          .codePostal,
      })
      this.setState({
        AccountId: this.props.location.aboutProps.datas.accountId,
      })
      this.setState({
        PersonMailingState: this.props.location.aboutProps.datas.state,
      })
    } else {
      // this.props.history.push('/login')
    }
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
  }

  handleCheckbox = (evt) => {
    this.setState({ isCardInfoUpdate: !this.state.isCardInfoUpdate })
  }

  modifyAccount = () => {
    const {
      AccountId,
      LastName,
      FirstName,
      PersonMobilePhone,
      PersonMailingStreet,
      PersonMailingCity,
      PersonMailingState,
      PersonMailingCountry,
      PersonMailingPostalCode,
    } = this.state
    if (LastName === '' || FirstName === '') {
      this.setState({ setError: 'fill the fields please' })
      setTimeout(() => {
        this.setState({ setError: '' })
      }, 4000)
    } else {

      updateAccount(
        AccountId,
        LastName,
        FirstName,
        PersonMobilePhone,
        PersonMailingStreet,
        PersonMailingCity,
        PersonMailingState,
        PersonMailingCountry,
        PersonMailingPostalCode,
      ).then(() => {
        this.props.history.push('/donor-information')
      })

      this.setState({ DisplayBtn: 'none' })
    }
  }

  render() {
    const {
      LastName,
      FirstName,
      PersonMobilePhone,
      PersonMailingStreet,
      PersonMailingState,
      PersonMailingCity,
      PersonMailingCountry,
      PersonMailingPostalCode,
    } = this.state

    return (
      <>
        <NavLinkPages routeUrl="new-don" />
        <div className="guest-expo-2022">
          <Grid className="Grid-guest-expo-2022">
            <Grid.Column className="image-left" width={8}>
              <Image src={require('../../../src/public/img/img_pivot.PNG')} />
            </Grid.Column>
            <Grid.Column width={8} className="grid-guest-padding mobilpadding">
              <div className="form-modif-inscription">
                {/* <form > */}
                <div className="row">
                  <h2>Modify Account</h2>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={FirstName}
                      name="FirstName"
                      placeholder="First Name"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="user" />
                    </div>
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={LastName}
                      name="LastName"
                      placeholder="Last Name"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="user" />
                    </div>
                  </div>
                  {/* <div className="input-group input-group-icon"><input type="email" value={email} name="email" placeholder="Email" onChange={this.handleChange} />
                            <div className="input-icon"><Icon className="mail"/></div>
                        </div>   */}
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={PersonMobilePhone}
                      name="PersonMobilePhone"
                      placeholder="Phone"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="phone" />
                    </div>
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={PersonMailingStreet}
                      name="PersonMailingStreet"
                      placeholder="Address"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="home" />
                    </div>
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={PersonMailingCity}
                      name="PersonMailingCity"
                      placeholder="City"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="building" />
                    </div>
                  </div>
                  {/* <div className="input-group input-group-icon">
                                <div className="input-icon"><Icon className="building"/></div><CountryDropdown value={PersonMailingCountry} onChange={(val) => this.selectCountry(val)} className="dropdown-city" />
                        </div> */}

                  <div className="input-group input-group-icon">
                    <Select
                      placeholder="Select your contry"
                      options={countryOptions}
                      value={PersonMailingCountry}
                      onChange={this.selectCountry}
                      className="dropdown-city"
                    />
                    <div className="input-icon">
                      <Icon className="building" />
                    </div>
                  </div>

                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={PersonMailingPostalCode}
                      name="PersonMailingPostalCode"
                      placeholder="Zip Code"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="fax" />
                    </div>
                  </div>
                  <div className="input-group input-group-icon">
                    <input
                      type="text"
                      value={PersonMailingState}
                      name="PersonMailingState"
                      placeholder="State/Province"
                      onChange={this.handleChange}
                    />
                    <div className="input-icon">
                      <Icon className="address book" />
                    </div>
                  </div>
                  <div style={{ marginTop: '20px', marginBottom:'20px'}} >
                    <input
                      rel="update"
                      type="checkbox"
                      className="check-input"
                      id='update'
                      checked={this.state.isCardInfoUpdate}
                      onClick={this.handleCheckbox}
                    />
                    <label htmlFor='update'>Update card information ?</label>
                  </div>
                  {this.state.isCardInfoUpdate && <SplitFieldsDemo />}

                  <div className="msg-error"> {this.state.setError}</div>
                  <Button
                    style={{ display: this.state.DisplayBtn }}
                    className="modifyaccount"
                    fluid
                    onClick={this.modifyAccount}
                  >
                    Modify account
                  </Button>

                  
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>

        <FooterPage />
      </>
    )
  }
}

export default ModifyAccount
