import React, { useEffect, useState } from 'react'
import { Button, Grid, Loader, Divider, Modal, Header, Input } from 'semantic-ui-react'
import axios from 'axios'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { expoAction } from '../../redux/reducers/expo/expoAction'
import { feesAction } from '../../redux/reducers/fees/feesAction'
import Billing from '../Forms/BillingAdress'
import CardSeparated from '../CardPayment/CardCheckout'
import CardPaymentSimple from '../CardPayment/CardPaymentDetails'
// import CardSeparated from '../CardPayment/CardPaymentDetails'
// import CardSavedPayment from '../CardPayment/CardSavedPayment.js'
import { getUser } from '../../utils/Common'
import {
  getDonationByAccountId,
  getAccountByEmail,
  verifySourceCustomer,
  createChargeRib,
  postDonSAS,
  postDonSAS_v2,
  linkBankAccount,
} from '../../utils/axios'
import NumberFormat from 'react-number-format'
import { isAuth } from '../../library/helpers/get_data'

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
// const customerStripeID = localStorage.getItem("userStripeCustomer") || sessionStorage.getItem("userStripeCustomer");

const PayementNewDon = ({ next, allDatasOnEveryStep }) => {
  const expo = useSelector((state) => state)
  const { step1 } = allDatasOnEveryStep
  // const stripe = useStripe();
  const [tab, setTab] = useState(0)
  // eslint-disable-next-line
  const [abonent, setAbonent] = useState()
  // eslint-disable-next-line
  const [customerId, setCustomerId] = useState('')
  const [count, setCount] = useState()

  const [dons, setDons] = useState([])
  const [data, setData] = useState({
    fees: false,
    finalPrice: step1.price,
    isOpenBilling: '',
    toggleState: 1,
  })

  const dispatch = useDispatch()
  const subscriptionState = useSelector((state) => state.subscription)
  dispatch(expoAction(allDatasOnEveryStep))
  // eslint-disable-next-line

  useEffect(() => {
    // subscription
    if (subscriptionState === 'monthlySubscription') {
      setAbonent('month')
      setCount(1)
    }
    if (
      subscriptionState === 'weeklySubscription' ||
      subscriptionState === true
    ) {
      setAbonent('week')
      setCount(2)
    }
    if (subscriptionState === 'quarterlySubscription') {
      setAbonent('quat')
      setCount(3)
    }
    if (subscriptionState === 'annuallySubscription') {
      setAbonent('annual')
      setCount(4)
    }
    if (subscriptionState === false) {
      console.log('simple pay')
    }
    // end subscription
    if (getUser() != null) {
      getAccountByEmail(getUser().email).then((response) => {
        if (response && response?.data) {
          getDonationByAccountId(response.data[0].Id).then((responseDon) => {
            if (responseDon && responseDon?.data && responseDon?.data.length > 0) {
              setDons(
                responseDon.data.sort((a, b) =>
                  a.date_du_don__c > b.date_du_don__c ? 1 : -1,
                ),
              )
            }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [count])

  return (
    <div className="guest-expo-2022s">
      <Grid>
        <Grid.Column>
          <div className="content-tabs">
            <h2>Gift Summary</h2>
            <Resume datas={data} />
            <Fees
              data={data.fees}
              finalPrices={data.finalPrice}
              onFeeing={() => setData(!data.fees)}
            />
            <BillingAdress
              data={data.isOpenBilling}
              openBillAdress={() =>
                setData({ ...data, isOpenBilling: !data.isOpenBilling })
              }
            />
            <div className="card-methd">
              <p className="methd">Payment Details</p>
              <TabMenu changeTab={setTab} />
              <div className="payment-details">
                {
                  tab === 1 ? (
                    !isAuth() ? ((subscriptionState === false) ? <CardPaymentSimple /> : <CardSeparated /> ) : dons?.length === 0 ? ((subscriptionState === false) ? <CardPaymentSimple/> : <CardSeparated />) : (<CardSeparated />)
                    // !isAuth() ? ((subscriptionState === false) ? <CardPaymentSimple /> : <CardSeparated /> ) : dons?.length === 0 ? ((subscriptionState === false) ? <CardPaymentSimple/> : <CardSeparated />) : (<CardSavedPayment datas={expo} prix={data.finalPrice} />)
                  ) : ( tab === 2 ? (<Elements stripe={promise}> <BankForm data={expo} /></Elements>) : <CheckWire data={expo} />
                    // <Elements stripe={promise}> <BankForm data={expo} /></Elements>
                  )
                }
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default PayementNewDon

const Resume = (datas) => {
  const allSteps = useSelector((state) => state.expo)

  // eslint-disable-next-line
  const [data, setData] = useState(datas)
  return (
    <Grid centered columns={2} className="donor-info">
      <Grid.Row>
        <Grid.Column className="title">Donor:</Grid.Column>
        <Grid.Column className="value">{allSteps.step1.firstName}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title">Amount:</Grid.Column>
        <Grid.Column className="value">$ {allSteps.step1.price}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title">Tribute:</Grid.Column>
        <Grid.Column className="value">
          {allSteps.step1.isDedicate ? 'Yes' : 'No'}{' '}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title"></Grid.Column>
        <Grid.Column className="value">
          {allSteps.step1.tribute ? ' In honor of ' : ' '}
          {allSteps.step1.tribute}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const Fees = ({ data, finalPrices, onFeeing }) => {
  const [isFeeing, setIsFeeing] = useState(data)
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(finalPrices.replace('$', '')),
  )

  const dispatch = useDispatch()
  const setStoragePrice = (price) => {
    localStorage.setItem('finalPrices', price)
    sessionStorage.setItem('finalPrices', price)
  }

  useEffect(() => {
    if (isFeeing) {
      const maxi = finalPrice * 0.03 + 0.3
      if (maxi > 10){
        setFinalPrice((finalPrice + 10).toFixed(2))
        dispatch(feesAction((finalPrice + 10).toFixed(2)))
        setStoragePrice((finalPrice + 10).toFixed(2))
      }else{
        setFinalPrice((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2))
        dispatch(feesAction((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2)))
        setStoragePrice((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2))
      }
      
    } else {
      setFinalPrice(parseFloat(finalPrices.replace('$', '')))
      dispatch(feesAction(finalPrices))
      setStoragePrice(finalPrices)
    }
    // eslint-disable-next-line
  }, [isFeeing])

  return (
    <>
      <div>
        <input
          type="checkbox"
          id="fees"
          className="check-input"
          checked={isFeeing}
          onChange={() => setIsFeeing(!isFeeing)}
        />
        <label htmlFor="fees" className="fees">
          <strong style={{ fontWeight: 'bold' }}>
            I would like to help cover processing fees
          </strong>
          <p style={{ fontWeight: '100' }}>
            (3% of gift amount + $0.30 will be added to your total billing
            amount)
          </p>
        </label>
      </div>
      <Grid centered columns={2} className="donor-info">
        <Grid.Row>
          <Grid.Column className="title">Total to be billed:</Grid.Column>
          <Grid.Column className="value">
            $ {parseFloat(finalPrice).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const BillingAdress = ({ data, openBillAdress }) => {
  return (
    <div>
      <input
        rel="billing"
        type="checkbox"
        id="bill"
        className="check-input"
        checked={data}
        onChange={() => openBillAdress()}
      />
      <label htmlFor="bill" className="bill">
        <strong style={{ fontWeight: 'bold' }}>Billing address is different from contact address</strong>{' '}
      </label>
      {data && <Billing />}
    </div>
  )
}


const BankForm = (data) => {
  const stripe = useStripe()
  const step = data
  const [isLoad, setIsLoad] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [errorBank, setErrorBank] = useState(null)
  const [deposit, setDeposit] = useState({
    min:'',
    max:''
  })
  // eslint-disable-next-line
  const [bankId, setBankId] = useState()
  const [bank, setBank] = useState({
    routing: '',
    account: '',
  })
  const [bankUser, setBankUser] = useState()

  const customerStripeID =
    localStorage.getItem('userStripeCustomer') ||
    sessionStorage.getItem('userStripeCustomer')

  useEffect(() => {
  });

  //onSubmit
  const bankPay = async (e) => {
    e.preventDefault()
    const type_pay = "ACH - EFT"
    if (bank.routing === '' || bank.account === '') {
      setErrorBank('the bank number is invalid')
      setTimeout(setErrorBank, 3000)
    }else{
      setIsLoad(true)
      const bankAcc = await axios.get(`https://api.stripe.com/v1/customers/${customerStripeID}/sources`, {
        headers: {Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`}})

      if(bankAcc.data.data.length === 1){
        const chargeRib = await createChargeRib(step.data.fees * 100, 'usd', customerStripeID)
        

        //post to DonSAS
        const postDon = await postDonSAS_v2(step, chargeRib, type_pay)
        setIsLoad(false)
        if(postDon.statusText === "Created"){
          window.location.href = '/success'
        }else{
          window.location.href = '/error'
        }
      }else{
        //create bank account token
        const resultBank = await stripe.createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: bank.routing,
          account_number: bank.account,
          account_holder_name: step.data.expo.step1.firstName,
          account_holder_type: 'individual',
        })
        const tokenId = await resultBank.token.id

        //link customer with bank account
        const bankCustomer = await linkBankAccount(customerStripeID, tokenId)
        await setBankUser(bankCustomer)
        await verifySourceCustomer(customerStripeID, bankUser.data.id)
      const respChargeRib = await createChargeRib(step.data.fees * 100,'usd',customerStripeID)
      const donSasPost = await postDonSAS_v2(step, respChargeRib, "ACH - EFT")
      setIsLoad(false)
      if(donSasPost.statusText === "Created"){
        window.location.href = '/success'
      }else{
        window.location.href = '/error'
      }
      }
    }
    
  }

  return (
    <form id="payment-form" className="col s12" onSubmit={bankPay}>
      <div className="input-group input-group-icon">
        <label>Routing number</label>
        <input
          type="text"
          name="RoutingNumber"
          placeholder="Routing number ( 9 digit number )"
          onChange={(e) => setBank({ ...bank, routing: e.target.value })}
        />
      </div>
      <div className="input-group input-group-icon">
        <label>Account number</label>
        <input
          type="text"
          name="AccountNumber "
          placeholder="Account number ( 000123456789 )"
          onChange={(e) => setBank({ ...bank, account: e.target.value })}
        />
      </div>

      <p style={{ textAlign: 'center', color: '#ee6e73' }}>{errorBank} </p>
      <Button className="btn-pay" disabled={isLoad}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoad && (
            <div style={{ marginRight: '10px' }}>
              <Loader size="mini" active inline />
            </div>
          )}
          <div id="button-text">SUBMIT</div>
        </div>
      </Button>
    </form>
  )
}

const CheckWire = (data) =>{
  
  const step = data
  const [wireAmount, setWireAmount ] = useState()
  const [send, setSend ] = useState(true)
  const [isLoad, setIsLoad ] = useState(false)
  const [isActive, setIsActive ] = useState(true)

  const checkOrWireSubmit = (e) =>{
    e.preventDefault()
    setIsLoad(true)
    if(send){
      // console.log(wireAmount)
      // console.log(step.data.expo.step1.adress)
      console.log(step.data)
      const datas=""
      postDonSAS(
          step.data.expo.step1.address, // addresse,
          getUser() ? getUser().email : step.data.expo.step1.mail,
          // this.state.allDataSteps.mail, // email,
          step.data.expo.step1.firstName, // FirstName,
          step.data.expo.step1.lastName, // LastName,
          wireAmount,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          "Credit Card",
          step.data.billing.country, //billing
          datas,
          datas,
          datas,
          step.data.expo.step1.mailToNotify, // mailNotify,
          step.data.expo.step1.tribute, // tributeName,
          // this.state.allDataSteps.isHonor ? "In Honor Of": "In Memory Of",//Type_of_Tribute__c
          step.data.expo.step1.isDedicate ? (step.data.expo.step1.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
          step.data.expo.step1.isAttend, // Will_you_attend__c
          " ", // Name_of_Guests__c
          " ", // Campaign__c
          step.data.expo.step1.message,
          " ",
          step.data.expo.step1.adress,
          step.data.billing.city,
          step.data.expo.step1.city,
          step.data.expo.step1.country,
          step.data.billing.province,
          step.data.expo.step1.province,
          step.data.billing.zip,
          step.data.expo.step1.zip,
          "",//pax
          "Classique", // Type_formulaire
          "Promesse"
      )
    .then(responseDonSas =>{
      console.log(responseDonSas)
      window.location.href = "/success"
      setIsLoad(false)
    })
    .catch(err=>{console.log(err)})
    }
  }

  const onChangeSend = () =>{
    setSend(!send)
  }

  return (
    <form id="payment-form" className="col s12">
       <div className="input-group input-group-icon" style={{marginBottom:"1em"}}>
      <input
        rel="check"
        type="checkbox"
        id="check"
        className="check-input"
        checked={send}
        onChange={onChangeSend}
      />
      <label htmlFor="check" className="check">
        <strong style={{ fontWeight: 'bold' }}>I will send a check or wire transfer. Please contact me to arrange.</strong>{' '}
      </label>
    </div><Divider hidden/>
    <div className="input-group input-group-icon">
      <label>Pledge amount</label>
            <NumberFormat
              className="input-other"
              thousandSeparator={true}
              placeholder="$ 0,00"
              prefix={'$'}
              value={`$ ${
                parseFloat(wireAmount).toFixed(2)
              }`}
              onValueChange={ (value) =>{ 
                setWireAmount(value.value) 
                console.log(value.value)
                if(value.value > 0){
                  setIsActive(false)
                }else{
                  setIsActive(true)
                }
              } }          
            />
            <label>Please contact us to send check or wire</label>
            {/* <Button className="btn-pay" onClick={checkOrWireSubmit}>Submit</Button> */}
            <Button className="btn-pay" onClick={checkOrWireSubmit} disabled={isActive}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoad && (
                  <div style={{ marginRight: "10px" }}>
                    <Loader size="mini" active inline />
                  </div>
                )}
                <div id="button-text">SUBMIT</div>
              </div>
            </Button>
    </div>
  
  </form>
  )
}

// const TabMenu = ({ changeTab }) => {
//   const [isCreditTab, setIsCreditTab] = useState(true)

//   useEffect(() => {
//     changeTab(isCreditTab ? 0 : 1)
//     // eslint-disable-next-line
//   }, [isCreditTab])
//   return (
//     <div className="bloc-tabs">
//       <div
//         className={isCreditTab ? 'tabs active-tabs' : 'tabs'}
//         onClick={() => setIsCreditTab(!isCreditTab)}
//       >
//         Credit card
//       </div>
//       <div
//         className={!isCreditTab ? 'tabs active-tabs' : 'tabs'}
//         onClick={() => setIsCreditTab(!isCreditTab)}
//       >
//         Bank transfer
//       </div>
//     </div>
//   )
// }

const TabMenu = ({ changeTab }) => {
  // const [isCreditTab, setIsCreditTab] = useState(true)
  const [tabs, setTabs] = useState(1)

  useEffect(() => {
    // changeTab(isCreditTab ? 0 : 1)
    changeTab(tabs)

    // eslint-disable-next-line
  }, [tabs])
  return (
    <div className="bloc-tabs">
      <div
        // className={isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
        className={tabs === 1 ? 'tabs active-tabs' : 'tabs'}
        onClick={() => setTabs(1)}
      >
        Credit card
      </div>
      <div
       className={tabs === 2 ? 'tabs active-tabs' : 'tabs'}
       onClick={() => setTabs(2)}
        // className={!isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
      >
        Bank transfer
      </div>
      <div
        className={tabs === 3 ? 'tabs active-tabs' : 'tabs'}
        onClick={() => setTabs(3)}
        // className={!isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
      >
       Check or Wire
      </div>
    </div>
  )
}