import React, { useEffect, useState } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useSelector } from 'react-redux'

import CheckoutForm from './Checkout/Checkout';
import Chargement from './Checkout/Chargement'
import { createNewSubscription, getAccountByEmail, getUtilsDataByEmail, postAccount, postPaymentIntent, postStripe } from '../../utils/axios';
import { getItemSession, setItemSession } from '../../utils/helpers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const FormStripe = ({allDatas, pi}) =>{

    const options ={
        clientSecret: pi,
        appearance:{
            theme: 'flat',
        }
    }

    // eslint-disable-next-line
    // const options2 = {
    //     clientSecret: pi,
    //     appearance:{
    //         theme: 'night',
    //         labels: 'floating'
    //     }
    // }

    // eslint-disable-next-line
    // const options3 = {
    //     clientSecret: pi,
    //     appearance:{
    //         theme: 'stripe',
    //     }
    // }


    return(
        <Elements stripe={stripePromise} options={options} >
            <CheckoutForm datas={allDatas} pay={options.clientSecret} />
        </Elements>
    )
}

export default function CardCheckout() {
    const[payIntent, setPayIntent]=useState()
    // eslint-disable-next-line
    const[intervals, setIntervals]=useState()
    // eslint-disable-next-line
    const[count, setCount]=useState()
    // eslint-disable-next-line
    const[subPeriiod, setSubPeriod]=useState()

    const sub = useSelector((state) => state.subscription)
    const step = useSelector((state) => state.expo.step1)
    const amount = useSelector((state) => state.fees)
    const allData = useSelector((state) => state)
    
    useEffect(()=>{

        const setAccountStorage = (account) => {setItemSession("userStripeAccount", account)};
        setItemSession("allData", JSON.stringify(allData))

        const setCustomerStorage = (customer) => {
            setItemSession("userStripeCustomer",customer)
        };

        const setPeriode = (period) =>{
            setItemSession("periode", period)
        }

        const setIntervalsCount = (inter, count) =>{
            setItemSession("intervals",inter)
            setItemSession("counts",count)
        }

        const getPeriode = async () =>{
            if ((sub === "weeklySubscription") ||(sub === true)) {
                setIntervals("week")
                setCount(1)
                setIntervalsCount("week",1)
                setSubPeriod('Weekly')
                setPeriode("Weekly")
              }
          
              if (sub === "monthlySubscription") {
                setIntervals("month")
                setCount(1)
                setIntervalsCount("month",1)
                setSubPeriod("Monthly")
                setPeriode("Monthly")
              }
          
              if (sub === "quarterlySubscription") {
                setIntervals("month")
                setCount(3)
                setIntervalsCount("month",3)
                setSubPeriod("Quarterly")
                setPeriode("Quarterly")
              }
          
              if (sub === "annuallySubscription") {
                setIntervals("year")
                setCount(1)
                setIntervalsCount("year",1)
                setSubPeriod("Annually")
                setPeriode("Annually")
              }
          
              if (sub === false) {
                setSubPeriod("One-Time Gift")
              }
        }

        getPeriode()

        const getPaymentIntent = (cusId) =>{
            const datas = {
                productId: process.env.REACT_APP_STRIPE_PRODUCT,
                customerId: cusId,
                amount: amount * 100,
                interval: getItemSession("intervals"),
                count: getItemSession("counts")
                // interval: localStorage.getItem('intervals') || sessionStorage.getItem('intervals'),
                // count: localStorage.getItem('counts') || sessionStorage.getItem('counts')
            }

            if(sub){
                createNewSubscription(datas).then(resp => setPayIntent(resp.data))
            }else if(sub === false){
                //pay simple
                postPaymentIntent(amount, cusId).then(re => setPayIntent(re.data.clientSecret.client_secret))
            }
            
        }

        getAccountByEmail(step.mail).then(resp =>{
            //si email exist
            if(resp.data.length !== 0){
                setAccountStorage(resp.data[0].Id)//id account
                    getUtilsDataByEmail(step.mail).then(dataUtils =>{
                        if(dataUtils.data.rowCount !== 0){
                            setCustomerStorage(dataUtils.data.rows[0].customer_id)
                            getPaymentIntent(dataUtils.data.rows[0].customer_id)
                        }else{
                            //creer customer
                            postStripe({ email: step.mail }, "create-customer").then((userStripe) => {
                                setCustomerStorage(userStripe.data.customer.id)
                                getPaymentIntent(userStripe.data.customer.id)

                                //create utils customer
                            postStripe({
                                account_id: resp.data[0].Id,
                                customer_id: userStripe.data.customer.id,
                                email: step.mail
                                },"create-utils-data")
                            });
                            
                        }
                    })
            }else{
                postAccount({
                    Name: step.lastName,
                    Prenom__c: step.firstName,
                    Email__c: step.mail,
                    Origine_connecteur__c: "portail",
                    Portail_secu__c: step.mail,
                    // contact
                    City_contact__c: step.city,
                    State_contact__c: step.province,
                    Zip_contact__c: step.zip,
                    Country_contact__c: step.country,
                    INDIV_Adresse_1_contact__c: step.adress,
                }).then((responseAccount) => {
                //creer custumer
                    postStripe({ email: step.mail }, "create-customer").then(userStripe => {
                        setCustomerStorage(userStripe.data.customer.id)
                        getPaymentIntent(userStripe.data.customer.id)

                        //create utils customer
                        postStripe({
                            account_id: responseAccount.data.ret.id,
                            customer_id: userStripe.data.customer.id,
                            email: step.mail
                        },"create-utils-data")
                    });
                })
            }
        })
        // eslint-disable-next-line
    },[])

    
    
  return (
    <div>
        {
            ( ((payIntent === undefined) || (allData === undefined)) || (payIntent === "")) ? <Chargement /> : <FormStripe allDatas={allData} pi={payIntent} />
        }
    </div>
  )
}
