import React, { useEffect, useState } from 'react'
import Avatar from '../public/img/user.svg'
import { Menu, Image } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { LOGIN_PAGE, HOME_PAGE } from '../settings/constants'
import logoPivot from '../public/img/pivot-logo.png'

const NavLinkPages = ({ type }) => {
  const [token, setToken] = useState('')

  // eslint-disable-next-line
  useEffect(() => {
    setToken(localStorage?.getItem('token'))
  })

  return (
    <div className="content-menu">
      <Menu secondary className="navbar-loug">
        <Menu.Item>
          <a href="https://www.pivotworks.org/">
            <Image className="logo-top" src={logoPivot} />
          </a>
        </Menu.Item>
        {type !== 'expo2022' && type !== 'expo2023' && (
          <Menu.Item className="menu-items" position="right">
            {!token ? (
              <NavLink to={LOGIN_PAGE}>
                <span>Log in or sign up</span>
              </NavLink>
            ) : (
              <span></span>
            )}
            {token && (
              <NavLink to={HOME_PAGE}>
                <Image className="picto-rond user" src={Avatar} />
              </NavLink>
            )}
          </Menu.Item>
        )}
      </Menu>
    </div>
  )
}

export default NavLinkPages
