import React from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "semantic-ui-react";

const ModalPivot = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className="modal_pivot" >
                <div className="modal_pivot-content" onClick={e => e.stopPropagation()}>
                    <div className="modal_pivot-header">
                        <h4 className="modal_pivot-title">{props.title}</h4>
                       <Icon onClick={props.onClose} name="close"/>
                    </div>
                    <div className="modal_pivot-body">{props.children}</div>
                </div>
            </div>
        </CSSTransition>
    )
};

export default ModalPivot;