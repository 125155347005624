import React, { useState, useEffect } from 'react'
import { Notyf } from 'notyf'
import {
  Grid,
  Segment,
  Image,
  Container,
  Loader,
  Dimmer,
} from 'semantic-ui-react'
import {
  getDonationByAccountId,
  getAccountByEmail,
  getStripeByItem,
  getUtilsDataByEmail,
  postStripe,
  getFiles,
  getDownFiles,
} from '../utils/axios'
import { getUser } from '../utils/Common'
import { fullDateFormat, fullDateFormatWithDay } from '../utils/dateFormater'

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  },
  types: [
    {
      type: 'warning',
      background: 'red',
      icon: {
        className: 'material-icons',
        tagName: 'i',
        text: 'warning',
      },
    },
    {
      type: 'error',
      background: 'indianred',
      duration: 9000,
      dismissible: true,
    },
  ],
})

const DonPage = () => {
  const [customer, setCustomer] = useState('')
  const [dons, setDons] = useState([])
  const [maxAmountDon, setMaxAmountDon] = useState(0)
  const [subscription, setSubscription] = useState([])

  useEffect(() => {
    getUtilsDataByEmail(getUser().email).then((result) => {
      if (result && result.data.rows && result.data.rows.length > 0) {
        setCustomer(result.data.rows[0].customer_id)
        getStripeByItem(
          result.data.rows[0].customer_id,
          'get-subscription',
        ).then((res) => {
          res &&
            res.data &&
            res.data.subscriptions.data.length > 0 &&
            setSubscription(res.data.subscriptions.data[0].items.data[0].plan)
        })
      }
    })
    getAccountByEmail(getUser().email).then((response) => {
      if (response && response.data) {
        setMaxAmountDon(response.data[0].Montant_total_dons__c)
        getDonationByAccountId(response.data[0].Id).then((responseDon) => {
          if (responseDon && responseDon.data && responseDon.data.length > 0) {
            setDons(
              responseDon.data.sort((a, b) =>
                a.date_du_don__c > b.date_du_don__c ? 1 : -1,
              ),
            )
          }
        })
      }
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (subscription && subscription.amount) {
      postStripe(
        { customer: customer, return_url: window.location.href },
        'create-session-portal-user',
      ).then((session) => {
        window.location.href = session.data.session.url
      })
    } else {
      window.location.href = '/new-don'
    }
  }

  return (
    <div className="bg-pattern">
      <Container text className="container-dons">
        {dons.length < 0 && (
          <div className="messages">-- NO DONATION YET --</div>
        )}
        <Grid centered columns={2}>
          <Grid.Row>
            <Grid.Column width={7}>
              <h1>Supporting Pivot Since</h1>
              {console.log("liste des dons: ",dons.length === 0 ? console.log("...") : dons[dons.length - 1])}
              <span className="mount-average">
                {dons &&
                  dons.length > 0 &&
                  fullDateFormat(dons[dons.length - 1].Date_du_don__c)}
              </span>
            </Grid.Column>

            <Grid.Column width={7}>
              <h1>Lifetime Giving Total</h1>
              <span>$ {(maxAmountDon).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row doubling="true" className="row-two-columns">
            <Grid.Column className="column-two number-donated">
              <h1>Active Subscriptions</h1>
              <p className="mnt-abn">
                {subscription && subscription.amount ? (
                  `$${subscription.amount / 100}/${subscription.interval}`
                ) : (
                  <span className="no-sub">You have no subscription yet !</span>
                )}
              </p>
              <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                <button type="submit" className="btn-abn">
                  Manage
                </button>
              </form>
              {/* <span className="nbr-years">
                        {this.state.giftNb ? this.state.giftNb : '0'}
                      </span> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <DonHistory dons={dons} />
      </Container>
    </div>
  )
}

export default DonPage

const DonHistory = ({ dons }) => {

  const [loadingFile, setLoadingFile] = useState({
    don: '',
    isLoad: false,
  })

  const handleClickDownloadFiles = (id) => {
    setLoadingFile({ don: id, isLoad: true })
    getFiles(id).then((responseDownFiles) => {
      if (responseDownFiles.data.length > 0) {
        const {
          Title,
          FileType,
          Id,
        } = responseDownFiles.data[0].ContentDocument
        getDownFiles(Title, FileType, Id).then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          setLoadingFile({ don: '', isLoad: false })
        })
      } else {
        notyf.error({
          message: 'There are no files!',
          duration: 9000,
          icon: false,
        })
      }
    })
  }

  return (
    <Grid className="list-gift paye">
      <h1>Giving History:</h1>
      {dons.length === 0 && (
        <div className="messages"> -- NO LETTER RECEIPT --</div>
      )}
      {dons.map(({ Id, Date_du_don__c, Montant__c }) => (
        <Grid.Row className="list-down" key={Id}>
          <Segment className="segment list-picto-paye">
            <span className="date-mount">
              <h4 className="date-list-gift">
                {fullDateFormatWithDay(Date_du_don__c)}
              </h4>
              <span className="mount-gift">$ {Montant__c}</span>
            </span>
            <div className="down" onClick={() => handleClickDownloadFiles(Id)}>
              <>
                <span>DOWNLOAD RECEIPT</span>
                {!(loadingFile.don === Id) ? (
                  <Image
                    className="picto-rond"
                    src={require('../public/img/download.svg')}
                    style={{ width: '17px', marginLeft: '5px' }}
                  />
                ) : (
                  <Dimmer
                    active
                    inverted
                    style={{ width: '12px', position: 'relative' }}
                  >
                    <Loader size="mini" inline className="picto-rond" />
                  </Dimmer>
                )}
              </>
            </div>
            {/* {!loadingFile.isLoad ? (
              <div
                className="down"
                onClick={() => handleClickDownloadFiles(Id)}
              >
                
              </div>
            ) : (
              loadingFile.don == Id && 
            )} */}
          </Segment>
        </Grid.Row>
      ))}
    </Grid>
  )
}
