import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import allReducer from './index'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [allReducer],
}

const pReducer = persistReducer(persistConfig, allReducer)
const middleware = [thunk]
const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)
const persistor = persistStore(store)

export default (persistor, store)
// export default store
