import React, { Component } from 'react';
import { Container, Image } from 'semantic-ui-react';
import InscriptionForm from '../components/Forms/InscriptionForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
class Inscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
     
    }


    render() {
        return(

            <>  
            <div className="content-inscription">
            <Image className="logo-connect"  src={require('../public/img/pivot-logo.png')} />
                <Container text className='content-inscription-center'>
                    <Elements stripe={promise}>
                        <InscriptionForm />
                    </Elements>
                </Container>
            </div>
            </>

        )
    }

}

export default Inscription
