
const initialState = {

    account: []
    
}

export const getAccount = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ACCOUNT':
            return {
                ...state,
                account: action.payload
            }
        default: return state
    }
}

export default getAccount;