import React, { useState } from "react";
import {Icon,  Select} from "semantic-ui-react";
import { useDispatch } from 'react-redux';

import billingAction from '../../redux/reducers/billing/billingAction';

const countryOptions = [
  { key: 'au', value: 'Australia', text: 'Australia' },
  { key: 'ca', value: 'Canada', text: 'Canada' },
  { key: 'ch', value: 'Chile', text: 'Chile' },
  { key: 'fl', value: 'Finland', text: 'Finland' },
  { key: 'fr', value: 'France', text: 'France' },
  { key: 'gr', value: 'Germany', text: 'Germany' },
  { key: 'gb', value: 'Gibraltar', text: 'Gibraltar' },
  { key: 'ir', value: 'Ireland', text: 'Ireland' },
  { key: 'jr', value: 'Jersey', text: 'Jersey' },
  { key: 'li', value: 'Liechtenstein', text: 'Liechtenstein' },
  { key: 'ma', value: 'Madagascar', text: 'Madagascar' },
  { key: 'nz', value: 'New Zealand', text: 'New Zealand' },
  { key: 'sa', value: 'South Africa', text: 'South Africa' },
  { key: 'se', value: 'Sweden', text: 'Sweden' },
  { key: 'sw', value: 'Switzerland', text: 'Switzerland' },
  { key: 'tu', value: 'Turkey', text: 'Turkey' },
  { key: 'ug', value: 'Uganda', text: 'Uganda' },
  { key: 'uk', value: 'United Kingdom', text: 'United Kingdom' },
  { key: 'us', value: 'United States', text: 'United States' },
]
const Billing = () => {

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('')

    const dispatch = useDispatch();

    const billingData = {
        address: address,
        city: city,
        zip: zip,
        province: province,
        country: country
    }

    const selectCountry = (e,val) => { 
        setCountry(val.value);
        billingData.country = val.value;
        dispatch(billingAction(billingData))
        setBillingSession(billingData)
    }

    const setBillingSession = (data) =>{
        sessionStorage.setItem("billing", JSON.stringify(data));
        localStorage.setItem("billing", JSON.stringify(data));
    }

    



    return (
   
                
        <form className="billing">
            <h2>Billing Address</h2>
            <div className="input-group input-group-icon"><input type="text" name="address" placeholder="Address" value={address} onChange={e =>{
                setAddress(e.target.value);
                billingData.address = e.target.value;
                dispatch(billingAction(billingData))
                setBillingSession(billingData)
            } }/>
                <div className="input-icon"><Icon className="home"/></div>
            </div>
            
            <div className="input-group input-group-icon"><input type="text" name="city" placeholder="City" value={city} onChange={e => {
                setCity(e.target.value);
                billingData.city = e.target.value
                dispatch(billingAction(billingData))
                setBillingSession(billingData)
            }} />
                <div className="input-icon"><Icon className="industry"/></div>
            </div>

            <div className="input-group input-group-icon"><input type="text" name="province" placeholder="State/Province" value={province} onChange={e => {
                setProvince(e.target.value);
                billingData.province = e.target.value
                dispatch(billingAction(billingData))
                setBillingSession(billingData)
                }}/>
                <div className="input-icon"><Icon className="cloud"/></div>
            </div>

            <div className="input-group input-group-icon"><input type="text" name="zip" placeholder="Zip code" value={zip} onChange={e => {
                setZip(e.target.value);
                billingData.zip = e.target.value
                dispatch(billingAction(billingData))
                setBillingSession(billingData)
                }}/>
                <div className="input-icon"><Icon className="fax"/></div>
            </div>

            <div className="input-group input-group-icon">
                <Select placeholder='Select your contry' options={countryOptions} value={country} onChange={selectCountry} className="dropdown-city" />   
                <div className="input-icon"><Icon className="building"/></div>
            </div>
        </form>
        
    );
};

export default Billing;