import React, { useState } from "react";
import { Button, Icon} from 'semantic-ui-react';
import {getAccountByEmail, createUser, getUserPortail} from "../../utils/axios";
import { removeUserSession, validateEmailAllChar} from '../../utils/Common';
import { useHistory } from 'react-router-dom';
import "../Stripe/global.css";

export default function CheckoutForm() {

  const [error, setError] = useState(null); 
  const [success, setSuccess] = useState(null); 
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');  
  const [type, setType] = useState('password');
  const [typerepeat, setRepeat] = useState('password');
  const [display, setDisplay] = useState('block');

  let history = useHistory();

  // const portail_id = process.env.REACT_APP_PORTAIL_ID;
  // const profil_id = process.env.REACT_APP_PROFIL_ID;

  const validPswd = (evt) => {
    setPassword(evt.target.value)
  setError(null)
  }

  const handleSubmit = async ev => {
    setDisplay('none'); 
    ev.preventDefault();
    
    if( email ==='' || password === '' || repeatPassword === ''){
      setError("Please fill in all fields!"); setTimeout(setError, 4000);
    }else{

      if (password.match(/[0-9]/) !=null)  {
        if (password.match(/[a-z]/) !=null)  {
          if (password.length > 4)  {
            if(password === repeatPassword){

              //hash password ! and send to SF

              getAccountByEmail(email).then(res => {
              if (res.data.length === 0) {
                      setError(''); removeUserSession();
                      if(!validateEmailAllChar(email)){
                        setError("Invalid email!");setTimeout(setError, 9000);
                        setDisplay('');
                      }
                      setError("Account not exists"); setTimeout(setError, 4000); setDisplay('');
                  //     else{
                  //       // createUser({ email, password, Securite__c: email, portail__c: "a04AM000001axFmYAI", profil__c: "a051v00000y1cX9AAI" }).then(() => {});
                  // //       postAccount({ Name: lastName, Prenom__c: firstName, Email__c: email, Origine_connecteur__c: "portail", Portail_secu__c: email }).then(res => {
                  // //       setUserSession(email, { email } );
                  // //     history.push('/login');
                        
                  // // });
                        
                  //       setDisplay('none');
                  //       setSuccess("Successful registration, you can reconnect"); setTimeout(setSuccess, 10000);
                  //       history.push('/login');
                  //     }
                  
                }else{
                  
                  getUserPortail(email,'PIVOT').then(res =>{
                    if( (res.data.length !== 1) || (res.data.length === undefined) || (res.data.length === null)){
                      createUser({ email, password, Securite__c: email, portail__c: "a041v00001ZWPKmAAP", profil__c: "a051v00000y1cX9AAI" }).then(() => {
                        setDisplay('none');
                        setSuccess("Successful registration, you can reconnect"); setTimeout(setSuccess, 9000);
                        history.push('/login');
                      });
                      
                    }else{
                      setError("Email already exists"); setTimeout(setError, 4000); setDisplay('');
                    }
                  })

                }
            })
            .catch( ()=>{
              setError("Account not exist"); setTimeout(setError, 4000); setDisplay('');
            })
            }else{
              setError("the password is not the same!"); setTimeout(setError, 4000); setDisplay('');
            }
           }else{
             setError("Password must contain letter, number and 5+ characters!"); setDisplay('');
           };
         }else{
           setError("Password must contain letter, number and 5+ characters!"); setDisplay('');
         };
       }else{
         setError("Password must contain letter, number and 5+ characters!"); setDisplay('');
       };
    }

  };
  return (
        <>
            <div className="form-inscription">
              <form onSubmit={handleSubmit}>
                  <div className="row">
                     <h2>Registration</h2>
                     
                        {/* <div className="input-group input-group-icon"><input type="text" name="firstName" value={firstName} placeholder="First Name " onChange={e => setFirstName(e.target.value)} />
                            <div className="input-icon"><Icon className="user"/>*</div>
                        </div>
                        <div className="input-group input-group-icon"><input type="text" name="lastName" value={lastName} placeholder="Last Name" onChange={e => setLastName(e.target.value)} />
                            <div className="input-icon"><Icon className="user"/>*</div>
                        </div> */}
                        <div className="input-group input-group-icon"><input type="email" name="email" value={email} placeholder="Email" onChange={e => setEmail(e.target.value)} />
                            <div className="input-icon"><Icon className="mail"/>*</div>
                        </div>

                        <div className="input-group input-group-icon"><input type={type} name="password" value={password} placeholder="Password" onChange={validPswd} />
                          <div className="input-icon"><Icon className="lock"/>*</div>
                          <div className="icon-psd"><Icon name =  {type === 'text' ? 'eye' : 'eye slash'} onClick={() => setType(type === 'text' ? 'password' : 'text')}/></div>
                        </div>
                        
                        <div className="input-group input-group-icon"><input type={typerepeat} name="password" value={repeatPassword} placeholder="Repeat Password" onChange={e => setRepeatPassword(e.target.value)} />
                          <div className="input-icon"><Icon className="lock"/>*</div>
                          <div className="icon-psd"><Icon name =  {typerepeat === 'text' ? 'eye' : 'eye slash'} onClick={() => setRepeat(typerepeat === 'text' ? 'password' : 'text')}/></div>   
                        </div>
                   </div>  
                   <div className="msg-error">{error} </div>  
                   <div className="msg-success">{success}</div> 
                  <Button style ={{ display: display }} className="btn-pay" >Register</Button> 
              </form>
            </div>
        </>
  );
}
