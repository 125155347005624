import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Abonnent from "./Payment";
import NavLinkPages from '../components/NavLinkPages';
import FooterPage from '../components/inc/FooterPage';
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class ElementStripeAbonnent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        return(
            <div className="content-dons">
                <NavLinkPages/>
                    <Grid text className="container-abonnent2">
                        <Elements stripe={promise}>
                            <Abonnent history={this.props.history}/>
                        </Elements>
                    </Grid>
                    <FooterPage/>
                </div>
        )
    }

}

export default ElementStripeAbonnent
