const priceReducer = (state = 0, action) =>{
    switch(action.type){
        case 'FEES':
            return state 
        case 'NO_FEES':
            return action.payload
        default:
            return state
    }
}

export default priceReducer;