import React, { useState, useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import FormNewDon from '../components/NewDon/FormNewDon'
import PayementNewDon from '../components/NewDon/PayementNewDon'

const initialStates = {
  step1: {
    error: null,
    price: ``,
    mail: '',
    adress: '',
    city: '',
    zip: '',
    province: '',
    country: '',
    isDedicate: false,
    isHonor: null,
    tribute: '',
    mailToNotify: '',
    obligatory: '*',
    isRecurring: false,
    subscription: 'OneTimeGift',
    message: '',
  },
}
const NewDon = () => {
  // State pour le pas de formulaire
  const [step, setStep] = useState(0)
  // state pour les valeurs de chaques step
  const [data, setData] = useState(initialStates)

  // Action pour persister les données au parent et passer au page suivante
  const onFormulaireNextPersist = (step, values, nextStep) => {
    setData({ ...data, [step]: values })
    setStep(nextStep)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })

  return (
    <>
      <div className="guest-expo-2022 newdon">
        <Grid className="Grid-guest-expo-2022">
          <Grid.Column
            className="image-left"
            width={8}
            style={{ position: 'fixed' }}
          >
            <Image src={require('../../src/public/img/img_pivot.PNG')} fluid/>
          </Grid.Column>
          <Grid.Column width={8} className="grid-guest-padding mobilpadding">
            {step === 0 && (
              <FormNewDon datas={data.step1} onNext={onFormulaireNextPersist} />
            )}
            {step === 1 && (
              <PayementNewDon
                prev={() => setStep(0)}
                allDatasOnEveryStep={data}
              />
            )}
          </Grid.Column>
        </Grid>
      </div>
    </>
  )
}

export default NewDon
