import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Table, Button } from 'semantic-ui-react'
import { getAccountByEmail, getOneCard, getUtilsDataByEmail } from '../../utils/axios'
import { getUser } from '../../utils/Common'

const FormAccount = () => {
  let history = useHistory()
  const userStock = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState({
    accountId: userStock.accountId,
    prenom: userStock.name.split(' ')[0],
    nom: userStock.name.split(' ')[1],
    email: userStock.email,
    phone: '',
    Address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    last4:'',
  })
  const [accountId, setAccountId] = useState();
  const [codePostal, setCodePostal] = useState();
  const [ville, setVille] = useState();
  const [addresse, setAddresse] = useState();
  const [country, setCountry] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [state, setState] = useState();
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (getUser() !== null) {
      const mail = getUser().email
      // getUtilsDataByEmail
      getUtilsDataByEmail(getUser().email).then(res => {
        if(res.data.rowCount > 0) {
        // setUser({customer: res.data.rows[0].customer_id})
        getOneCard(res.data.rows[0].customer_id).then(respCard => {
          try {
            setUser({last4: respCard.data.data[0].last4})
          } catch (error) {
            setUser({last4: "4242"})
          }
        })
        }
      })

      getAccountByEmail(mail).then((response) => {
        // setUser(response)
        const usr = response.data[0]
        setAccountId(usr.Id)
        setCodePostal(usr.Zip_contact__c)
        setVille(usr.City_contact__c)
        setAddresse(usr.INDIV_Adresse_1_contact__c)
        setCountry(usr.Country_contact__c)
        setFirstName(usr.FirstName)
        setLastName(usr.LastName)
        setState(usr.State_contact__c)
        setPhone(usr.PersonMobilePhone)
        // const {
        //   Id,
        //   LastName,
        //   FirstName,
        //   PersonMobilePhone,
        //   AD_Ville__c,
        //   Country__c,
        //   Addressee__c,
        //   State__c,
        //   AD_Code_Postal__c,
        // } = response.data[0]
        // setUser({
        //   accountId: Id,
        //   prenom: LastName,
        //   nom: FirstName,
        //   email: mail,
        //   phone: PersonMobilePhone,
        //   Address: Addressee__c,
        //   city: AD_Ville__c,
        //   state: State__c,
        //   country: Country__c,
        //   postalCode: AD_Code_Postal__c,
        // })
      })
    } else {
      history.push(`/login`)
    }
    // eslint-disable-next-line
  }, [])

  const AccountDetails ={
    accountId,
    codePostal,
    ville,
    addresse,
    country,
    firstName,
    lastName,
    state,
    phone
  }

  return (
    <div className="bg-formaccount">
      <Container text className="content-account">
        <div className="info-account">
          <div className="head-modif-account">
            <h2>Account Details</h2>
          </div>
          <Table unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell>First Name</Table.Cell>{' '}
                <Table.Cell>{firstName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Last Name</Table.Cell>{' '}
                <Table.Cell>{lastName} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>{' '}
                <Table.Cell>{getUser().email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Phone</Table.Cell>{' '}
                <Table.Cell> {phone} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Address</Table.Cell>{' '}
                <Table.Cell> {addresse} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>City/Town</Table.Cell>{' '}
                <Table.Cell> {ville} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>State</Table.Cell>{' '}
                <Table.Cell> {state} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Zip</Table.Cell>{' '}
                <Table.Cell> {codePostal} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Country</Table.Cell>{' '}
                <Table.Cell> {country} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Default Payment Method </Table.Cell>{' '}
                <Table.Cell>
                  {(user.last4 !== undefined ) ? "**** **** **** **"+user.last4:"**** **** **** **4242"}
                  <Link 
                  to={{ 
                    pathname: '/update-card-information'
                    }}>
                    {/* <span className="btn-abn"> (Edit Account Details)</span> */}
                  </Link>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="btn-donorinfo">
            <Link
              to={{
                pathname: '/update-donor-information',
                aboutProps: {
                  datas: { ...AccountDetails },
                },
              }}
            >
              <Button className="btn-abn">Edit Account Details</Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FormAccount
