
const tributeYes = (state = false, action) =>{
    switch(action.type){
        case 'TRIBUTE_YES':
            return true
        default:
            return state
    }
}

export default tributeYes