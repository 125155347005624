import React, { useEffect, useState } from 'react'
import { Button, Placeholder, Loader } from 'semantic-ui-react'
import { useSelector } from "react-redux";
import {
  ConfirmSubscription,
  getAccountByEmail,
  getOneCard,
  paymentIntentSavedCard,
  postDonSAS,
  getUtilsDataByEmail,
  postStripe,
  createPI,
  updateAccount
} from '../../utils/axios'
import { getUser } from '../../utils/Common'
import { useHistory } from 'react-router-dom'
import Axios from 'axios';

export default function CardSavedPayment(props) {
  const [card, setCard] = useState('')
  // eslint-disable-next-line
  const [address, setAddress] = useState('')
  // eslint-disable-next-line
  const [Name, setName] = useState('')
  // eslint-disable-next-line
  const [firstName, setFirstName] = useState('')
  // eslint-disable-next-line
  const [email, setEmail] = useState(getUser().email)
  // eslint-disable-next-line
  const [active, setActive] = useState(false)
  // eslint-disable-next-line
  const [payBtn, setPayBtn] = useState('PAY')
  const [customerId, setCustomerId] = useState('')
  const [count, setCount] = useState(null)
  const [period, setPeriod] = useState(null)
  // eslint-disable-next-line
  const [subPeriod, setSubPeriod] = useState(null)
  // eslint-disable-next-line
  const [priceID, setPriceID] = useState(null)
  const [isLoad, setIsLoad] = useState(false);
  const [accountID, setAccountID] = useState();

  let history = useHistory()
  // let location = useLocation()
  const allDataStep = useSelector((state) => state);

  const getMyCard = async (customer) =>{
    const Carte = await getOneCard(customer)
    console.log("ma carte: ",Carte)
    return Carte;
  }

  // eslint-disable-next-line
  useEffect(() => {
    console.log("saved payment")
    if ( props.datas.subscription || props.datas.subscription === "weeklySubscription") {
      setPeriod("week")
      setCount(1)
      setSubPeriod("Weekly")
    }

    if (props.datas.subscription === "monthlySubscription") {
      setPeriod("month")
      setCount(1)
      setSubPeriod("Monthly")
    }

    if (props.datas.subscription === "quarterlySubscription") {
      setPeriod("month")
      setCount(3)
      setSubPeriod("Quarterly")
    }

    if (props.datas.subscription === "annuallySubscription") {
      setPeriod("year")
      setCount(1)
      setSubPeriod("Annually")
    }

    if (props.datas.subscription === false) {
      setSubPeriod("One-Time Gift")
    }

    if (getUser() !== null) {
      setName(getUser().name)

      getAccountByEmail(email).then(
        (responsUser) => {
          console.log("get account by email saved! ",responsUser)
          setAddress(responsUser.data[0].State__c)
          setFirstName(responsUser.data[0].FirstName)
        },
        [getUser().email, props.client],
      )

      getUtilsDataByEmail(email).then((res) => {
        sessionStorage.setItem('customerId', JSON.stringify(res.data.rows[0].customer_id));
        localStorage.setItem('customerId', res.data.rows[0].customer_id);

        console.log("get utils saved! ",res)
        setCustomerId(res.data.rows[0].customer_id)

        // Axios.post("https://avenirgreen-portail-dev.herokuapp.com/stripe/getStripeCustomerByEmail/public",{email: email})
        // .then(data => {
        //   console.log('data axios:', data.data.data[0])
        //   // setCustomerId
        //   getOneCard(data.data.data[0].id).then(responseCustomerID =>{
        //     console.log('responseCustomerID:', responseCustomerID.data.data[0].card.last4)
        //     setCard(responseCustomerID.data.data[0].card.last4)
        //   })
        // })

        
        getOneCard(res.data.rows[0].customer_id).then((responseCard) => {
          console.log("get one card saved! ",responseCard)
          console.log(responseCard)
          
          if (
            responseCard.data.length > 0 &&
            responseCard.data.length !== undefined
          ) {
            if (responseCard.data[0].card !== undefined) {
              setCard(responseCard.data[0].card.last4)
            } else {
              setCard(responseCard.data.data[0].last4)
            }
            // setCard(responseCard.data.data[0].last4)
          } else {
            setActive(true)
            setPayBtn('GO TO BANK TRANSFERT')
          }
        })
        // getMyCard(idCust)
      })
    }
    // eslint-disable-next-line
  }, [card])

  const payment = async (evt) => {
    evt.preventDefault()
    setIsLoad(true)
    console.log("loading active...")
    if (getUser() === null || getUser() === undefined) {
      // USER NOT CONNECTED
    }else{
      // USER CONNECTED
      getAccountByEmail(email).then((response) => {
        if (response.data.length !== 0) {
          setAccountID(response.data[0].Id); //id account
        }
      })


      console.log("testing email with step1 ",allDataStep.expo.step1.mail)
      if( email !== allDataStep.expo.step1.mail){
        updateAccount(
          accountID,
          allDataStep.expo.step1.lastName, 
          allDataStep.expo.step1.firstName,
          " ", //phone
          allDataStep.expo.step1.adress,
          allDataStep.expo.step1.city, 
          allDataStep.expo.step1.province, 
          allDataStep.expo.step1.country, 
          allDataStep.expo.step1.zip, 
          allDataStep.expo.step1.mail
        ).then(respUpdateAccount =>{
          console.log('respUpdateAccount:', respUpdateAccount)
        })
      }
    }

    if (props.datas.subscription !== false) {
      console.log("subscription active...")
      console.log("prix: ",props.prix * 100,)
      postStripe(
        {
          productId: process.env.REACT_APP_STRIPE_PRODUCT,
          price: props.prix * 100,
          currency: "usd",
          period: period,
          count: count,
        },
        "create-price"
      ).then((resultCreatePrice) => {
        console.log("create price success!")
        setPriceID(resultCreatePrice.data.result.id)
        createPI(
          resultCreatePrice.data.result.id,
          customerId
        ).then((respPI) => {
          console.log("create pi success! ", respPI)
          ConfirmSubscription(respPI.data.clientSecret.id).then(responseConfirm =>{
            const datas = responseConfirm.data.paymentIntent.charges.data[0]
            console.log("les datas: ",datas)
            console.log("allDataStepsss: ", allDataStep)
            postDonSAS(
              allDataStep.billing.address, 
              getUser().email,
              // allDataStep.expo.step1.mail, 
              allDataStep.expo.step1.firstName, 
              allDataStep.expo.step1.lastName, 
              props.prix,
              datas.id,
              datas.object,
              datas.balance_transaction,
              datas.calculated_statement_descriptor,
              datas.currency,
              datas.customer,
              datas.description,
              datas.paid,
              "Credit Card",
              allDataStep.billing.country,
              datas.payment_method_details.card.brand,
              datas.receipt_url,
              datas.status,
              allDataStep.expo.step1.mailToNotify, 
              allDataStep.expo.step1.tribute, // tributeName,
              allDataStep.expo.step1.isDedicate ? allDataStep.expo.step1.isHonor ? "In Honor Of" : "In Memory Of" : " ",
              allDataStep.expo.step1.isAttend, // Will_you_attend__c
              " ", // Name_of_Guests__c
              " ", // Campaign__c
              allDataStep.expo.step1.message,
              subPeriod,//period
              allDataStep.expo.step1.adress,
              allDataStep.billing.city,
              allDataStep.expo.step1.city,
              allDataStep.expo.step1.country,
              allDataStep.billing.province,
              allDataStep.expo.step1.province,
              allDataStep.billing.zip,
              allDataStep.expo.step1.zip,
              " ", //pax
              "Classique ",//Type_formulaire
              "Payé"
            ).then(()=>{
              setIsLoad(false)
              history.push('/success')
            })
            .catch(err=>{
              setIsLoad(false)
              history.push('/error')
            })
            // postDonSAS(
            //   allDataStep.billing.address, 
            //   getUser().email,
            //   // allDataStep.expo.step1.mail, 
            //   allDataStep.expo.step1.firstName, 
            //   allDataStep.expo.step1.lastName, 
            //   props.prix,
            //   datas.id,
            //   datas.object,
            //   datas.balance_transaction,
            //   datas.calculated_statement_descriptor,
            //   datas.currency,
            //   datas.customer,
            //   datas.description,
            //   datas.paid,
            //   "Credit Card",
            //   allDataStep.billing.country,
            //   datas.payment_method_details.card.brand,
            //   datas.receipt_url,
            //   datas.status,
            //   allDataStep.expo.step1.mailToNotify, 
            //   allDataStep.expo.step1.tribute, // tributeName,
            //   allDataStep.expo.step1.isDedicate ? allDataStep.expo.step1.isHonor ? "In Honor Of" : "In Memory Of" : " ",
            //   " ", // Will_you_attend__c
            //   " ", // Name_of_Guests__c
            //   " ", // Campaign__c
            //   allDataStep.expo.step1.message,
            //   " ",//period
            //   allDataStep.expo.step1.adress,
            //   allDataStep.billing.city,
            //   allDataStep.expo.step1.city,
            //   allDataStep.expo.step1.country,
            //   allDataStep.billing.province,
            //   allDataStep.expo.step1.province,
            //   allDataStep.billing.zip,
            //   allDataStep.expo.step1.zip,
            //   "Classique",
            //   "Payé"
            // ).then(() =>{
            //   setIsLoad(false)
            //   history.push('/success')
            // })
            // .catch(err=>{
            //   setIsLoad(false)
            // })
          })
        })
      })
      
    }else{
      console.log("simpla payment active...")
        // paiement sans numero de carte 
        // AUTH IS REQUIRED!!!
        paymentIntentSavedCard(customerId, props.prix * 100).then((responSaved) => {
            const datas = responSaved.data.charges.data[0]
            //POST DANS DON_SAS SALESFORCE
            postDonSAS(
              allDataStep.billing.address, 
              getUser().email,
              // allDataStep.expo.step1.mail, 
              allDataStep.expo.step1.firstName, 
              allDataStep.expo.step1.lastName, 
              props.prix,
              datas.id,
              datas.object,
              datas.balance_transaction,
              datas.calculated_statement_descriptor,
              datas.currency,
              datas.customer,
              datas.description,
              datas.paid,
              "Credit Card",
              allDataStep.billing.country,
              datas.payment_method_details.card.brand,
              datas.receipt_url,
              datas.status,
              allDataStep.expo.step1.mailToNotify, 
              allDataStep.expo.step1.tribute, // tributeName,
              allDataStep.expo.step1.isDedicate ? allDataStep.expo.step1.isHonor ? "In Honor Of" : "In Memory Of" : " ",
              allDataStep.expo.step1.isAttend, // Will_you_attend__c
              " ", // Name_of_Guests__c
              " ", // Campaign__c
              allDataStep.expo.step1.message,
              " ",//period
              allDataStep.expo.step1.adress,
              allDataStep.billing.city,
              allDataStep.expo.step1.city,
              allDataStep.expo.step1.country,
              allDataStep.billing.province,
              allDataStep.expo.step1.province,
              allDataStep.billing.zip,
              allDataStep.expo.step1.zip,
              " ", //pax
              "Classique ",//Type_formulaire
              "Payé"
            )
          })
          .then(() => {
            history.push('/success')
          })
          .catch(() => {
            history.push('/error')
          })
      }
  }

  return (
    <form onSubmit={payment}>
      {card !== undefined ? (
        <div>
          <label>
            <div id="saved-card">
              <center>**** **** **** {card === '' ? '****' : card}</center>
            </div>
          </label>
          <Button className="btn-pay" disabled={isLoad}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoad && (
            <div style={{ marginRight: "10px" }}>
              <Loader size="mini" active inline />
            </div>
          )}
          <div id="button-text">SUBMIT</div>
        </div>
      </Button>
          {/* <Button disabled={active}>{payBtn}</Button> */}
        </div>
      ) : (
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </form>
  )
}
