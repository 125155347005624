const periodReducer = (state = "", action) =>{
    switch(action.type){
        case 'MONTH':
            return "month"
        case 'WEEK':
            return "week"
        case 'QUAT':
            return "quat"
        case 'ANNUAL':
            return "annual"
        default:
            return state
    }
}

export default periodReducer;