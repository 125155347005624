import React, { useState, useEffect } from 'react'
import FormAccount from '../components/Forms/DonorInformationForm'
import NavLinkPages from '../components/NavLinkPages'
import { Image } from 'semantic-ui-react'

import { getUsersMailFromToken, getAPIData } from '../library/helpers/get_data'
import FooterPage from '../components/inc/FooterPage'
import DonationInformation from './DonationInformation'

const Account = () => {
  const [username, setUsername] = useState(
    JSON.parse(localStorage.getItem('user'))?.name,
  )

  const fetchUsername = async () => {
    const apiUrl = {
      endpoint: `${getUsersMailFromToken()}/account/public`,
      name: 'recuperation du compte utilisateur',
    }
    const response = await getAPIData(apiUrl)
    // Mis apart des items (firstName, lastName, etc...) venant de la reponse de la request
    setUsername(response?.data.Name)
  }

  useEffect(() => {
    fetchUsername()
  }, [])

  return (
    <div className="bg-account">
      <NavLinkPages routeUrl="new-don" />
      <div className="header-page">
        <Image
          className="img-piv"
          src={require('../../src/public/img/pivot-logo.png')}
          width={80}
          style={{ margin: '0 auto' }}
        />
        <h2>My Donor Account</h2>
        <span>Welcome, {username}</span>
      </div>
      <div className="donor-donation-info">
        <FormAccount />
        <DonationInformation />
      </div>
      <FooterPage />
    </div>
  )
}

export default Account
