import React, { Component } from 'react';
import { Container, Image, Segment } from 'semantic-ui-react';
import NavLinkPages from '../components/NavLinkPages';
import FooterPage from '../components/inc/FooterPage';
import { getDonationByAccountId, getFiles, getDownFiles, getAccountByEmail } from '../utils/axios';
import { getUser } from '../utils/Common';
import { Notyf } from 'notyf';

const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    types: [
      {
        type: 'warning',
        background: 'red',
        icon: {
          className: 'material-icons',
          tagName: 'i',
          text: 'warning'
        }
      },
      {
        type: 'error',
        background: 'indianred',
        duration: 9000,
        dismissible: true
      }
    ]
  });

class Facture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            dons: []
        }
        
    }
 
    componentDidMount () {

        if (this.props.location.aboutPropsFiles !== undefined){
            getDonationByAccountId(this.props.location.aboutPropsFiles.accountId)
                .then(responseDon => {
                    this.setState({dons: responseDon.data})
                })
        }else{
            getAccountByEmail(getUser().email)
                .then(response => {
                    this.setState({datas: response.data[0]})
                    getDonationByAccountId(response.data[0].Id)
                        .then(responseDon => {
                            this.setState({dons: responseDon.data})
                        })
                })
        }
    }

    fonctionAffiche(res){
       //creation du fichier
       const file = new Blob(
           [res.data],
           {type:'application/pdf'}
       );
       const fileURL = URL.createObjectURL(file);
       window.open(fileURL);

       this.setState({loadingPage:false})
    }

    handleClickDownFiles = (e) => {
        if (this.props.location.aboutPropsFiles !== undefined){
            getFiles(e)
            .then(responseDownFiles => {
                if(responseDownFiles.data.length > 0){
                    getDownFiles(responseDownFiles.data[0].ContentDocument.Title,responseDownFiles.data[0].ContentDocument.FileType, responseDownFiles.data[0].ContentDocument.Id)
                    .then(responseFiles => {
                        this.fonctionAffiche(responseFiles)
                    })
                }else{
                    notyf.error({
                        message: "There are no files!",
                        duration: 9000,
                        icon: false,
                      })
                }
                   
             })
           
        }
      
    }

    render() {
        const dons = this.state.dons;
        return(
            <>  
            <div className="bg-pattern facture" onkeydown="noRefresh(event)"> 
                <NavLinkPages routeUrl="new-don"/>
                    <Container text className="container-facture">
                    
                        <h1 className="title">My letter receipts</h1>
                        <p className="messages" style={{ display: dons.length > 0 ? 'none' :'block' }} >--No letter receipt--</p>
                        {dons !== undefined && (<>
                            {dons.length > 0 && ( 
                        <div className="list-files">
                            {dons.map(don => (
                                <Segment key={don.Id} className="maximum-gift">
                                <span>
                                    <h4 className="date-list-gift"> {don.Date_de_don__c} </h4>
                                    <span className="mount-gift">{don.Montant__c} $ </span>
                                </span>
                                <Image onClick={() => this.handleClickDownFiles(don.Id)} className="picto-rond" src={require('../public/img/download.svg')}/>
                            </Segment>
                            ))}
                        </div>
                        )}
                        </>)}
                    </Container>
                <FooterPage/>
            </div>
            </>

        )
    }

}

export default Facture
