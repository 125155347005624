import React, { Component, Fragment } from 'react'
import { Route, BrowserRouter, Switch, useLocation } from 'react-router-dom'

import AccueilPage from './pages/Accueil'
import DonPage from './pages/DonationInformation'
import ConnectPage from './pages/Login'
import FacturePage from './pages/ReceipeInformation'
// import Home from './pages/Home'
import Inscription from './pages/Inscription'
import Account from './pages/DonorInformation'
import NotFound from './pages/notFound'
import DonationFAQ from './pages/DonationFAQs'
import PivotExpo2022 from './pages/PivotExpo2022'
import PivotExpo2023 from './pages/PivotExpo2023'
import Eofy22 from './pages/Eofy22'
import NewDon from './pages/NewDon'
import ElementStripeAbonnent from './pages/ElementStripePayment'

import NavLinkPages from './components/NavLinkPages'
import FooterPage from './components/inc/FooterPage'
import ModifyAccount from './components/Forms/UpdateDonorInformationForm'
import Success from './components/inc/SuccessPayment'
import Success_eofy22 from './components/inc/SuccessPaymentEofy'
import Error from './components/inc/ErrorPayment'

import {
  // ROOT_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
  MAKE_A_DON_PAGE,
  FAQ_PAGE,
  ABONNENT_PAGE,
  SUCCESS_PAGE,
  SUCCESS_PAGE_EOFY,
  ERROR_PAGE,
  HOME_PAGE,
  DONATION_INFORMATION,
  DONOR_INFORMATION_PAGE,
  EDIT_DONOR_INFORTMATION_PAGE,
  RECEIPE_PAGE,
  EXPO2022_PAGE,
  EXPO2023_PAGE,
  EOFY22_PAGE,
  GENERICS,
} from './settings/constants'

import PrivateRoute from './utils/PrivateRoute'
import './public/style.css'

const PrivateRoutes = [
  { id: 1, path: HOME_PAGE, component: AccueilPage },
  { id: 2, path: DONOR_INFORMATION_PAGE, component: Account },
  { id: 3, path: EDIT_DONOR_INFORTMATION_PAGE, component: ModifyAccount },
  { id: 4, path: DONATION_INFORMATION, component: DonPage },
  { id: 5, path: RECEIPE_PAGE, component: FacturePage },
]

const PublicRoutes = [
  // { id: 1, path: ROOT_PAGE, component: Home },
  { id: 2, path: LOGIN_PAGE, component: ConnectPage },
  { id: 3, path: REGISTER_PAGE, component: Inscription },
  { id: 4, path: MAKE_A_DON_PAGE, component: NewDon },
  { id: 6, path: FAQ_PAGE, component: DonationFAQ },
  { id: 7, path: ABONNENT_PAGE, component: ElementStripeAbonnent },
  { id: 8, path: SUCCESS_PAGE, component: Success },
  { id: 8, path: SUCCESS_PAGE_EOFY, component: Success_eofy22 },
  { id: 9, path: ERROR_PAGE, component: Error },
]

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            {PublicRoutes.map(({ id, path, component }) => (
              <Route exact path={path} component={component} key={id} />
            ))}

            {PrivateRoutes.map(({ id, path, component }) => (
              <PrivateRoute path={path} component={component} key={id} />
            ))}

            {/* "/expo2022" */}
            <Route path={EXPO2022_PAGE} component={PivotExpo2022} />
            {/* "/expo2022" */}
            {/* "/expo2023" */}
            <Route path={EXPO2023_PAGE} component={PivotExpo2023} />
            {/* "/expo2023" */}
            <Route path={EOFY22_PAGE} component={Eofy22} />
            {/* "*"" */}
            <Route path={GENERICS} component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    )
  }
}
export default App

const Layout = ({ children }) => {
  let location = useLocation()
  return (
    <>
      {![HOME_PAGE, MAKE_A_DON_PAGE].includes(location.pathname) ? (
        <Fragment>{children}</Fragment>
      ) : (
        <>
          <NavLinkPages />
          {children}
          <FooterPage />
        </>
      )}
    </>
  )
}
