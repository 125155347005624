import { getUser } from "./Common"

export const getItemSession = (name) => {
    return localStorage.getItem(name) || sessionStorage.getItem(name)
}

export const setItemSession = (name, value) => {
    sessionStorage.setItem(name, value)
    localStorage.setItem(name, value)
}

export const isLogged = () => {
    if( (getUser() === null) || (getUser() === undefined) ){
        return false
    }else{
        return true
    }
}