import React, { useState, useEffect } from 'react'
import { Button, Grid, Icon, Input, Select, TextArea } from 'semantic-ui-react'
import NumberFormat from 'react-number-format';
import DonationFAQ from '../../pages/DonationFAQs'
import { COUNTRIES } from '../../utils/countries'
import { validateEmailAllChar } from '../../utils/Common'

const PRICES = [
  { id: 0, label: '$50.00', value: 50 },
  { id: 1, label: '$100.00', value: 100 },
  { id: 2, label: '$150.00', value: 150 },
  { id: 3, label: '$200.00', value: 200 },
  { id: 4, label: '$500.00', value: 500 },
  { id: 5, label: '$1,000.00', value: 1000 },
]

/**
 *
 * STEP1 : COMPOSANT POUR APROPOS DU DONS ET DU DONNEUR
 * Sous-composant :
 * Princing | ContactInformation | Dedicace
 */

const FormDonsExpo2023 = ({ prev, datas, onNext }) => {
  // Holds tous les données du step2
  const [formData, setFormData] = useState(datas)
  //holds errors handler
  const [message, setMessage] = useState({
    type: '',
    content: '',
  })

  // Function pour modifier les données parent apartir des données fils
  const onChildFieldChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const validationWithNextStep = () => {
    if (formData.price === '') {
      setMessage({ type: 'ERROR', content: 'Gift amount required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.mail === '' ||
      formData.adress === '' ||
      formData.city === '' ||
      formData.province === '' ||
      formData.zip === '' ||
      formData.country === ''
    ) {
      setMessage({ type: 'ERROR', content: 'All fields are required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (formData.isDedicate && formData.tribute === '') {
      setMessage({ type: 'ERROR', content: 'The name of tributee is required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (
      !validateEmailAllChar(formData.mail) || (formData.mailToNotify !== '' && !validateEmailAllChar(formData.mailToNotify))
      // (formData.isDedicate && !validateEmailAllChar(formData.mailToNotify))
    ) {
      setMessage({ type: 'ERROR', content: 'Invalid email!' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else {
      onNext('step2', formData, 2)
    }
  }

  useEffect(() => {
    formData.isDedicate && setFormData({ ...formData, isHonor: true })
    // eslint-disable-next-line
  }, [formData.isDedicate])

  return (
    <>
      <div className="page-formdons">
        <Grid className="container-loug">
          <Grid.Column>
            <div className="form-connect">
              <Grid centered>
                <Grid.Row> <h2>Pivot Expo 2023</h2></Grid.Row>
                <Grid.Row style={{margin: 0, paddingTop: 0, fontWeight: 600, fontFamily:"Montserrat"}}> <span>Minimum suggested donation: $150 per guest</span></Grid.Row>
              </Grid>
              <Pricing
                price={formData.price}
                // isRecurring={formData.isRecurring}
                // subscription={formData.subscription}
                onFieldChange={onChildFieldChange}
              />
              <ContactInformation
                firstName={formData.firstName}
                lastName={formData.lastName}
                mail={formData.mail}
                adress={formData.adress}
                city={formData.city}
                province={formData.province}
                zip={formData.zip}
                country={formData.country}
                onFieldChange={onChildFieldChange}
              />
              <Dedicace
                isDedicate={formData.isDedicate}
                isHonor={formData.isHonor}
                tribute={formData.tribute}
                mailToNotify={formData.mailToNotify}
                onFieldChange={onChildFieldChange}
              />
              <div className="msg-error">{message.content}</div>

              <Grid.Row columns={2}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Grid.Column width={8}>
                    <Button style={{fontFamily:"Montserrat"}} className="previous" onClick={() => prev()}>
                      Previous
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8} style={{ textAlign: 'end' }}>
                    <Button
                      className="btn-pay"
                      onClick={validationWithNextStep}
                    >
                      Continue to Payment
                    </Button>
                  </Grid.Column>
                </div>
              </Grid.Row>
            </div>
          </Grid.Column>
        </Grid>
      </div>

      {/**
       * Page Donation
       *FAQ
       **/}
      <div className="page-formdons">
        <Grid className="container-loug">
          <Grid.Column>
            <div className="form-connect">
              <DonationFAQ />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  )
}

export default FormDonsExpo2023

const ContactInformation = ({
  firstName,
  lastName,
  mail,
  adress,
  city,
  province,
  zip,
  country,
  onFieldChange,
}) => {
  const [countryOptions] = useState(COUNTRIES)

  const [data, setData] = useState({
    firstName,
    lastName,
    mail,
    adress,
    city,
    province,
    zip,
    country,
  })

  const onInputChange = (name, value) => {
    setData({ ...data, [name]: value })
    onFieldChange(name, value)
  }

  return (
    <form className="contact-adress">
      <p className="billing-address">Contact Information</p>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="firstName"
          placeholder="First Name"
          value={data.firstName}
          onChange={(e) => onInputChange('firstName', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="user" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={data.lastName}
          onChange={(e) => onInputChange('lastName', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="user" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="email"
          name="mail"
          placeholder="Email"
          value={data.mail}
          onChange={(e) => onInputChange('mail', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="envelope" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="address"
          placeholder="Address"
          value={data.adress}
          onChange={(e) => onInputChange('adress', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="home" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="city"
          placeholder="City"
          value={data.city}
          onChange={(e) => onInputChange('city', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="industry" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="province"
          placeholder="State/Province"
          value={data.province}
          onChange={(e) => onInputChange('province', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="cloud" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="zip"
          placeholder="Zip code"
          value={data.zip}
          onChange={(e) => onInputChange('zip', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="fax" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Select
          placeholder="Select your country"
          options={countryOptions}
          value={data.country}
          onChange={(_, val) => onInputChange('country', val.value)}
          className="dropdown-city"
        />
        <div className="input-icon">
          <Icon className="building" />*
        </div>
      </div>
    </form>
  )
}

const Pricing = ({ price, isRecurring, subscription, onFieldChange }) => {
  const [prices] = useState(PRICES)
  const [data, setData] = useState({
    price,
  })


  const onInputChange = (name, value) => {
    setData({ ...data, [name]: value })
    onFieldChange(name, value)
  }


  return (
    <>
      <Grid centered columns={2} className="container-abonnent">
        <Grid.Row doubling="true" className="row-abonnent">
          {prices.map(({ id, label, value }) => {
            return (
              <Grid.Column key={id} className="column-abonnent a2">
                <span
                  className="amount"
                  onClick={() =>
                    onInputChange('price', parseFloat(value).toFixed(2))
                  }
                  style={{
                    backgroundColor:
                      data.price === parseFloat(value).toFixed(2)
                        ? '#5ac3bd'
                        : '',
                    color:
                      data.price === parseFloat(value).toFixed(2)
                        ? '#ffff'
                        : '',
                  }}
                >
                  {label}
                </span>
              </Grid.Column>
            )
          })}
          <div className="column-abonnent a6">
            <span className="give-freely">Other amount:</span>
            <span className="amount">
            <NumberFormat
              className="input-other"
              thousandSeparator={true}
              placeholder="$ 0.00"
              prefix={'$'}
              value={`$ ${
                parseFloat(data.price).toFixed(2)
              }`}
              onValueChange={ (value) =>{
                onInputChange('price', parseFloat(value.value).toFixed(2))
                // console.log(isNaN(value.value) ? parseFloat(0).toFixed(2): parseFloat(value.value).toFixed(1))
              }}
            />
            </span>
          </div>
        </Grid.Row>
      </Grid>
    </>
  )
}

const Dedicace = ({
  isDedicate,
  isHonor,
  tribute,
  mailToNotify,
  onFieldChange,
}) => {
  const [data, setData] = useState({
    isDedicate,
    isHonor,
    tribute,
    mailToNotify,
  })

  const onInputChange = (name, value) => {
    setData({ ...data, [name]: value })
    onFieldChange(name, value)
  }
  

  return (
    <>
      <div className="input-group coverCheck">
        <input
          rel="coverCheck"
          type="checkbox"
          id="cover"
          className="check-input"
          onChangeCapture={() => onInputChange('isDedicate', !data.isDedicate)}
          checked={data.isDedicate}
        />
        <label style={{ fontWeight: 'bold' }} htmlFor="cover">
          I would like to dedicate this donation
        </label>
      </div>
      {data.isDedicate && (
        <div key="other">
          <Grid className="HonorDonor">
            <Grid.Row columns={2}>
              <Grid.Column>
                <input
                  rel="honor"
                  type="checkbox"
                  id="honor"
                  className="check-input"
                  onChange={() => onInputChange('isHonor', !data.isHonor)}
                  checked={data.isHonor}
                />
                <label htmlFor="honor">In honor of</label>
              </Grid.Column>
              <Grid.Column>
                <input
                  rel="memory"
                  type="checkbox"
                  id="memory"
                  className="check-input"
                  onChange={() => onInputChange('isHonor', !data.isHonor)}
                  checked={!data.isHonor}
                />
                <label htmlFor="memory">In memory of</label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2} className="tribute">
            <Grid.Row stretched>
              <Grid.Column>
                <div className="input-group input-group-icon">
                  <input
                    type="text"
                    name="tribute"
                    placeholder="Name of Tributee"
                    value={data.tribute}
                    onChange={(e) => onInputChange('tribute', e.target.value)}
                  />
                  <div className="input-icon">
                    <Icon className="user" />
                    {''}
                  </div>
                </div>
                <div className="input-group input-group-icon">
                  <input
                    type="email"
                    name="mailNotify"
                    placeholder="Email of person to notify"
                    value={data.mailToNotify}
                    onChange={(e) =>
                      onInputChange('mailToNotify', e.target.value)
                    }
                  />
                  <div className="input-icon">
                    <Icon className="mail" />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <TextArea
                  className="textArea"
                  placeholder="Please share a message with the recipient of this tribute gift"
                  style={{ minHeight: 100 }}
                  value={data.message}
                  onChange={(e) => onInputChange('message', e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )}
    </>
  )
}