export const fullDateFormat = (date) => {
  const _date = new Date(date)
  return `${_date.toLocaleString('en-US', {
    month: 'long',
  })} ${_date.getFullYear()}`
}

export const fullDateFormatWithDay = (date) => {
  const _date = new Date(date)
  return `${_date.toLocaleString('en-US', {
    month: 'long',
  })} ${_date.getDate()}, ${_date.getFullYear()}`
}
