import React, { Component } from 'react';
import {Button, Container} from 'semantic-ui-react';

class notFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }


    render() {

      
        return(
            <div className="bg-successerror">
                <Container text className="content-successeror">
                    <h1>404 NOT FOUND</h1>
                    <p> We are sorry but the page you are looking for does not exist</p>
                    <a href="https://www.pivotworks.org/"><Button>Back to Pivot's website</Button></a>
                    
                </Container>
            </div>
         

        )
    }

}

export default notFound

