import React, { Component } from 'react';
import {Button, Container} from 'semantic-ui-react';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }


    render() {

      
        return(
            <div className="bg-successerror">
                <Container text className="content-successeror">
                    <h1>Error!</h1>
                    <h2>We're sorry, there is an error, please try again!</h2>
                    <p>
                    Please try again using a different browser or device. 
                    If the problem persists, please contact <a href='mailto:giving@pivotworks.org'>giving@pivotworks.org</a> support.
                    </p>
                    {/* <p> 
                    When you give to Pivot, you’re not only supporting the 
                    delivery of lifesaving care for the communities we serve, 
                    but also investing in the future of the country’s public 
                    health system as we work in partnership with the national 
                    government to achieve sustainable universal health coverage for all.
                    </p> */}
                    <a href="https://www.pivotworks.org/"><Button>Back to Pivot's website</Button></a>
                    
                </Container>
            </div>
         

        )
    }

}

export default Error
