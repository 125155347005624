import React, { useEffect, useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { Grid, Icon, Button, Image } from 'semantic-ui-react'

import { MAKE_A_DON_PAGE } from '../settings/constants'
import sideImage from '../public/img/img_pivot.PNG'

import { getUsersMailFromToken, getAPIData } from '../library/helpers/get_data'
import { getUser, removeUserSession } from '../utils/Common'
import '../public/style.css'

// Composant parent
const AccueilPage = () => {
  return (
    <div className="content-acceuil">
      <div className="container-center">
        <Grid className="categories-container">
          <Grid.Column
            width={8}
            className="mobil-none"
            style={{ position: 'fixed', height: '100%' }}
          >
            <Image
              className="img-piv"
              src={sideImage}
              alt="deux-personnes-vaccination"
              style={{ height: '100%' }}
            />
          </Grid.Column>
          <Grid.Column width={8} className="form-menu">
            <h1>My Donor Account</h1>
            <WelcomePanel />
            <AccountInformationPanel />
            <LogOutLink />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

// Composant fils pour le Welcome + username panneau
const WelcomePanel = () => {
  const [username, setUsername] = useState(
    JSON.parse(localStorage.getItem('user'))?.name,
  )

  const fetchUsername = async () => {
    const apiUrl = {
      endpoint: `${getUsersMailFromToken()}/account/public`,
      name: 'recuperation du compte utilisateur',
    }
    const response = await getAPIData(apiUrl)
    // Mis apart des items (firstName, lastName, etc...) venant de la reponse de la request
    setUsername(response?.data.Name)
  }

  useEffect(() => {
    fetchUsername()
  }, [])

  return (
    <Grid.Row>
      <div className="category-card info">
        <div className="title-card pinfo">
          <span className="donator"> Welcome , {username} ! </span>
        </div>
        <div className="pbtn">
          <NavLink to={'/'}>
            <Button className="btn-donation">Donate</Button>
          </NavLink>
        </div>
      </div>
    </Grid.Row>
  )
}

// Composant fils pour le panneau qui renvoie à l'information utilisateur
const AccountInformationPanel = () => {
  return (
    <Grid.Row>
      <NavLink
        to={'/donor-information/' + getUser().email}
        className="category-card acc"
      >
        <div className="title-card paccount">
          <Icon className="iconi" name="user outline" />
          Account Information
        </div>
      </NavLink>
    </Grid.Row>
  )
}

// Composant fils pour le lien de déconnexion
const LogOutLink = () => {
  const history = useHistory()

  const logout = () => {
    removeUserSession()
    history.push(MAKE_A_DON_PAGE)
  }

  return (
    <Grid.Row onClick={logout}>
      <div className="loug-out">Logout</div>
    </Grid.Row>
  )
}

export default AccueilPage
