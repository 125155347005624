import React, { useState, useEffect } from 'react'
import { Button, Grid, Input, Checkbox } from 'semantic-ui-react'

/**
 *  STEP O : COMPOSANT APROPS DES INVITES
 *  isAttend : Bool - Present à l'exposition ou pas
 *  pax : Number - nombre de personne en compagnie
 *  pax_names : Array - noms des compagnies
 *
 */
const GuestExpo2023 = ({ next, datas }) => {
  // Holds on of pax_names
  const [onName, setOneName] = useState('')
  // const [keyDown, setKeyDown] = useState('')
  // Holds tous les données de ce composant
  const [data, setData] = useState(datas)
  // Verify if pax === nombre des pax_names
  const [isOutOfBonds, setIsOutOfBonds] = useState(false)

  // tant que pax_names.length depasse le nombre de pax blocker l'input
  useEffect(() => {
    setIsOutOfBonds(!(data.pax_names.length < data.pax))
  }, [data.pax_names, data.pax])

  useEffect(() => {
    if (onName.includes(',')) {
      setOneName('')
      setData({
        ...data,
        pax_names: [...data.pax_names, onName.replace(',', '')],
      })
    }
  }, [onName, data])

  // const HandleKeypress = (ev) => {
  //   if (ev.key === ',') {
  //     ev.preventDefault()
  //     setData({
  //       ...data,
  //       pax_names: [...data.pax_names, ev.target.value],
  //     })
  //     setOneName('')
  //   }
  // }

  return (
    <div className="form">
      <Grid centered>
        <Grid.Row><h1>Pivot Expo 2023</h1></Grid.Row>
        <Grid.Row style={{margin: 0, paddingTop: 0, fontWeight: 600}}><span style={{fontFamily:"Montserrat"}}>Tuesday May 16, 6:00-9:00pm</span></Grid.Row>
        <Grid.Row style={{margin: 0, paddingTop: 0, fontWeight: 600}}><span style={{fontFamily:"Montserrat"}}>Saint James, Long Island, NY</span></Grid.Row>
      </Grid>
      <Grid centered>
        <Grid.Row centered>
          <h2> Will you be joining us?</h2>
        </Grid.Row>
      </Grid>
      <Grid.Row className="guestsExpo">
        <Grid.Column width={8} className="guestsExpo-column">
          <Checkbox
            rel="yes"
            type="checkbox"
            id="yes"
            className="check-input"
            checked={data.isAttend}
            onChange={(e) => setData({ ...data, isAttend: !data.isAttend })}
          />
          <label htmlFor="yes">Yes</label>
        </Grid.Column>
        <Grid.Column width={8} className="guestsExpo-column">
          <Checkbox
            rel="no"
            type="checkbox"
            id="no"
            className="check-input"
            checked={!data.isAttend}
            onChange={(e) => setData({ ...data, isAttend: !data.isAttend })}
          />
          <label style={{paddingLeft:"10px"}} htmlFor="no">No, but I would like to make a gift </label>
        </Grid.Column>
      </Grid.Row>
      {data.isAttend && (
        <>
          <Grid.Row className="rows">
                  <Grid.Column>
                    <div className="input-group input-group-icon">
                      <label>How many guests total (including you)?</label>
                      <Input
                        fluid
                        icon="users"
                        iconPosition='left'
                        type="number"
                        name="Nbguests"
                        placeholder="Number of guests"
                        // min="0"
                        value={data.pax}
                        onChange={(e) =>
                          setData({ ...data, pax: e.target.value })
                        }
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>

          <Grid.Row className="rows">
            <Grid.Column>
              <div className="input-group input-group-icon">
                <label>Enter the name of all guests (including yours), separated by a comma:</label>
                <Input
                  fluid
                  icon="pin"
                  iconPosition="left"
                  type="text"
                  name="guest_name"
                  placeholder="Enter the name of all guests (including yours), separated by a comma:"
                  // onKeyPress={(ev) => HandleKeypress(ev)}
                  disabled={isOutOfBonds}
                  onChange={(e) => setOneName(e.target.value)}
                  value={onName}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <Grid.Row className="rows">
        <div className="lists_pax">
          {data.pax_names.map((name, index) => (
            <span className="pax-name" key={index}>
              {name}
              <i
                style={{ cursor: 'pointer' }}
                className="close icon"
                onClick={() =>
                  setData({
                    ...data,
                    pax_names: data.pax_names.filter((names) => name !== names),
                  })
                }
              />
            </span>
          ))}
        </div>
      </Grid.Row>
      <Grid.Row centered>
        <Button fluid onClick={() => next('step1', data, 1)}>
          Next
        </Button>
      </Grid.Row>
    </div>
  )
}

export default GuestExpo2023
