import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format';
import { subscriptionAction } from '../../redux/reducers/subscription/subscriptionAction'
import { Button, Grid, Icon, Input, Select, TextArea } from 'semantic-ui-react'
import { COUNTRIES } from '../../utils/countries'
import DonationFAQ from '../../pages/DonationFAQs'
import { validateEmailAllChar } from '../../utils/Common'

import {
  getAPIData,
  getUsersMailFromToken,
} from '../../library/helpers/get_data'

const PRICES = [
  { id: 0, label: '$50.00', value: 50 },
  { id: 1, label: '$100.00', value: 100 },
  { id: 2, label: '$500.00', value: 500 },
  { id: 3, label: '$1000.00', value: 1000 },
]

const DONATION_FREQUENCY = [
  { id: 0, label: 'Weekly', value: 'weeklySubscription' },
  { id: 1, label: 'Monthly', value: 'monthlySubscription' },
  { id: 2, label: 'Quarterly', value: 'quarterlySubscription' },
  { id: 3, label: 'Annually', value: 'annuallySubscription' },
]

/**
 *
 * step2 : COMPOSANT POUR APROPOS DU DONS ET DU DONNEUR
 * Sous-composant :
 * Princing | ContactInformation | Dedicace
 */

const FormNewDon = ({ datas, onNext }) => {
  // Holds tous les données du step2
  const [formData, setFormData] = useState(datas)
  //holds errors handler
  const [message, setMessage] = useState({
    type: '',
    content: '',
  })

  // Function pour modifier les données parent apartir des données fils
  const onChildFieldChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const validationWithNextStep = () => {
    if (formData.price === '') {
      setMessage({ type: 'ERROR', content: 'Gift amount required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.mail === '' ||
      formData.adress === '' ||
      formData.city === '' ||
      formData.province === '' ||
      formData.zip === '' ||
      formData.country === ''
    ) {
      setMessage({ type: 'ERROR', content: 'All fields are required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (formData.isDedicate && formData.tribute === '') {
      setMessage({ type: 'ERROR', content: 'The name of tributee is required' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else if (
      // !validateEmailAllChar(formData.mail) ||(formData.isDedicate && !validateEmailAllChar(formData.mailToNotify))
      !validateEmailAllChar(formData.mail) || (formData.mailToNotify !== '' && !validateEmailAllChar(formData.mailToNotify))
    ) {
      setMessage({ type: 'ERROR', content: 'Invalid email!' })
      setTimeout(() => {
        setMessage({ type: '', content: '' })
      }, 3000)
    } else {
      onNext('step1', formData, 1)
    }
  }

  async function fetchAccountInformation() {
    const apiUrl = {
      endpoint: `${getUsersMailFromToken()}/account/public`,
      name: 'recuperation du compte utilisateur',
    }

    try {
      const response = await getAPIData(apiUrl)
      // Mis apart des items (firstName, lastName, etc...) venant de la reponse de la request
      const {
        FirstName,
        LastName,
        PersonEmail,
        INDIV_Adresse_1_contact__c,
        City_contact__c,
        Country_contact__c,
        Zip_contact__c,
        State_contact__c,
      } = response?.data

      // Pour la auto-completion
      setFormData({
        ...formData,
        firstName: FirstName,
        lastName: LastName,
        mail: PersonEmail,
        adress: INDIV_Adresse_1_contact__c,
        city: City_contact__c,
        province: State_contact__c,
        zip: Zip_contact__c,
        country: Country_contact__c,
      })
    } catch (e) {
      setFormData({ ...formData })
    }
  }

  useEffect(() => {
    formData.isDedicate && setFormData({ ...formData, isHonor: true })
    fetchAccountInformation()
    // eslint-disable-next-line
  }, [formData.isDedicate])

  // useEffect(() => {
  //   // eslint-disable-next-line
  //   fetchAccountInformation()
  //   // eslint-disable-next-line
  // }, [])

  return (
    <>
      <div className="page-formdons">
        <Grid className="container-loug">
          <Grid.Column>
            <div className="form-connect">
              <Grid centered>
                <Grid.Row>
                  <h2>Donation</h2>
                </Grid.Row>
              </Grid>
              <Pricing
                price={formData.price}
                isRecurring={formData.isRecurring}
                subscription={formData.subscription}
                onFieldChange={onChildFieldChange}
              />
              <ContactInformation
                data={formData}
                onFieldChange={onChildFieldChange}
              />
              <Dedicace data={formData} onFieldChange={onChildFieldChange} />
              <div className="msg-error">{message.content}</div>

              <Grid.Row columns={2}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Grid.Column width={8} style={{ textAlign: 'end' }}>
                    <Button
                      className="btn-pay"
                      onClick={validationWithNextStep}
                    >
                      Continue to Payment
                    </Button>
                  </Grid.Column>
                </div>
              </Grid.Row>
            </div>
          </Grid.Column>
        </Grid>
      </div>

      {/**
       * Page Donation
       *FAQ
       **/}
      <div className="page-formdons">
        <Grid className="container-loug">
          <Grid.Column>
            <div className="form-connect">
              <DonationFAQ />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  )
}

export default FormNewDon

const ContactInformation = ({ data, onFieldChange }) => {
  const {
    firstName,
    lastName,
    mail,
    adress,
    city,
    province,
    zip,
    country,
  } = data
  const [countryOptions] = useState(COUNTRIES)

  const onInputChange = (name, value) => {
    onFieldChange(name, value)
  }

  return (
    <form className="contact-adress">
      <p className="billing-address">Contact Information</p>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="firstName"
          placeholder="First Name"
          value={firstName || ''}
          onChange={(e) => onInputChange('firstName', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="user" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={lastName || ''}
          onChange={(e) => onInputChange('lastName', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="user" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="email"
          name="mail"
          placeholder="Email"
          value={mail || ''}
          onChange={(e) => onInputChange('mail', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="envelope" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="address"
          placeholder="Address"
          value={adress || ''}
          onChange={(e) => onInputChange('adress', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="home" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="city"
          placeholder="City"
          value={city || ''}
          onChange={(e) => onInputChange('city', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="industry" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="province"
          placeholder="State/Province"
          value={province || ''}
          onChange={(e) => onInputChange('province', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="cloud" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Input
          fluid
          type="text"
          name="zip"
          placeholder="Zip code"
          value={zip || ''}
          onChange={(e) => onInputChange('zip', e.target.value)}
        />
        <div className="input-icon">
          <Icon className="fax" />*
        </div>
      </div>
      <div className="input-group input-group-icon">
        <Select
          placeholder="Select your country"
          options={countryOptions}
          value={country || ''}
          onChange={(_, val) => onInputChange('country', val.value)}
          className="dropdown-city"
        />
        <div className="input-icon">
          <Icon className="building" />*
        </div>
      </div>
    </form>
  )
}

const Pricing = ({ price, isRecurring, subscription, onFieldChange }) => {
  const [prices] = useState(PRICES)
  // const [sub, setSub] = useState()
  const [donationFrequency] = useState(DONATION_FREQUENCY)
  const [data, setData] = useState({
    price,
    isRecurring,
    subscription,
  })

  const dispatch = useDispatch()

  const onInputChange = (name, value) => {
    setData({ ...data, [name]: value })
    onFieldChange(name, value)
    if (name === 'isRecurring' || name === 'subscription') {
      dispatch(subscriptionAction(value))
    }
  }

  useEffect(() => {
    if (data.isRecurring) {
      setData({ ...data, subscription: 'weeklySubscription' })
      onFieldChange('subscription', 'weeklySubscription')
    } else {
      setData({ ...data, subscription: 'OneTimeGift' })
      onFieldChange('subscription', 'OneTimeGift')
    }
    // eslint-disable-next-line
  }, [data.isRecurring])

  return (
    <>
      <Grid centered columns={2} className="container-abonnent">
        <Grid.Row doubling="true" className="row-abonnent">
          {prices.map(({ id, label, value }) => {
            return (
              <Grid.Column key={id} className="column-abonnent a2">
                <span
                  className="amount"
                  onClick={() =>
                    onInputChange('price', parseFloat(value).toFixed(2))
                  }
                  style={{
                    backgroundColor:
                      data.price === parseFloat(value).toFixed(2)
                        ? '#5ac3bd'
                        : '',
                    color:
                      data.price === parseFloat(value).toFixed(2)
                        ? '#ffff'
                        : '',
                  }}
                >
                  {label}
                </span>
              </Grid.Column>
            )
          })}
          <div className="column-abonnent a6">
            <span className="give-freely">Other amount:</span>
            <span className="amount">
            <NumberFormat
              className="input-other"
              thousandSeparator={true}
              placeholder="$ 0,00"
              prefix={'$'}
              value={`$ ${
                parseFloat(data.price).toFixed(2)
              }`}
              onValueChange={ (value) =>{
                onInputChange('price', parseFloat(value.value).toFixed(2))
                // console.log(isNaN(value.value) ? parseFloat(0).toFixed(2): parseFloat(value.value).toFixed(1))
              }}
            />
              {/* <input
                className="input-other"
                data-type="currency"
                type="text"
                pattern="[0-9]+([\.,][0-9]+)?"
                value={`$ ${
                  data.price === ''
                    ? parseFloat(0).toFixed(2)
                    : parseFloat(data.price).toFixed(2)
                }`}
                onFocus={() =>
                  setData({
                    ...data,
                    price:
                      data.price !== ''
                        ? parseFloat(data.price).toFixed(2)
                        : parseFloat(0).toFixed(),
                  })
                }
                name="price"
                onChange={(e) =>
                  onInputChange('price', e.target.value.replace('$', ''))
                }
                placeholder="$0.00"
              /> */}
            </span>
          </div>
        </Grid.Row>
      </Grid>
      <Grid className="make-gift">
        <Grid.Row columns={2}>
          <Grid.Column>
            <input
              rel="ontimegift"
              type="checkbox"
              id="termsO"
              className="check-input"
              checked={!data.isRecurring}
              onChange={(e) => {
                onInputChange('isRecurring', !data.isRecurring)
              }}
            />
            <label htmlFor="termsO">One-Time Gift</label>
          </Grid.Column>
          <Grid.Column>
            <input
              rel="makerecurringgift"
              type="checkbox"
              id="termsM"
              className="check-input"
              checked={data.isRecurring}
              onChange={(e) => {
                onInputChange('isRecurring', !data.isRecurring)
              }}
            />
            <label htmlFor="termsM">Make it a Recurring Gift</label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div
        className="input-group subscriptionGift"
        style={{ display: data.isRecurring ? 'flex' : 'none' }}
      >
        {donationFrequency.map(({ id, label, value }) => {
          return (
            <span key={id} onClick={() => onInputChange('subscription', value)}>
              <input
                type="checkbox"
                id={label}
                className="check-input"
                checked={data.subscription === value}
                onChange={() => onInputChange('subscription', value)}
              />
              <label htmlFor={label}>{label}</label>
            </span>
          )
        })}
      </div>
    </>
  )
}

const Dedicace = ({ data, onFieldChange }) => {
  const { isDedicate, isHonor, tribute, mailToNotify, message } = data

  const onInputChange = (name, value) => {
    onFieldChange(name, value)
  }

  return (
    <>
      <div className="input-group coverCheck">
        <input
          rel="coverCheck"
          type="checkbox"
          id="cover"
          className="check-input"
          onChange={() => onInputChange('isDedicate', !isDedicate)}
          checked={isDedicate}
        />
        <label style={{ fontWeight: 'bold' }} htmlFor="cover">
          I would like to dedicate this donation
        </label>
      </div>
      {isDedicate && (
        <div key="other">
          <Grid className="HonorDonor">
            <Grid.Row columns={2}>
              <Grid.Column>
                <input
                  rel="honor"
                  type="checkbox"
                  id="honor"
                  className="check-input"
                  onChange={() => onInputChange('isHonor', !isHonor)}
                  checked={isHonor}
                />
                <label htmlFor="honor">In honor of</label>
              </Grid.Column>
              <Grid.Column>
                <input
                  rel="memory"
                  type="checkbox"
                  id="memory"
                  className="check-input"
                  onChange={() => onInputChange('isHonor', !isHonor)}
                  checked={!isHonor}
                />
                <label htmlFor="memory">In memory of</label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2} className="tribute">
            <Grid.Row stretched>
              <Grid.Column>
                <div className="input-group input-group-icon">
                  <input
                    type="text"
                    name="tribute"
                    placeholder="Name of Tributee"
                    value={tribute}
                    onChange={(e) => onInputChange('tribute', e.target.value)}
                  />
                  <div className="input-icon">
                    <Icon className="user" />
                    {''}
                  </div>
                </div>
                <div className="input-group input-group-icon">
                  <input
                    type="email"
                    name="mailNotify"
                    placeholder="Email of person to notify"
                    value={mailToNotify}
                    onChange={(e) =>
                      onInputChange('mailToNotify', e.target.value)
                    }
                  />
                  <div className="input-icon">
                    <Icon className="mail" />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <TextArea
                  className="textArea"
                  placeholder="Please share a message with the recipient of this tribute gift"
                  style={{ minHeight: 100 }}
                  value={message}
                  onChange={(e) => onInputChange('message', e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )}
    </>
  )
}
