import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { expoAction } from '../../redux/reducers/expo/expoAction'
import { feesAction } from '../../redux/reducers/fees/feesAction'
import Billing from '../../components/Forms/BillingAdress'
import CardSeparated from '../../components/CardPayment/CardPaymentDetails'
import { getUser } from '../../utils/Common'
import {
  verifySourceCustomer,
  createChargeRib,
  postDonSAS,
} from '../../utils/axios'
import NumberFormat from 'react-number-format'

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const AbonnentExpo2023 = ({ next, allDatasOnEveryStep }) => {
  const { step2 } = allDatasOnEveryStep
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
    fees: false,
    finalPrice: step2.price,
    isOpenBilling: '',
    toggleState: 1,
  })

  const dispatch = useDispatch()
  const expo = useSelector((state) => state)
  dispatch(expoAction(allDatasOnEveryStep))

  return (
    <div className="guest-expo-2022s">
      <Grid>
        <Grid.Column>
          <div className="content-tabs">
            <h2>Gift Summary</h2>
            <Resume datas={data} />
            <Fees
              data={data.fees}
              finalPrices={data.finalPrice}
              onFeeing={() => setData(!data.fees)}
            />
            <BillingAdress
              data={data.isOpenBilling}
              openBillAdress={() =>
                setData({ ...data, isOpenBilling: !data.isOpenBilling })
              }
            />
            <div className="card-methd">
              <p className="methd">Payment Details</p>
              <p style={{fontFamily:'Montserrat', fontWeight:'500', marginTop:'14px', lineHeight:'1.5', textAlign:'center'}}>  For larger gifts, please consider sending a check or wire.</p>
            
              <TabMenu changeTab={setTab} />
              <div className="payment-details">
                {
                // tab === 0 ? ( <CardSeparated />) : (<Elements stripe={promise}><BankForm data={expo} /></Elements>)
                // CheckWire
                tab === 1 ? <CardSeparated /> : (tab === 2 ? ((<Elements stripe={promise}><BankForm data={expo} /></Elements>)) : <CheckWire data={expo} />)
                }
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default AbonnentExpo2023

const Resume = (datas) => {
  const allSteps = useSelector((state) => state.expo)

  // eslint-disable-next-line
  const [data, setData] = useState(datas)
  return (
    <Grid centered columns={2} className="donor-info">
      <Grid.Row>
        <Grid.Column className="title">Donor:</Grid.Column>
        <Grid.Column className="value">{allSteps.step2.firstName}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title">Amount:</Grid.Column>
        <Grid.Column className="value">$ {allSteps.step2.price}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title">Tribute:</Grid.Column>
        <Grid.Column className="value">
          {allSteps.step2.isDedicate ? 'Yes' : 'No'}{' '}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="title"></Grid.Column>
        <Grid.Column className="value">
          {allSteps.step2.tribute ? ' In honor of ' : ' '}
          {allSteps.step2.tribute}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

// const Fees = ({ data, finalPrices, onFeeing }) => {
//   const [isFeeing, setIsFeeing] = useState(data)
//   const [finalPrice, setFinalPrice] = useState(
//     parseFloat(finalPrices.replace('$', '')),
//   )

//   const dispatch = useDispatch()

//   useEffect(() => {
//     if (isFeeing) {
//       const maxi = finalPrice * 0.03 + 0.3
//       if (maxi < 0){
//         setFinalPrice((finalPrice + 10).toFixed(2))
//         dispatch(feesAction((finalPrice + 10).toFixed(2)))
//       }else{
//         setFinalPrice((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2))
//         dispatch(feesAction((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2)))
//       }
//     } else {
//       setFinalPrice(parseFloat(finalPrices.replace('$', '')))
//       dispatch(feesAction(finalPrice))
//     }
//     // eslint-disable-next-line
//   }, [isFeeing])

//   return (
//     <>
//       <div>
//         <input
//           type="checkbox"
//           id="fees"
//           className="check-input"
//           checked={isFeeing}
//           onChange={() => setIsFeeing(!isFeeing)}
//         />
//         <label htmlFor="fees" className="fees">
//           <strong style={{ fontWeight: 'bold' }}>
//             I would like to help cover processing fees (applies to CC payments only)
//           </strong>
//           <p style={{ fontWeight: '100' }}>
//             (3% of gift amount + $0.30 will be added to your total billing
//             amount)
//           </p>
//         </label>
//       </div>
//       <Grid centered columns={2} className="donor-info">
//         <Grid.Row>
//           <Grid.Column className="title">Total gift / pledge amount:</Grid.Column>
//           <Grid.Column className="value">
//             $ {parseFloat(finalPrice).toFixed(2)}
//           </Grid.Column>
//         </Grid.Row>
//       </Grid>
//     </>
//   )
// }
const Fees = ({ data, finalPrices, onFeeing }) => {
  const [isFeeing, setIsFeeing] = useState(data)
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(finalPrices.replace('$', '')),
  )

  const dispatch = useDispatch()
  const setStoragePrice = (price) => {
    localStorage.setItem('finalPrices', price)
    sessionStorage.setItem('finalPrices', price)
  }

  useEffect(() => {
    if (isFeeing) {
      const maxi = finalPrice * 0.03 + 0.3
      if (maxi < 0){
        setFinalPrice((finalPrice + 10).toFixed(2))
        dispatch(feesAction((finalPrice + 10).toFixed(2)))
        setStoragePrice((finalPrice + 10).toFixed(2))
      }else{
        setFinalPrice((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2))
        dispatch(feesAction((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2)))
        setStoragePrice((finalPrice + finalPrice * 0.03 + 0.3).toFixed(2))
      }
      
    } else {
      setFinalPrice(parseFloat(finalPrices.replace('$', '')))
      dispatch(feesAction(finalPrices))
      setStoragePrice(finalPrices)
    }
    // eslint-disable-next-line
  }, [isFeeing])

    return (
    <>
      <div>
        <input
          type="checkbox"
          id="fees"
          className="check-input"
          checked={isFeeing}
          onChange={() => setIsFeeing(!isFeeing)}
        />
        <label htmlFor="fees" className="fees">
          <strong style={{ fontWeight: 'bold' }}>
            I would like to help cover processing fees (applies to CC payments only)
          </strong>
          <p style={{ fontWeight: '100' }}>
            (3% of gift amount + $0.30 will be added to your total billing
            amount)
          </p>
        </label>
      </div>
      <Grid centered columns={2} className="donor-info">
        <Grid.Row>
          <Grid.Column className="title">Total gift / pledge amount:</Grid.Column>
          <Grid.Column className="value">
            $ {parseFloat(finalPrice).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const BillingAdress = ({ data, openBillAdress }) => {
  return (
    <div>
      <input
        rel="billing"
        type="checkbox"
        id="bill"
        className="check-input"
        checked={data}
        onChange={() => openBillAdress()}
      />
      <label htmlFor="bill" className="bill">
        <strong style={{ fontWeight: 'bold' }}>Billing address is different from contact address</strong>{' '}
      </label>
      {data && <Billing />}
    </div>
  )
}

const BankForm = (data) => {
  const stripe = useStripe()
  const step = data
  const [loading, setLoading] = useState(false)
  const [errorBank, setErrorBank] = useState(null)
  // eslint-disable-next-line
  const [bankId, setBankId] = useState()
  const [bank, setBank] = useState({
    routing: '',
    account: '',
  })

  const customerStripeID =
    localStorage.getItem('userStripeCustomer') ||
    sessionStorage.getItem('userStripeCustomer')

  const bankPay = (e) => {
    e.preventDefault();
    if(bank.account === '' || bank.routing === ''){
     setErrorBank("the bank number is invalid")
     setTimeout(setErrorBank, 3000);
    }else{
      setLoading(true)
      stripe
      .createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: bank.routing,
        account_number: bank.account,
        account_holder_name: step.data.expo.step2.firstName,
        account_holder_type: 'individual',
      })
      .then(function (resultBank) {
        setBankId(resultBank.token.bank_account.id)
        const data = `source=${resultBank.token.id}`
        const xhr = new XMLHttpRequest()
        xhr.withCredentials = true

        xhr.addEventListener('readystatechange', function () {
          if (this.readyState === this.DONE) {
            const responseLink = JSON.parse(this.response)
            verifySourceCustomer(customerStripeID, responseLink.id)
              .then((verifResponse) => {
                createChargeRib(
                  step.data.fees * 100,
                  'usd',
                  customerStripeID,
                ).then((respChargeRib) => {
                  // const datas = respChargeRib.data
                  // post don sas
                  postDonSAS(
                    step.data.billing.address, // addresse,
                    getUser() ? getUser().email : step.data.expo.step2.mail, // email,
                    step.data.expo.step2.firstName, // FirstName,
                    step.data.expo.step2.lastName, // LastName,
                    // datas.amount / 100,
                    step.data.fees,
                    respChargeRib.data.id,
                    respChargeRib.data.object,
                    respChargeRib.data.balance_transaction,
                    respChargeRib.data.calculated_statement_descriptor,
                    respChargeRib.data.currency,
                    respChargeRib.data.customer,
                    respChargeRib.data.description,
                    respChargeRib.data.paid,
                    'ACH - EFT',
                    step.data.billing.country,
                    'brand', // respChargeRib.data.payment_method_details.card.brand,
                    respChargeRib.data.receipt_url,
                    respChargeRib.data.status,
                    step.data.expo.step2.mailToNotify, // mailNotify,
                    step.data.expo.step2.tribute, // tributeName,
                    step.data.expo.step2.isDedicate
                      ? step.data.expo.step2.isHonor
                        ? 'In Honor Of'
                        : 'In Memory Of'
                      : ' ',
                    step.data.expo.step2.isAttend, // Will_you_attend__c
                    step.data.expo.step1.pax_names.join(' / '), // Name_of_Guests__c
                    'Pivot Expo 2023', // Campaign__c
                    step.data.expo.step2.message,
                    ' ', //period
                    step.data.expo.step2.adress,
                    step.data.billing.city,
                    step.data.expo.step2.city,
                    step.data.expo.step2.country,
                    step.data.billing.province,
                    step.data.expo.step2.province,
                    step.data.billing.zip,
                    step.data.expo.step2.zip,
                  )
                    .then(() => {
                      setLoading(false)
                      window.location.href = '/success'
                    })
                    .catch((error) => {
                      setLoading(false)
                      // window.location.href = '/error'
                    })
                })
              })
              .catch((err) => {
                setLoading(false)
                // window.location.href = '/error'
              })
          }
        })

        xhr.open(
          'POST',
          `https://api.stripe.com/v1/customers/${customerStripeID}/sources`,
        )
        xhr.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded',
        )
        xhr.setRequestHeader(
          'Authorization',
          `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
        )

        xhr.send(data);
      });
    }
    
  };

  return (
    <form id="payment-form" className="col s12" onSubmit={bankPay}>
      <div className="input-group input-group-icon">
        <label>Routing number</label>
        <input
          type="text"
          name="RoutingNumber"
          placeholder="Routing number ( 9 digit number )"
          onChange={(e) => setBank({ ...bank, routing: e.target.value })}
        />
      </div>
      <div className="input-group input-group-icon">
        <label>Account number</label>
        <input
          type="text"
          name="AccountNumber "
          placeholder="Account number ( 000123456789 )"
          onChange={(e) => setBank({ ...bank, account: e.target.value })}
        />
      </div>
      <p style={{textAlign: "center", color: "#ee6e73"}}>{errorBank}</p>
      <Button className="btn-pay">
        <span id="button-text">
          {' '}
          {loading && <Loader size="mini" active inline />} Submit
        </span>
      </Button>
    </form>
  )
}

const CheckWire = (data) =>{
  
  const step = data
  const [wireAmount, setWireAmount ] = useState()
  const [send, setSend ] = useState(true)
  const [isLoad, setIsLoad ] = useState(false)
  const [isActive, setIsActive ] = useState(true)

  const checkOrWireSubmit = (e) =>{
    e.preventDefault()
    setIsLoad(true)
    if(send){
      console.log(wireAmount)
      console.log(step.data.expo.step2.adress)
      console.log(step.data)
      const datas=""
      postDonSAS(
        step.data.expo.step2.address, // addresse,
          getUser() ? getUser().email : step.data.expo.step2.mail,
          // this.state.allDataSteps.mail, // email,
          step.data.expo.step2.firstName, // FirstName,
          step.data.expo.step2.lastName, // LastName,
          wireAmount,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          datas,
          "Credit Card",
          step.data.billing.country, //billing
          datas,
          datas,
          datas,
          step.data.expo.step2.mailToNotify, // mailNotify,
          step.data.expo.step2.tribute, // tributeName,
          // this.state.allDataSteps.isHonor ? "In Honor Of": "In Memory Of",//Type_of_Tribute__c
          step.data.expo.step2.isDedicate ? (step.data.expo.step2.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
          step.data.expo.step1.isAttend, // Will_you_attend__c
          step.data.expo.step1.pax_names.join(" / "), // Name_of_Guests__c
          "Pivot Expo 2023", // Campaign__c
          step.data.expo.step2.message,
          "",
          step.data.expo.step2.adress,
          step.data.billing.city,
          step.data.expo.step2.city,
          step.data.expo.step2.country,
          step.data.billing.province,
          step.data.expo.step2.province,
          step.data.billing.zip,
          step.data.expo.step2.zip,
          step.data.expo.step1.pax,
          "Expo2023", // Type_formulaire
          "Promesse"
      )
    .then(responseDonSas =>{
      console.log(responseDonSas)
      window.location.href = "/success"
      setIsLoad(false)
    })
    .catch(err=>{console.log(err)})
    }
  }

  const onChangeSend = () =>{
    setSend(send)
  }

  return (
    <form id="payment-form" className="col s12">
       <div className="input-group input-group-icon" style={{marginBottom:"1em"}}>
      <input
        rel="check"
        type="checkbox"
        id="check"
        className="check-input"
        checked={send}
        onChange={onChangeSend}
      />
      <label htmlFor="check" className="check">
        <strong style={{ fontWeight: 'bold' }}>I will send my gift by check or wire.</strong>{' '}
      </label>
    </div><Divider hidden/>
    <div className="input-group input-group-icon">
      <label>Pledge amount:</label>
            <NumberFormat
              className="input-other"
              thousandSeparator={true}
              placeholder="$ 0.00"
              prefix={'$'}
              value={`$ ${
                parseFloat(wireAmount).toFixed(2)
              }`}
              onValueChange={ (value) =>{ 
                setWireAmount(value.value) 
                console.log(value.value)
                if(value.value > 0){
                  setIsActive(false)
                }else{
                  setIsActive(true)
                }
              } }          
            />
            <label>Checks made out to "Pivot" can be mailed to:</label><br/>
            <label>75 North Main St. #2075</label><br/>
            <label>Randolph, MA 02368</label>
            <p style={{marginTop:'1em'}}>If you would like to make a gift by wire, contact giving@pivotworks.org for information.</p>
            {/* <Button className="btn-pay" onClick={checkOrWireSubmit}>Submit</Button> */}
            <Button className="btn-pay" onClick={checkOrWireSubmit} disabled={isActive}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoad && (
                  <div style={{ marginRight: "10px" }}>
                    <Loader size="mini" active inline />
                  </div>
                )}
                <div id="button-text">SUBMIT</div>
              </div>
            </Button>
    </div>
  
  </form>
  )
}

const TabMenu = ({ changeTab }) => {
  // const [isCreditTab, setIsCreditTab] = useState(true)
  const [tabs, setTabs] = useState(1)

  useEffect(() => {
    // changeTab(isCreditTab ? 0 : 1)
    changeTab(tabs)

    // eslint-disable-next-line
  }, [tabs])
  return (
    <div className="bloc-tabs">
      <div
        // className={isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
        className={tabs === 1 ? 'tabs active-tabs' : 'tabs'}
        onClick={() => setTabs(1)}
      >
        Credit card
      </div>
      {/* <div
       className={tabs === 2 ? 'tabs active-tabs' : 'tabs'}
       onClick={() => setTabs(2)}
        // className={!isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
      >
        Bank transfer
      </div> */}
      <div
        className={tabs === 3 ? 'tabs active-tabs' : 'tabs'}
        onClick={() => setTabs(3)}
        // className={!isCreditTab ? 'tabs active-tabs' : 'tabs'}
        // onClick={() => setIsCreditTab(!isCreditTab)}
      >
       Check or Wire
      </div>
    </div>
  )
}
