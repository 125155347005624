import React, { Component } from 'react'
import { Button, Container } from 'semantic-ui-react'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements'

import { updateCustomerPayment } from '../../utils/axios'
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  }
}

class _SplitFieldsForm extends Component {
  state = {
    errorMessage: '',
    display: '',
    payBtn: 'Pay',
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    error: '',
  }

  handleSubmit = async (evt) => {
    evt.preventDefault()
    this.setState({ display: 'none' })

    const custId = localStorage.getItem('customerId') || sessionStorage.getItem('customerId')

    if (this.props.stripe) {
      this.setState({ loading: true })
      try {
        let stripeToken = await this.props.stripe.createToken()

        updateCustomerPayment(custId, stripeToken.token.id).then((resp) => {
          window.location.href = '/home'
        })
      } catch (error) {
        this.setState({ error: 'number invalid' })
        setTimeout(() => {
          this.setState({ error: '' })
        }, 4000)
        this.setState({ display: '' })
      }
    } else {
      this.setState({ display: '' })
    }
  }

  handleChange = (e) => {
    if (e.empty) {
      this.setState({ disabled: true })
    } else {
      if (e.error !== undefined) {
        this.setState({ errorMessage: e.error.code })
        this.setState({ disabled: true })
      } else {
        this.setState({ errorMessage: '' })
      }
      if (e.elementType === 'cardNumber') {
        this.setState({ cardNumber: true })
      }
      if (e.elementType === 'cardExpiry') {
        this.setState({ cardExpiry: true })
      }
      if (e.elementType === 'cardCvc') {
        this.setState({ cardCvc: true })
      }
      if (
        this.state.cardNumber &&
        this.state.cardExpiry &&
        this.state.cardCvc
      ) {
        this.setState({ disabled: false })
      }
    }
  }

  componentDidMount = () => {}

  render() {
    return (
      <div>
        {/* <NavLinkPages/> */}

        <Container text style={{ width: '100%' }}>
          <h2>Modify card information</h2>
          <div className="payment-details">
            <form onSubmit={this.handleSubmit}>
              <div className="split-form">
                <label>
                  Card number
                  <CardNumberElement
                    {...createOptions()}
                    onChange={this.handleChange}
                  />
                </label>
                <label>
                  Expiration date
                  <CardExpiryElement
                    {...createOptions()}
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <div className="split-form">
                <label>
                  CVC
                  <CardCVCElement
                    {...createOptions()}
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <div className="error" role="alert">
                <center style={{ color: 'red' }}>
                  {this.state.errorMessage}
                </center>
              </div>
              <p style={{ color: 'red', textAlign: 'center' }}>
                {this.state.error}
              </p>
              <Button style={{ display: this.state.display }} className="update-card-btn">
                Update card{' '}
              </Button>
            </form>
          </div>
        </Container>

        {/* <FooterPage/> */}
      </div>
    )
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm)

export default class SplitFieldsDemo extends Component {
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <SplitFieldsForm handleResult={this.props.handleResult} />
        </Elements>
      </StripeProvider>
    )
  }
}
