import React, { Component } from "react";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { connect } from "react-redux";
import { Button, Placeholder } from "semantic-ui-react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from "react-stripe-elements";

import {
  createChargeCustomerSaved,
  createPI,
  createSessionStorage,
  getAccountByEmail,
  getDonationByAccountId,
  getUtilsDataByEmail,
  postAccount,
  postDonSAS,
  postStripe,
  retrievePaymentIntent,
  updateAccount,
  updateCustomerPayment,
  createDonSasBase,
  postDonSaS_Card,
  getCustomerStripeByEmail
} from "../../utils/axios";
import { getUser } from "../../utils/Common";

var pholder = true;

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
  types: [
    {
      type: "warning",
      background: "red",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "warning",
      },
    },
    {
      type: "error",
      background: "indianred",
      duration: 9000,
      dismissible: true,
    },
  ],
});

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

class _SplitFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      LastName: "",
      FirstName: "",
      display: "none",
      payBtn: "SUBMIT",
      loading: false,
      disabled: true,
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      donationListe: null,
      AccountData: null,
      customer_user: null,
      PriceId: null,
      period: null,
      count: null,
      subPeriod: "One-Time Gift",
      allDataSteps: null,
      email: null
    };
  }

  handleChange = (e) => {
    let { cardNumber, cardExpiry, cardCvc } = this.state;
  
    if (e.empty) {
      this.setState({ disabled: true });
    } else {
      if (e.error !== undefined) {
        this.setState({
          errorMessage: e.error.code,
          disabled: true,
        });
      } else {
        this.setState({ errorMessage: "" });
      }
  
      if (e.elementType === "cardNumber") {
        cardNumber = true;
      }
      if (e.elementType === "cardExpiry") {
        cardExpiry = true;
      }
      if (e.elementType === "cardCvc") {
        cardCvc = true;
      }
  
      if (cardNumber && cardExpiry && cardCvc) {
        this.setState({ disabled: false });
      }
  
      this.setState({ cardNumber, cardExpiry, cardCvc });
    }
  };
  
  componentDidMount = () => {
    let dataStep = this.props.allSteps.step1.mail === undefined ? this.props.allSteps.step2 : this.props.allSteps.step1;
    this.setState({ allDataSteps: dataStep });

    

    // console.log("nombre des Guests: ",this.props.allSteps.step1.pax)

    if (this.props.subscription) {
      let period = "week", count = 1, subPeriod = "Weekly";
      
      if (this.props.subscription === "monthlySubscription") {
        period = "month";
        subPeriod = "Monthly";
      } else if (this.props.subscription === "quarterlySubscription") {
        period = "month";
        count = 3;
        subPeriod = "Quarterly";
      } else if (this.props.subscription === "annuallySubscription") {
        period = "year";
        subPeriod = "Annually";
      }
    
      this.setState({ period, count, subPeriod });
    } else {
      this.setState({ subPeriod: "One-Time Gift" });
    }
    

    const setAccountStorage = (account) => {
      sessionStorage.setItem("userStripeAccount", account);
      localStorage.setItem("userStripeAccount", account);
    };

    const setCustomerStorage = (customer) => {
      sessionStorage.setItem("userStripeCustomer", customer);
      localStorage.setItem("userStripeCustomer", customer);
    };

    if (getUser() === null || getUser() === undefined) {
      // USER NOT CONNECTED

      let dataStep =
        this.props.allSteps.step1.mail === undefined
          ? this.props.allSteps.step2
          : this.props.allSteps.step1;
      getAccountByEmail(dataStep.mail).then((response) => {
        if (response.data.length !== 0) {
          setAccountStorage(response.data[0].Id)
          this.setState({ AccountData: response.data[0].Id }); //id account
          getUtilsDataByEmail(dataStep.mail).then((user) => {
            if (user.data.rowCount !== 0) {
              setCustomerStorage(user.data.rows[0].customer_id)
              this.setState({ customer_user: user.data.rows[0].customer_id });
            } else {

              try {
                getCustomerStripeByEmail(dataStep.mail).then(resp => {
                if(resp.data.id){
                  this.setState({customer_user: resp.data.id})
                  setCustomerStorage(resp.data.id)
                }else{
                  postStripe({ email: dataStep.mail }, "create-customer").then(
                    (userStripe) => {
                      setCustomerStorage(userStripe.data.customer.id)
                      this.setState({ customer_user: userStripe.data.customer.id });
                      //add to localstorage userStripe
                    }
                  );
                }
              })
              } catch (error) {
                console.log("dans le catch"+error)
              }
              
              // link with Account SF
            }
          });
        } 
          else {
            // create Account SF
            postAccount({
              Name: dataStep.lastName,
              Prenom__c: dataStep.firstName,
              Email__c: dataStep.mail,
              Origine_connecteur__c: "portail",
              Portail_secu__c: dataStep.mail,
              // contact
              City_contact__c: dataStep.city,
              State_contact__c: dataStep.province,
              Zip_contact__c: dataStep.zip,
              Country_contact__c: dataStep.country,
              INDIV_Adresse_1_contact__c: dataStep.adress,
              //billing
              // AD_Ville__c: step.data.billing.city,
              // Country__c: step.data.billing.country,
              // AD_Ligne_2__c: step.data.billing.address,
              // State__c: step.data.billing.province,
              // AD_Code_Postal__c: step.data.billing.zip,
            }).then((responseAccount) => {
              setAccountStorage(responseAccount.data.ret.id)
              this.setState({ AccountData: responseAccount.data.ret.id });
              // create customer stripe
              postStripe({ email: dataStep.mail }, "create-customer").then(
                (userStripe) => {
                  setCustomerStorage(userStripe.data.customer.id)
                  this.setState({ customer_user: userStripe.data.customer.id });

                  postStripe(
                    {
                      account_id: responseAccount.data.ret.id,
                      customer_id: userStripe.data.customer.id,
                      email: dataStep.mail,
                    },
                    "create-utils-data"
                  )
                }
              );
            });
          }
      });
      // fin
    } else {
      getUtilsDataByEmail(getUser().email).then((user) => {
        createSessionStorage("customerId", user.data.rows[0].customer_id);
        if (user.data.rowCount !== 0) {
          this.setState({ customer_user: user.data.rows[0].customer_id });
        }
      });

      getAccountByEmail(getUser().email).then((response) => {
        if (response.data.length !== 0) {
          getDonationByAccountId(response.data[0].Id).then((responseDon) => {
            this.setState({ donationListe: responseDon.data.length });
            if (responseDon.data.length !== undefined) {
              setTimeout(setVisible, 100);
            }
          });
          this.setState({ AccountData: response.data[0].Id }); //id account
        }
      });
    }

    function setVisible() {
      pholder = true;
    }
  };

  handleSubmit = async (evt) => {
    evt.preventDefault();

    const roadToSuccess = () =>{
      window.location.href = "/success"
    }

    if (this.props.stripe) {
      this.setState({ loading: true });
      let stripeToken = await this.props.stripe.createToken();

       // AJOUT DANS LA BASE DEBUT
       const data ={
        address_contact__c: this.props.billing.address,
        email__c: getUser() ? getUser().email : this.state.allDataSteps.mail,
        first_name__c: this.state.allDataSteps.firstName,
        name: this.state.allDataSteps.lastName,
        montant__c: this.props.finalPrice,
        country_contact__c: this.state.allDataSteps.country,
        address__c: this.props.billing.address,
        city_contact__c: this.state.allDataSteps.city,
        city__c: this.props.billing.city,
        country__c: this.props.billing.country,
        state_contact__c: this.state.allDataSteps.province,
        state__c: this.props.billing.province,
        zip_contact__c: this.state.allDataSteps.zip,
        zip__c: this.props.billing.zip,
       }
      // createDonSasBase(data);
        // AJOUT DANS LA BASE FIN

      // if (window.location.href.split("/")[3] === "expo2023") {
      //       // AJOUT DANS LA BASE DEBUT
      //       createDonSasBase(
      //         this.props.billing.address, // addresse,
      //         getUser() ? getUser().email : this.state.allDataSteps.mail,
      //         // this.state.allDataSteps.mail, // email,
      //         this.state.allDataSteps.firstName, // FirstName,
      //         this.state.allDataSteps.lastName, // LastName,
      //         this.props.finalPrice,
      //         " ",// datas.id,
      //         " ",//datas.object,
      //         " ",// datas.balance_transaction,
      //         " ",// datas.calculated_statement_descriptor,
      //         " ",// datas.currency,
      //         " ",// datas.customer,
      //         " ",// datas.description,
      //         " ",// datas.paid,
      //         "Credit Card",
      //         this.props.billing.country, //billing
      //         " ",// datas.payment_method_details.card.brand,
      //         " ",// datas.receipt_url,
      //         " ",// datas.status,
      //         this.state.allDataSteps.mailToNotify, // mailNotify,
      //         this.state.allDataSteps.tribute, // tributeName,
      //         // this.state.allDataSteps.isHonor ? "In Honor Of": "In Memory Of",//Type_of_Tribute__c
      //         this.state.allDataSteps.isDedicate ? (this.state.allDataSteps.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
      //         this.props.allSteps.step1.isAttend, // Will_you_attend__c
      //         this.props.allSteps.step1.pax_names.join(" / "), // Name_of_Guests__c
      //         "Pivot Expo 2023", // Campaign__c
      //         this.state.allDataSteps.message,
      //         this.state.subPeriod,
      //         this.state.allDataSteps.adress,
      //         this.props.billing.city,
      //         this.state.allDataSteps.city,
      //         this.state.allDataSteps.country,
      //         this.props.billing.province,
      //         this.state.allDataSteps.province,
      //         this.props.billing.zip,
      //         this.state.allDataSteps.zip,
      //         this.props.allSteps.step1.pax,
      //         "Expo2023", // Type_formulaire
      //         "Payé"
      //       );
      //         // AJOUT DANS LA BASE FIN
      //     if (getUser() === null || getUser() === undefined) {
      //       // USER NOT CONNECTED
      //     }else{
      //       // USER CONNECTED
      //       if( getUser().email !== this.props.allSteps.step1.mail){
      //         try {
      //           updateAccount(
      //             this.state.AccountData,
      //             this.props.allSteps.step1.lastName, 
      //             this.props.allSteps.step1.firstName,
      //             " ", //phone
      //             this.props.allSteps.step1.adress,
      //             this.props.allSteps.step1.city, 
      //             this.props.allSteps.step1.province, 
      //             this.props.allSteps.step1.country, 
      //             this.props.allSteps.step1.zip, 
      //             this.props.allSteps.step1.mail
      //           )
      //         } catch (error) {
      //           console.log(error)
      //         }
      //       }
      //     }

           

      //   if (this.props.subscription === false) {
      //     //PAY SIMPLE

      //     const paymentProcess = async () => {
      //       await updateCustomerPayment(this.state.customer_user, stripeToken.token.id)
      //       const createStripeCharge = await createChargeCustomerSaved(this.state.customer_user, this.props.finalPrice * 1000000)
      //       if(createStripeCharge.data.id){
      //         // POST TO STRIPE
      //         const createDonSAS = await postDonSAS(
      //           this.props.billing.address, // addresse,
      //             getUser() ? getUser().email : this.state.allDataSteps.mail,
      //             this.state.allDataSteps.firstName, // FirstName,
      //             this.state.allDataSteps.lastName, // LastName,
      //             this.props.finalPrice,
      //             createStripeCharge.data.id,
      //             createStripeCharge.data.object,
      //             createStripeCharge.data.balance_transaction,
      //             createStripeCharge.data.calculated_statement_descriptor,
      //             createStripeCharge.data.currency,
      //             createStripeCharge.data.customer,
      //             createStripeCharge.data.description,
      //             createStripeCharge.data.paid,
      //             "Credit Card",
      //             this.props.billing.country, //billing
      //             createStripeCharge.data.payment_method_details.card.brand,
      //             createStripeCharge.data.receipt_url,
      //             createStripeCharge.data.status,
      //             this.state.allDataSteps.mailToNotify, // mailNotify,
      //             this.state.allDataSteps.tribute, // tributeName,
      //             // this.state.allDataSteps.isHonor ? "In Honor Of": "In Memory Of",//Type_of_Tribute__c
      //             this.state.allDataSteps.isDedicate ? (this.state.allDataSteps.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
      //             this.props.allSteps.step1.isAttend, // Will_you_attend__c
      //             this.props.allSteps.step1.pax_names.join(" / "), // Name_of_Guests__c
      //             "Pivot Expo 2023", // Campaign__c
      //             this.state.allDataSteps.message,
      //             this.state.subPeriod,
      //             this.state.allDataSteps.adress,
      //             this.props.billing.city,
      //             this.state.allDataSteps.city,
      //             this.state.allDataSteps.country,
      //             this.props.billing.province,
      //             this.state.allDataSteps.province,
      //             this.props.billing.zip,
      //             this.state.allDataSteps.zip,
      //             this.props.allSteps.step1.pax,
      //             "Expo2023", // Type_formulaire
      //             "Payé"
      //         )
      //         if (createDonSAS.data.ret.id){
      //           roadToSuccess();
      //         }else{
      //           // error for createDonSAS
      //           window.location.href = "/error";
      //         }
              
      //       }else{
      //         // ERREUR createStripeCharge
      //         window.location.href = "/error";
      //       }
      //     }

      //     paymentProcess();
      //   }
      // }

      if (window.location.href.split("/")[3] === "eofy22") {

      if (getUser() === null || getUser() === undefined) {
        // USER NOT CONNECTED
      }else{
        // USER CONNECTED
        if( getUser().email !== this.props.allSteps.step1.mail){
          updateAccount(
            this.state.AccountData,
            this.props.allSteps.step1.lastName, 
            this.props.allSteps.step1.firstName,
            " ", //phone
            this.props.allSteps.step1.adress,
            this.props.allSteps.step1.city, 
            this.props.allSteps.step1.province, 
            this.props.allSteps.step1.country, 
            this.props.allSteps.step1.zip, 
            this.props.allSteps.step1.mail
          ).then(respUpdateAccount =>{
            console.log('respUpdateAccount:', respUpdateAccount)
          })
          console.log("email different: ",getUser().email," email saisie: ",this.props.allSteps.step1.mail)
        }
      }
    if (this.props.subscription === false) {
      //PAY SIMPLE
      updateCustomerPayment(
        this.state.customer_user,
        stripeToken.token.id
      ).then(() => {
        // 4-CREATE CHARGE
        createChargeCustomerSaved(
          this.state.customer_user,
          this.props.finalPrice * 1000000
        )
        .then((response) => {
            const datas = response.data;
            
            postDonSAS(
              this.state.allDataSteps.adress,
              getUser() ? getUser().email : this.state.allDataSteps.mail,
              this.state.allDataSteps.firstName,
              this.state.allDataSteps.lastName,
              this.props.finalPrice,
              datas.id,
              datas.object,
              datas.balance_transaction,
              datas.calculated_statement_descriptor,
              datas.currency,
              datas.customer,
              datas.description,
              datas.paid,
              "Credit Card",
              this.props.billing.country,
              datas.payment_method_details.card?.brand,
              datas.receipt_url,
              datas.status,
              this.state.allDataSteps.mailToNotify,
              this.state.allDataSteps.mailToNotify,
              this.state.allDataSteps.isDedicate ? (this.state.allDataSteps.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
              "",
              "",
              "EOFY22 Appeal",
              this.state.allDataSteps.message,
              this.state.subPeriod,
              this.state.allDataSteps.adress,
              this.props.billing.city,
              this.state.allDataSteps.city,
              this.state.allDataSteps.country,
              this.props.billing.province,
              this.state.allDataSteps.province,
              this.props.billing.zip,
              this.state.allDataSteps.zip,
              "",
              "EOFY22 Appeal",
              ""
            )
            .then(resp =>{
              console.log(resp)
              window.setTimeout(roadToSuccess, 3000);
            })
            .catch(()=>{
              // erreur sur postDonSas
              window.location.href = "/error";
            })
          })
        .catch(() => {
            // erreur sur creation de la charge
            window.location.href = "/error";
          });
      })
      .catch(()=>{
        // erreur sur updateCustomer payement
        window.location.href = "/error";
      });
    }
      }
      
      if (window.location.href.split("/")[3] === "expo2023") {

      if (getUser() === null || getUser() === undefined) {
        // USER NOT CONNECTED
      }else{
        // USER CONNECTED
        if( getUser().email !== this.props.allSteps.step1.mail){
          updateAccount(
            this.state.AccountData,
            this.props.allSteps.step1.lastName, 
            this.props.allSteps.step1.firstName,
            " ", //phone
            this.props.allSteps.step1.adress,
            this.props.allSteps.step1.city, 
            this.props.allSteps.step1.province, 
            this.props.allSteps.step1.country, 
            this.props.allSteps.step1.zip, 
            this.props.allSteps.step1.mail
          ).then(respUpdateAccount =>{
            console.log('respUpdateAccount:', respUpdateAccount)
          })
          console.log("email different: ",getUser().email," email saisie: ",this.props.allSteps.step1.mail)
        }
      }
    if (this.props.subscription === false) {
      //PAY SIMPLE
      updateCustomerPayment(
        this.state.customer_user,
        stripeToken.token.id
      ).then(() => {
        // 4-CREATE CHARGE
        createChargeCustomerSaved(
          this.state.customer_user,
          this.props.finalPrice * 1000000
        )
        .then((response) => {
            const datas = response.data;
            
            postDonSAS(
              this.state.allDataSteps.adress,
              getUser() ? getUser().email : this.state.allDataSteps.mail,
              this.state.allDataSteps.firstName,
              this.state.allDataSteps.lastName,
              this.props.finalPrice,
              datas.id,
              datas.object,
              datas.balance_transaction,
              datas.calculated_statement_descriptor,
              datas.currency,
              datas.customer,
              datas.description,
              datas.paid,
              "Credit Card",
              this.props.billing.country,
              datas.payment_method_details.card?.brand,
              datas.receipt_url,
              datas.status,
              this.state.allDataSteps.mailToNotify,
              this.state.allDataSteps.mailToNotify,
              this.state.allDataSteps.isDedicate ? (this.state.allDataSteps.isHonor ? "In Honor Of" : "In Memory Of" ) : " ",
              this.props.allSteps.step1.isAttend,
              this.props.allSteps.step1.pax_names.join(" / "),
              "Pivot Expo 2023",
              this.state.allDataSteps.message,
              this.state.subPeriod,
              this.state.allDataSteps.adress,
              this.props.billing.city,
              this.state.allDataSteps.city,
              this.state.allDataSteps.country,
              this.props.billing.province,
              this.state.allDataSteps.province,
              this.props.billing.zip,
              this.state.allDataSteps.zip,
              this.props.allSteps.step1.pax,
              "Expo2023",
              "Payé"
            )
            .then(resp =>{
              console.log(resp)
              window.setTimeout(roadToSuccess, 3000);
            })
            .catch(()=>{
              // erreur sur postDonSas
              window.location.href = "/error";
            })
          })
        .catch(() => {
            // erreur sur creation de la charge
            window.location.href = "/error";
          });
      })
      .catch(()=>{
        // erreur sur updateCustomer payement
        window.location.href = "/error";
      });
    }
      }

      if (window.location.href.split("/")[3] === "") {

            //backup_tech
            const datasf =""
            const type_pay="Credit Card"
            const subscription = this.state.subPeriod
            postDonSaS_Card(datasf, this.props, type_pay, 'Classique', 'TECH_backup', subscription)
            //fin backup_tech
        
            if (getUser() === null || getUser() === undefined) {
              // USER NOT CONNECTED
              }else{
              // USER CONNECTED
              if( getUser().email !== this.props.allSteps.step1.mail){
                updateAccount(
                  this.state.AccountData,
                  this.props.allSteps.step1.lastName, 
                  this.props.allSteps.step1.firstName,
                  " ", //phone
                  this.props.allSteps.step1.adress,
                  this.props.allSteps.step1.city, 
                  this.props.allSteps.step1.province, 
                  this.props.allSteps.step1.country, 
                  this.props.allSteps.step1.zip, 
                  this.props.allSteps.step1.mail
                ).then(respUpdateAccount =>{
                  console.log('respUpdateAccount:', respUpdateAccount)
                })
                console.log("email different: ",getUser().email," email saisie: ",this.props.allSteps.step1.mail)
              }
            }

        if (this.props.subscription === false) {
          console.log("pay simple")
          // ****************************PAY SIMPLE************************************
          updateCustomerPayment(
            this.state.customer_user,
            stripeToken.token.id
          ).then(() => {
            // 4-CREATE CHARGE
            createChargeCustomerSaved(
              this.state.customer_user,
              this.props.finalPrice * 1000000
            ).then((response) => {
                const datas = response.data;
                postDonSAS(
                  this.props.billing.address, // addresse,
                  getUser() ? getUser().email : this.props.allSteps.step1.mail,
                  // this.props.allSteps.step1.mail, // email,
                  this.props.allSteps.step1.firstName, // FirstName,
                  this.props.allSteps.step1.lastName, // LastName,
                  this.props.finalPrice,
                  datas.id,
                  datas.object,
                  datas.balance_transaction,
                  datas.calculated_statement_descriptor,
                  datas.currency,
                  datas.customer,
                  datas.description,
                  datas.paid,
                  "Credit Card",
                  this.props.billing.country,
                  datas.payment_method_details.card.brand,
                  datas.receipt_url,
                  datas.status,
                  this.props.allSteps.step1.mailToNotify, // mailNotify,
                  this.props.allSteps.step1.tribute, // tributeName,
                  this.props.allSteps.step1.isDedicate ? this.props.allSteps.step1.isHonor ? "In Honor Of" : "In Memory Of" : " ",
                  " ", // Will_you_attend__c
                  " ", // Name_of_Guests__c
                  " ", // Campaign__c
                  this.props.allSteps.step1.message,
                  this.state.subPeriod,
                  this.props.allSteps.step1.adress,
                  this.props.billing.city,
                  this.props.allSteps.step1.city,
                  this.props.allSteps.step1.country,
                  this.props.billing.province,
                  this.props.allSteps.step1.province,
                  this.props.billing.zip,
                  this.props.allSteps.step1.zip,
                  " ", //pax
                  "Classique ",//Type_formulaire
                  "Payé"
                );
              })
              .then(() => {
                // window.location.href = "/success";
                window.setTimeout(roadToSuccess, 3000)
              })
              .catch(err => {
                window.location.href = "/error";
              });
          });
            } else {
              console.log("abonmnt")
            // **************************************ABONEMENT**************************************
            postStripe(
              {
                productId: process.env.REACT_APP_STRIPE_PRODUCT,
                price: this.props.finalPrice * 100,
                currency: "usd",
                period: this.state.period,
                count: this.state.count,
              },
              "create-price"
            ).then((resultCreatePrice) => {
              console.log("1-result create price ",resultCreatePrice)
              this.setState({ PriceId: resultCreatePrice.data.result.id });
              createPI(
                resultCreatePrice.data.result.id,
                this.state.customer_user
              ).then((respPI) => {
                console.log("2- create PI ",respPI)
                retrievePaymentIntent(respPI.data.clientSecret.id).then(
                  (retrieveResponse) => {
                    console.log("3-retrieve PI ",retrieveResponse)
                    this.props.stripe
                      .confirmCardPayment(
                        retrieveResponse.data.retrieve.client_secret,
                        {
                          payment_method: {
                            card: {
                              token: stripeToken.token.id,
                            },
                          },
                        }
                      )
                      .then((result) => {
                        this.setState({ loading: false });
                        if (result.error) {
                          window.location.href = "/error";
                        } else {
                          retrievePaymentIntent(respPI.data.clientSecret.id).then(
                            (retrieveResponse) => {
                              const datas =
                                retrieveResponse.data.retrieve.charges.data[0];
                              postDonSAS(
                                this.props.billing.address, // addresse,
                                getUser() ? getUser().email : this.props.allSteps.step1.mail,
                                // this.props.allSteps.step1.mail, // email,
                                this.props.allSteps.step1.firstName, // FirstName,
                                this.props.allSteps.step1.lastName, // LastName,
                                this.props.finalPrice,
                                datas.id,
                                datas.object,
                                datas.balance_transaction,
                                datas.calculated_statement_descriptor,
                                datas.currency,
                                datas.customer,
                                datas.description,
                                datas.paid,
                                "Credit Card",
                                this.props.allSteps.step1.country,
                                datas.payment_method_details.card.brand,
                                datas.receipt_url,
                                datas.status,
                                this.props.allSteps.step1.mailToNotify, // mailNotify,
                                this.props.allSteps.step1.tribute, // tributeName,
                                this.props.allSteps.step1.isDedicate
                                  ? this.props.allSteps.step1.isHonor
                                    ? "In Honor Of"
                                    : "In Memory Of"
                                  : " ",
                                " ", // Will_you_attend__c
                                " ", // Name_of_Guests__c
                                " ", // Campaign__c
                                this.props.allSteps.step1.message,
                                this.state.subPeriod,
                                this.props.allSteps.step1.adress,
                                this.props.billing.city,
                                this.props.allSteps.step1.city,
                                this.props.allSteps.step1.country,
                                this.props.billing.province,
                                this.props.allSteps.step1.province,
                                this.props.billing.zip,
                                this.props.allSteps.step1.zip,
                                " ",//pax
                                "Classique",//Type_formulaire
                                "Payé"
                              ).then(() => {
                                  // window.location.href = "/success";
                                  window.setTimeout(roadToSuccess, 3000)
                                })
                                .catch(err => {
                                  window.location.href = "/error";
                                });
                            }
                          );
                        }
                      });
                  }
                );
              });
            });
          }
      }
    } else {
      notyf.error({
        message: "Stripe.js hasn't loaded yet!",
        duration: 9000,
        icon: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Placeholder hidden={pholder ? true : false}>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>

        <form
          onSubmit={this.handleSubmit.bind(this)}
          hidden={pholder ? false : true}
          // autoComplete="cc-csc"
          autocomplete="off"
        >
          <div className="split-form">
          {/* <input autocomplete="false" name="hidden" type="text" style="display:none;"/> */}
            <label>
              Card number
              <CardNumberElement
                {...createOptions()}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Expiration date
              <CardExpiryElement
                {...createOptions()}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="split-form">
            <label>
              CVC
              <CardCVCElement
                {...createOptions()}
                onChange={this.handleChange}
              />
            </label>
            {/* <label>
            Postal code
            <input
              name="name"
              type="text"
              placeholder="94115"
              className="StripeElement"
              required
            />
          </label> */}
          </div>
          <div className="error" role="alert">
            <center style={{ color: "red" }}>{this.state.errorMessage}</center>
          </div>
          {/* <button style ={{ display: this.state.display }}>Pay</button> */}
          <Button loading={this.state.loading} disabled={this.state.disabled}>
            {this.state.payBtn}{" "}
          </Button>
        </form>
      </div>
    );
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

const mapStateToProps = (state) => ({
  allSteps: state.expo,
  finalPrices: state.fees,
  account: state.account,
  billing: state.billing,
  subscription: state.subscription,
  allData: state,
});

class SplitFieldsDemo extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <SplitFieldsForm
            allData={this.props}
            allSteps={this.props.allSteps}
            billing={this.props.billing}
            account={this.props.account}
            finalPrice={this.props.finalPrices}
            handleResult={this.props.handleResult}
            subscription={this.props.subscription}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default connect(mapStateToProps)(SplitFieldsDemo);
