export const COUNTRIES = [
  { key: 'au', value: 'Australia', text: 'Australia' },
  { key: 'ca', value: 'Canada', text: 'Canada' },
  { key: 'ch', value: 'Chile', text: 'Chile' },
  { key: 'fl', value: 'Finland', text: 'Finland' },
  { key: 'fr', value: 'France', text: 'France' },
  { key: 'gr', value: 'Germany', text: 'Germany' },
  { key: 'gb', value: 'Gibraltar', text: 'Gibraltar' },
  { key: 'ir', value: 'Ireland', text: 'Ireland' },
  { key: 'jr', value: 'Jersey', text: 'Jersey' },
  { key: 'li', value: 'Liechtenstein', text: 'Liechtenstein' },
  { key: 'ma', value: 'Madagascar', text: 'Madagascar' },
  { key: 'nz', value: 'New Zealand', text: 'New Zealand' },
  { key: 'sa', value: 'South Africa', text: 'South Africa' },
  { key: 'se', value: 'Sweden', text: 'Sweden' },
  { key: 'sw', value: 'Switzerland', text: 'Switzerland' },
  { key: 'tu', value: 'Turkey', text: 'Turkey' },
  { key: 'ug', value: 'Uganda', text: 'Uganda' },
  { key: 'uk', value: 'United Kingdom', text: 'United Kingdom' },
  { key: 'us', value: 'United States', text: 'United States' },
]
