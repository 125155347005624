import axios from "axios";
import {getToken, getUser} from "./Common";

const urlApi = process.env.REACT_APP_API_URL;
const linkApi = `${urlApi}/api/pivot`;
const stripeKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

export const checkCode6 = (email, code) =>{
    const urlApi = process.env.REACT_APP_API_URL;
    return axios.get(`${urlApi}/user/${email}/${code}/check_code/public`, )
        .then(res => { return res; })
}

export const updatePassword = (email, data) =>{
    const urlApi = process.env.REACT_APP_API_URL;
    const token2 = getToken();
    return axios.put(`${urlApi}/user/${email}/update/password/public`, data, {headers: {Authorization: "Bearer " + token2}})
}

export const getAccountByEmail = (email) => {
    try{
        return axios.get(`${linkApi}/${email}/account/public`);
    } catch (err) {
        return err
    }
}

export const updateAccount = ( 
    AccountId,
    LastName, 
    FirstName, 
    PersonMobilePhone,
    INDIV_Adresse_1_contact__c,
    City_contact__c,
    State_contact__c,
    Country_contact__c,
    Zip_contact__c,
    PersonEmail2__c
    ) => {
// export const updateAccount = ( AccountId, LastName, FirstName, PersonMobilePhone, PersonMailingStreet, PersonMailingCity, PersonMailingState, PersonMailingCountry, PersonMailingPostalCode,
//     city_contact, zip_contact, state_contact, country_contact, address_contact) => {
    try{
        return axios.put(`${linkApi}/account/update/public`, {
            AccountId: AccountId, 
            LastName: LastName, 
            FirstName: FirstName, 
            PersonMobilePhone: PersonMobilePhone,
            INDIV_Adresse_1_contact__c: INDIV_Adresse_1_contact__c,
            City_contact__c: City_contact__c,
            State_contact__c: State_contact__c,
            Country_contact__c: Country_contact__c,
            Zip_contact__c: Zip_contact__c,
            PersonEmail2__c: PersonEmail2__c
            });
        // return axios.put(`${linkApi}/account/update/public`, {AccountId: AccountId, LastName: LastName, FirstName: FirstName, PersonMobilePhone: PersonMobilePhone,
        //      PersonMailingStreet: PersonMailingStreet, PersonMailingCity: PersonMailingCity, PersonMailingState: PersonMailingState,
        //       PersonMailingCountry: PersonMailingCountry, PersonMailingPostalCode: PersonMailingPostalCode, city_contact: city_contact, zip_contact: zip_contact, state_contact: state_contact, 
        //       country_contact: country_contact, address_contact: address_contact
        //     });
    } catch (err) {
        return err
    }
}


export const getDonationByAccountId = (AccountId, security) => {
    try{
        return axios.get(`${linkApi}/${AccountId}/donation/public`, security);
    } catch (err) {
        return err
    }
}

export const getAllEvent = () => {
    try{
        return axios.get(`${linkApi}/campagne/public`);
    } catch (err) {
        return err
    }
}

export const postAccount = (
    Name, Prenom__c, Email__c, Origine_connecteur__c, 
    // Phone, address, city, Country, postalCode,
     Portail_secu__c,
    //contact
    City_contact__c, State_contact__c, Zip_contact__c, Country_contact__c, INDIV_Adresse_1_contact__c,
    //billing
    AD_Ville__c, Country__c, AD_Ligne_2__c, State__c, AD_Code_Postal__c
    ) => {
    try{
        return axios.post(`${linkApi}/create-account/public`,
            Name, Prenom__c, Email__c, Origine_connecteur__c, Portail_secu__c,
            //contact
            City_contact__c, State_contact__c, Zip_contact__c, Country_contact__c, INDIV_Adresse_1_contact__c,
            //billing
            AD_Ville__c, Country__c, AD_Ligne_2__c, State__c, AD_Code_Postal__c
         );
    } catch (err) {
        return err
    }
}

export const postDonSAS = (
    address, email, firstName, lastName, price, IdCharge, object_charge, balance_transaction, calculated_statement_descriptor, currency,
    customer, description, paid, payment_method, country, type, receipt_url, status, mailNotify, Tributee_Name, 
    Type_of_Tribute, will_you_attend, name_of_guest, campaign, message, Subscription__c, Address_contact__c, City__c, City_contact__c, Country_contact__c,
    State__c, State_contact__c, Zip__c, Zip_contact__c,Number_of_Guests__c, Type_formulaire__c, Paye_ou_promesse__c
    ) => new Promise((resolve, reject)=>{
    try {
        resolve(
            axios.post(`${linkApi}/create-don-saas/public`, {
                Address__c: address,
                Email__c: email,
                First_name__c: firstName,
                Name: lastName,
                Montant__c: price,
                Id_paiement__c: IdCharge,
                object_charge__c: object_charge,
                balance_transaction__c: balance_transaction,
                calculated_statement_descriptor__c: calculated_statement_descriptor,
                currency__c: currency,
                customer__c: customer,
                description__c: description,
                paid__c: paid,
                payment_method__c: payment_method,
                country__c: country,
                type__c: type,
                receipt_url__c: receipt_url,
                status__c: status,
                Email_of_person_to_notify__c: mailNotify,
                Tributee_Name__c: Tributee_Name,
                Type_of_Tribute__c: Type_of_Tribute,
                Will_you_attend__c: will_you_attend,
                Name_of_Guests__c: name_of_guest,
                Campaign__c: campaign,
                Tribute_message__c: message, //new
                Subscription__c,
                Address_contact__c,
                City__c,
                City_contact__c, 
                Country_contact__c,
                State__c,
                State_contact__c,
                Zip__c,
                Zip_contact__c,
                Number_of_Guests__c,
                Type_formulaire__c,
                Paye_ou_promesse__c
            })
        )
    } catch (error) {
        reject(error)
    }
})

export const postDonSAS_v2 = async (step, respChargeRib, type) => {

    const datas = {
        Address__c: step.data.billing.address,
        Email__c: step.data.expo.step1.mail,
        First_name__c: step.data.expo.step1.firstName,
        Name: step.data.expo.step1.lastName,
        Montant__c: step.data.fees,
        Id_paiement__c: respChargeRib.data.id,
        object_charge__c: respChargeRib.data.object,
        balance_transaction__c: respChargeRib.data.balance_transaction,
        calculated_statement_descriptor__c: respChargeRib.data.calculated_statement_descriptor,
        currency__c: respChargeRib.data.currency,
        customer__c: respChargeRib.data.customer,
        description__c: respChargeRib.data.description,
        paid__c: respChargeRib.data.paid,
        payment_method__c: type,
        country__c: step.data.billing.country,
        type__c: respChargeRib.data.payment_method_details.card?.brand === undefined ? '' : respChargeRib.data.payment_method_details.card.brand ,
        receipt_url__c: respChargeRib.data.receipt_url,
        status__c: respChargeRib.data.status,
        Email_of_person_to_notify__c: step.data.expo.step1.mailToNotify,
        Tributee_Name__c: step.data.expo.step1.tribute,
        Type_of_Tribute__c: step.data.expo.step1.isDedicate ? step.data.expo.step1.isHonor ? 'In Honor Of' : 'In Memory Of' : ' ',
        Will_you_attend__c: '',
        Name_of_Guests__c: '',
        Campaign__c: '',
        Tribute_message__c: step.data.expo.step1.message,
        Subscription__c: '',
        Address_contact__c: step.data.expo.step1.adress,
        City__c: step.data.billing.city,
        City_contact__c: step.data.expo.step1.city, 
        Country_contact__c: step.data.expo.step1.country,
        State__c: step.data.billing.province,
        State_contact__c: step.data.expo.step1.province,
        Zip__c: step.data.billing.zip,
        Number_of_Guests__c:0,
        Zip_contact__c: step.data.expo.step1.zip
    }

   return await axios.post(`${linkApi}/create-don-saas/public`, datas)
}

export const promessePaye = async (datas,step,type_pay, type_formulaire, status) => {
    const data = {
        Address__c: step.data.expo.step1.address,
        Email__c: getUser() ? getUser().email : step.data.expo.step1.mail,
        First_name__c: step.data.expo.step1.firstName,
        Name: step.data.expo.step1.lastName,
        Montant__c: step.data.fees,
        Id_paiement__c: datas,
        object_charge__c: datas,
        balance_transaction__c: datas,
        calculated_statement_descriptor__c: datas,
        currency__c: datas,
        customer__c: datas,
        description__c: datas,
        paid__c: datas,
        payment_method__c: type_pay,
        country__c: step.data.billing.country,
        type__c: datas ,
        receipt_url__c: datas,
        status__c: '',
        Email_of_person_to_notify__c: step.data.expo.step1.mailToNotify,
        Tributee_Name__c: step.data.expo.step1.tribute,
        Type_of_Tribute__c: step.data.expo.step1.isDedicate ? step.data.expo.step1.isHonor ? 'In Honor Of' : 'In Memory Of' : ' ',
        Will_you_attend__c: '',
        Name_of_Guests__c: '',
        Campaign__c: '',
        Tribute_message__c: step.data.expo.step1.message,
        Subscription__c: '',
        Address_contact__c: step.data.expo.step1.adress,
        City__c: step.data.billing.city,
        City_contact__c: step.data.expo.step1.city, 
        Country_contact__c: step.data.expo.step1.country,
        State__c: step.data.billing.province,
        State_contact__c: step.data.expo.step1.province,
        Zip__c: step.data.billing.zip,
        Number_of_Guests__c:0,
        Zip_contact__c: step.data.expo.step1.zip,
        Paye_ou_promesse__c: status,
        Type_formulaire__c: type_formulaire
    }

    return await axios.post(`${linkApi}/create-don-saas/public`, data)
}

export const postDonSaS_Card = async (datas,step,type_pay, type_formulaire, status) => {
    const data = {
        Address__c: step.allSteps.step1.address,
        Email__c: getUser() ? getUser().email : step.allSteps.step1.mail,
        First_name__c: step.allSteps.step1.firstName,
        Name: step.allSteps.step1.lastName,
        Montant__c: step.finalPrice,
        Id_paiement__c: datas,
        object_charge__c: datas,
        balance_transaction__c: datas,
        calculated_statement_descriptor__c: datas,
        currency__c: datas,
        customer__c: datas,
        description__c: datas,
        paid__c: datas,
        payment_method__c: type_pay,
        country__c: step.billing.country,
        type__c: datas ,
        receipt_url__c: datas,
        status__c: '',
        Email_of_person_to_notify__c: step.allSteps.step1.mailToNotify,
        Tributee_Name__c: step.allSteps.step1.tribute,
        Type_of_Tribute__c: step.allSteps.step1.isDedicate ? step.allSteps.step1.isHonor ? 'In Honor Of' : 'In Memory Of' : ' ',
        Will_you_attend__c: '',
        Name_of_Guests__c: '',
        Campaign__c: '',
        Tribute_message__c: step.allSteps.step1.message,
        Subscription__c: '',
        Address_contact__c: step.allSteps.step1.adress,
        City__c: step.billing.city,
        City_contact__c: step.allSteps.step1.city, 
        Country_contact__c: step.allSteps.step1.country,
        State__c: step.billing.province,
        State_contact__c: step.allSteps.step1.province,
        Zip__c: step.billing.zip,
        Number_of_Guests__c:0,
        Zip_contact__c: step.allSteps.step1.zip,
        Paye_ou_promesse__c: status,
        Type_formulaire__c: type_formulaire
    }

    return await axios.post(`${linkApi}/create-don-saas/public`, data)
}
export const postDonSaS_Card_subscription = async (datas,step,type_pay, type_formulaire, status, subscription) => {
    const data = {
        Address__c: step.expo.step1.address,
        Email__c: getUser() ? getUser().email : step.expo.step1.mail,
        First_name__c: step.expo.step1.firstName,
        Name: step.expo.step1.lastName,
        Montant__c: step.expo.step1.price,
        Id_paiement__c: datas,
        object_charge__c: datas,
        balance_transaction__c: datas,
        calculated_statement_descriptor__c: datas,
        currency__c: datas,
        customer__c: datas,
        description__c: datas,
        paid__c: datas,
        payment_method__c: type_pay,
        country__c: step.billing.country,
        type__c: datas ,
        receipt_url__c: datas,
        status__c: '',
        Email_of_person_to_notify__c: step.expo.step1.mailToNotify,
        Tributee_Name__c: step.expo.step1.tribute,
        Type_of_Tribute__c: step.expo.step1.isDedicate ? step.expo.step1.isHonor ? 'In Honor Of' : 'In Memory Of' : ' ',
        Will_you_attend__c: '',
        Name_of_Guests__c: '',
        Campaign__c: '',
        Tribute_message__c: step.expo.step1.message,
        Subscription__c: subscription,
        Address_contact__c: step.expo.step1.adress,
        City__c: step.billing.city,
        City_contact__c: step.expo.step1.city, 
        Country_contact__c: step.expo.step1.country,
        State__c: step.billing.province,
        State_contact__c: step.expo.step1.province,
        Zip__c: step.billing.zip,
        Number_of_Guests__c:0,
        Zip_contact__c: step.expo.step1.zip,
        Paye_ou_promesse__c: status,
        Type_formulaire__c: type_formulaire
    }

    return await axios.post(`${linkApi}/create-don-saas/public`, data)
}

export const postPaymentIntent = (price, customer) => {
    try{
        return axios.post(`${linkApi}/create-payment-intent/public`, {price: price, customer});
    } catch (err) { return err }
}

export const postDon = (address, email, firstName, clientSecretId, price, help, AccountId,) => {
    try{
        return axios.post(`${linkApi}/create-don/public`, {Address__c: address, Email__c: email, First_name__c: firstName, Id_paiement__c: clientSecretId,
           Montant__c: price, Statut__c: "Payé", Help__c: help, Donateur__c: AccountId, Collecteur__c: "", Commentaire__c:""});
    } catch (err) { return err }
}

export const getUtilsDataByEmail = (email) => {
    try{
        return axios.get(`${urlApi}/stripe/${email}/get-utils-data/public`);
    } catch (err) { return err }
}

export const postStripe = (data, action) => {
    try{
        return axios.post(`${urlApi}/stripe/${action}/public`, data);
    } catch (err) { return err }
}

export const getStripe = (action) => {
    try{
        return axios.get(`${urlApi}/stripe/${action}/public`);
    } catch (err) { return err }
}

export const getStripeByItem = (item, action) => {
    try{
        return axios.get(`${urlApi}/stripe/${item}/${action}/public`);
    } catch (err) { return err }
}

export const updateDataStripe = (data) => {
    try{
        return axios.put(`${urlApi}/stripe/update-utils-data/public`, data);
    } catch (err) { return err }
}

export const generateTokenSocialLogin = (data) => {
    try{
        return axios.post(`${linkApi}/generate/token/social/login/public`, data);
    } catch (err) {
        return err
    }
}

export const createUser = (data) => {
    try{
        return axios.post(`${urlApi}/api/users/user/create/public`, data);
    } catch (err) {
        return err
    }
}
// split card create charge
export const createChargePayment = (data) => {
    try{
        return axios.post(`${urlApi}/stripe/create-charge-payment/public`, data);
    } catch (err) { return err }
}

//confirm subscription to server 
export const ConfirmSubscription = (pi) => {
    try{
        return axios.post(`${urlApi}/stripe/${pi}/confirmPaymentIntent/public`);
    } catch (err) { return err }
}
//retrieve PI
export const retrievePaymentIntent = (pi) => {
    try{
        return axios.post(`${urlApi}/stripe/${pi}/retrievePaymentIntent/public`);
    } catch (err) { return err }
}

//get files in don
export const getFiles = (files) => {
    try{
        return axios.get(`${linkApi}/${files}/fichiers/public`);
    }catch (err) { return err }
}

export const getDownFiles = (nomFile,extFile,idFile) => {
    try{
        return axios.get(`${linkApi}/${idFile}/ContentVersion/public`, {
            responseType: 'blob',
            data:{nomFichier:nomFile,extension:extFile,idfichier:idFile}    
        }
        );
    }catch (err) { return err }
}

//link customer to bank
export const createBankAccount = (btok, customer) => {
    const data = `source=${btok}`;
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
    if (this.readyState === this.DONE) {
    }
    });

    xhr.open("POST", `https://api.stripe.com/v1/customers/${customer}/sources`);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`);

    return xhr.send(data);
}

export const getBankAccount = (customer) => {
    const options = {
        method: 'GET',
        url: `https://api.stripe.com/v1/customers/${customer}/sources`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${stripeKey}`
        },
        data: {}
      };
      
      return axios.request(options)
   
}

export const createCustomerAch = (btok, customer) => {
    try{
        return axios.post(`${urlApi}/stripe/create-customer-ach/public`, {btok, customer});

    } catch (err) { return err }
}

export const linkBankAccount = (customer, btok) => {
    try{
        return axios.post(`${urlApi}/stripe/link-bank-account/public`, {customer, btok});

    } catch (err) { return err }
}

export const createChargeRib = (amount, currency, customer) => {
    try{
        return axios.post(`${urlApi}/stripe/create-charge/public`, {amount, currency, customer});

    } catch (err) { return err }
}

export const verifySourceCustomer = ( customerId, ba, min, max ) => {
    try{
        return axios.post(`${urlApi}/stripe/verify-source/public`, {customerId, ba, min, max});

    } catch (err) { return err }
}

export const getBankAccountToken = ( customerId ) => {
    try{
        return axios.post(`${urlApi}/stripe/${customerId}/retrieveBankAccount/public`);

    } catch (err) { return err }
}

// export const updateCustomerPayment = ( customerId, tokenId ) => {
//     try{
//         return axios.post(`${urlApi}/stripe/create-customer/public`, {customerId, method: 'update', tokenId});

//     } catch (err) { return err }
// }

export const updateCustomerPayment = (customerId, tokenId) => new Promise((resolve, reject)=>{
    resolve(
        axios.post(`${urlApi}/stripe/create-customer/public`, {customerId, method: 'update', tokenId})
    )
})

// export const VerifyToken = ( token ) => {
//     jwt.verify(token, process.env.SECRET_JWT, function (err,decoded) {
//         if(err){
//             // removeUserSession();
//             window.location.href = "/";
//         }
//     })
// }

export const paymentIntentCustomer = ( customerId, amount ) => {
    try{
        return axios.post(`${urlApi}/stripe/${customerId}/paymentIntent/public`, {amount});

    } catch (err) { return err }
}

// export const createChargeCustomerSaved = ( customerId, amount ) => {
//     const value = amount / 10000
//     try{
//         return axios.post(`${urlApi}/stripe/${customerId}/createChargeCustomerSaved/public`, {amount: value});

//     } catch (err) { return err }
// }

export const createChargeCustomerSaved = (customerId, amount) => new Promise((resolve, reject)=>{
    const value = amount / 10000
    try {
        resolve(
            axios.post(`${urlApi}/stripe/${customerId}/createChargeCustomerSaved/public`, {amount: value})
        )
    } catch (error) {
        reject(error)
    }
})

export const createSessionStorage = ( name, value ) => {
    sessionStorage.setItem(name, value.toString());
    localStorage.setItem(name, value.toString());
}

export const getOneCard = ( customer ) => {
    try{
        return axios.post(`${urlApi}/stripe/cards/public`, {customer});

    } catch (err) { return err }
}

export const getUserPortail = ( email, portail ) => {
    const data = {
        email,
        portail
    }
    try {
       return axios.get(`${linkApi}/findPivotUserPortail/${data.email}/${data.portail}/public`)
    } catch (error) {
        
    }
}

export const getSubscriptionUser = ( customer ) => {
    try {
       return axios.get(`${linkApi}/stripe/${customer}/get-subscription/public`)
    } catch (error) {
        
    }
}

export const setDefaultPaymentAbonent = ( customer ) => {
    try {
       return axios.get(`${urlApi}/stripe/getPaymentMethod/${customer}/public`)
    } catch (error) {
        
    }
}

export const paymentIntentSavedCard = ( customer, amount ) => {
    try {
       return axios.post(`${urlApi}/stripe/donate-intent/${customer}/${amount}/public`)
    } catch (error) {
        
    }
}

export const createNewSubscription = ( datas ) => {
    try {
       return axios.post(`${urlApi}/api/pivot/createNewSubscription/public`,datas)
    } catch (error) {
        console.log(error)
    }
}

export const createPI = ( priceId, customer_Id ) => {
    try {
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/stripe/create-subscription/public`,
            headers: {'Content-Type': 'application/json'},
            data: {priceId: priceId, customerId: customer_Id}
          };

         return axios.request(options)
        //     .then(function (response) {
        //         // setClientSecret(response.data.clientSecret);
        //         // sessionStorage.setItem('pi', response.data.clientSecret.id);
        //         // localStorage.setItem('pi', response.data.clientSecret.id);
        //     }).catch(function (error) {
        //     console.error(error);
        //   });
        //    return axios.post(`${urlApi}/stripe/donate-intent/${customer}/${amount}/public`)
    } catch (error) {
        
    }
}

export const createDonSasBase = (data) => {
    try{
        return axios.post(`${linkApi}/don-sas-base/public`, {data});
    } catch (err) { return err }
}

export const getCustomerStripeByEmail = (email) => {
    try {
        return axios.post(`${linkApi}/${email}/stripe-sync/public`);
    }catch (error) {return error}
}