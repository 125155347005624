import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './public/css/IndexScss/index.scss';
import "semantic-ui-css/semantic.min.css";
import App from './App';
import 'notyf/notyf.min.css';
import { Provider } from 'react-redux';
import store from './redux/store';

ReactDOM.render(
        <Provider store={store} >
            <App />
        </Provider>,
    document.getElementById('root')
);
