const initialStateUser = {
    user: []
}

const userReducer = (state = initialStateUser, action) =>{
    switch(action.type){
        case 'USER_SESSION':
            return action.payload
        default:
            return state
    }
}

export default userReducer;