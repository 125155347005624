import React, {useEffect, useState} from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Button, Grid, Image } from 'semantic-ui-react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {getUser} from "../utils/Common";
import { ConfirmSubscription, createChargeRib, getAccountByEmail, getStripe, getUtilsDataByEmail,
     linkBankAccount, postDonSAS, postStripe, retrievePaymentIntent, verifySourceCustomer, getDonationByAccountId,
     getBankAccountToken, getOneCard
    } from "../utils/axios";
import CardSeparated from '../components/CardPayment/CardPaymentDetails';
import SavedCard from '../components/CardPayment/CardSavedPayment';
import BankSavedNumber from '../components/BankPayment/BankSavedNumber';
import {useLocation, useHistory} from 'react-router-dom';
import currency from 'currency.js';
import Billing from '../components/Forms/BillingAdress';

const Abonnent = (props) => {

    const [priceId, setPriceId] = useState("");
    // eslint-disable-next-line
    const [clientSecret, setClientSecret] = useState('');
    const [FirstName, setFirstName] = useState('');
    // eslint-disable-next-line
    const [name, setName] = useState('');
    const [customer_id_bank, setCustomer_id_bank] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [donor, setDonor] = useState('');
    const[toggleState, setToggleState] = useState(1);
    const [display, setDisplay] = useState('block');
    const [displayNumber, setDisplayNumber] = useState(true);
    // eslint-disable-next-line
    const [pay, setPay] = useState('Card');
    // eslint-disable-next-line
    const [subscribe, setSubscribe] = useState('Card');
    const location = useLocation();
    const stripe = useStripe();
    const [disabled, setDisabled] = useState(true);

    const [listCard, setListCard] = useState(0);
    const [listBank, setListBank] = useState(0);
    const [last4Bank, setLast4Bank] = useState(0);
    const [routingBank, setRouting4Bank] = useState(0);
    const [paymentLst, setPaymentLst] = useState(0);
    // eslint-disable-next-line
    const [pri, setPri] = useState(0);
    
    const [displaybilling, setDisplayBilling] = useState('none');

    const addresse = localStorage.getItem('address') || sessionStorage.getItem('address');
    const price_dollar = (localStorage.getItem('price') || sessionStorage.getItem('price'));
    const feeActive = (localStorage.getItem('fee') || sessionStorage.getItem('fee'));
    
    const castFee = parseInt((3 * currency(price_dollar).value)/100).toFixed(0);
    const fee = parseInt(castFee)
    const castPrice = currency(price_dollar).value
    const total = (castPrice + fee) * 100
    let price = feeActive ? ( total )  : ( (currency(price_dollar).value) * 100)

    const tributeName = localStorage.getItem('tribute') || sessionStorage.getItem('tribute');
    const typeOftribute = localStorage.getItem('typeOftribute') || sessionStorage.getItem('typeOftribute');
    const mailNotify = localStorage.getItem('mailNotify') || sessionStorage.getItem('mailNotify');

    const tributeYes = useSelector(state => state.tribute);
    const finalPrice = useSelector(state => state.price);

    let history = useHistory();
    // let email = getUser().email;

    const [fees, setFees] = useState(false);
    const handleChangeFees = () =>{
        setFees(!fees);
    }

    const [billing, setBilling] = useState(false);
    const handleChangeBilling = () =>{
        setBilling(!billing)
        if (billing === false){
            setDisplayBilling('')
        }else{
            setDisplayBilling('none')
        }
    }



    useEffect(() => {

        if(getUser() !== null){
            setDonor(getUser().name)
            getAccountByEmail(getUser().email).then(res =>{
            // setAccountId(res.data[0].Id)
            getDonationByAccountId(res.data[0].Id)
                    .then(responseDon => {
                        if( responseDon.data.length !== undefined ){
                            setListCard(responseDon.data.length)
                        }
                    })
            setFirstName(res.data[0].FirstName)
            setName(res.data[0].LastName)
        })
        }

        if(  getUser() !== null ){
            getUtilsDataByEmail(getUser().email).then(response => {
                getOneCard(response.data.rows[0].customer_id).then(responsePM =>{
                    if(responsePM.data.data !== undefined){
                        setPaymentLst(responsePM.data.data.length)
                    }
                })
                setCustomer_id_bank(response.data.rows[0].customer_id);
                  getBankAccountToken(response.data.rows[0].customer_id).then(respBank => {
                      if( (respBank.data.bankAccounts.data.length === 0) || (respBank.data.bankAccounts.data.length === undefined) ) {
                      }else{
                          setListBank(respBank.data.bankAccounts.data.length)
                      setLast4Bank(respBank.data.bankAccounts.data[0].last4)
                      setRouting4Bank(respBank.data.bankAccounts.data[0].routing_number)
                      }
                  })
            })
        }
        

        

        const period = localStorage.getItem('period') || sessionStorage.getItem('period');
        const count = parseInt(localStorage.getItem('count') || sessionStorage.getItem('count'));
        setSubscribe(localStorage.getItem('subscription') || sessionStorage.getItem('subscription'));
        if (price === 0 || price === undefined) {
            props.history.push(`/new-don/${getUser().accountId}`)
        }

        getStripe('get-price').then(res => {
            const prices = res.data.prices;

            let unitAmount = [];

            for (let i = 0; i < prices.length; i++) {
                unitAmount.push(prices[i].unit_amount)
                if (price === prices[i].unit_amount) {
                    setPriceId(prices[i].id)
                }
            }

            if (!unitAmount.includes(price)) {
                postStripe({ productId: process.env.REACT_APP_STRIPE_PRODUCT, price, currency: "usd", period, count }, "create-price").then(resultCreatePrice => {
                    setPriceId(resultCreatePrice.data.result.id);
                })
            }
        })
        
        if (location.pathname === "/abonnent/subscription") {
            if (getUser() !== null) {
                getUtilsDataByEmail(getUser().email).then(res => {
                    if (priceId.length > 0) {
                        const options = {
                            method: 'POST',
                            url: `${process.env.REACT_APP_API_URL}/stripe/create-subscription/public`,
                            headers: {'Content-Type': 'application/json'},
                            data: {priceId: priceId, customerId: res.data.rows[0].customer_id}
                          };
                          
                          axios.request(options).then(function (response) {
                            setClientSecret(response.data.clientSecret);
                            sessionStorage.setItem('pi', response.data.clientSecret.id);
                            localStorage.setItem('pi', response.data.clientSecret.id);
                          }).catch(function (error) {
                            console.error(error);
                          });
                    }
                })
            }
        }
// eslint-disable-next-line
    }, [priceId]);

    const handleChangeRoutingNumber = (e) => {
        e.preventDefault();
        setRoutingNumber(e.target.value)
        }

    const handleAccountNumber = (e) => {
        e.preventDefault();
        setAccountNumber(e.target.value)
        if( e.target.value.length === 0) {
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }

    const handleSubmitRib = e => {
        e.preventDefault();
        if(routingNumber.length === 9){
            setDisplayNumber(true);
            setDisplay('none');
        const pi = localStorage.getItem('pi') || sessionStorage.getItem('pi');
        axios.get(`https://api.stripe.com/v1/customers/${customer_id_bank}/sources`, { //if bank account exist
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
            }}).then(respBankAccounts => {
                if(respBankAccounts.data.data.length === 1){
                    if (location.pathname === "/abonnent/donate") {
                        createChargeRib(price, "usd", customer_id_bank).then(respChargeRib =>{
                            postDonSAS(
                                addresse,
                                getUser().email,
                                FirstName,
                                donor,
                                price,
                                respChargeRib.data.id,
                                respChargeRib.data.object,
                                respChargeRib.data.balance_transaction,
                                respChargeRib.data.calculated_statement_descriptor,
                                respChargeRib.data.currency,
                                respChargeRib.data.customer,
                                respChargeRib.data.description,
                                respChargeRib.data.paid,
                                "ACH - EFT",
                                respChargeRib.data.source.country,
                                respChargeRib.data.source.type,
                                respChargeRib.data.receipt_url,
                                "pending",
                                mailNotify,
                                tributeName,
                                typeOftribute
                            )
                            .then(() =>{
                                history.push('/success')
                            })
                            .catch(error =>{
                                history.push('/error')
                                console.error("add donation SF error")
                            })
                        })
                        .catch(error =>{
                            history.push('/error')
                            //erreur create charge RIB
                        })
                    }
                    if (location.pathname === "/abonnent/subscription") {
                            //validate Subscriptions
                            ConfirmSubscription(pi).then(respConfirmSubscription =>{
                            const datas = respConfirmSubscription.data.paymentIntent.charges.data[0];
                            postDonSAS(
                                addresse,
                                getUser().email,
                                FirstName,
                                donor,
                                price,
                                datas.id,
                                datas.object,
                                datas.balance_transaction,
                                datas.calculated_statement_descriptor,
                                datas.currency,
                                datas.customer,
                                datas.description,
                                datas.paid,
                                "ACH - EFT",
                                datas.source.country,
                                datas.source.type,
                                datas.receipt_url,
                                "pending",
                                mailNotify,
                                tributeName,
                                typeOftribute
                            )
                            .then(() =>{
                                history.push('/success')
                            })
                            .catch(err =>{
                                history.push('/error')
                                console.error("error, add subscription SF")
                            })
                        })
                        .catch(err =>{
                            history.push('/error');
                        })
                    }   
                }else{

                    //1- create B-token
                    stripe.createToken('bank_account', {
                            country: 'US',
                            currency: 'usd',
                            routing_number: routingNumber,
                            account_number: accountNumber,
                            account_holder_name: getUser().name,
                            account_holder_type: 'individual',
                        })
                        .then(function(result) {
                            //link customer to bank account
                            linkBankAccount(customer_id_bank,result.token.id).then(respLinkBank => {
                                // verify source
                                 verifySourceCustomer(customer_id_bank, respLinkBank.data.id).then(() => {
                                    if (location.pathname === "/abonnent/donate") {
                                        // create charge for payment!!!
                                         createChargeRib(price *100, "usd", customer_id_bank)
                                         .then(() =>{
                                            history.push('/success');
                                            })
                                         .catch(err =>{
                                            history.push('/error');
                                            })
                                    }    
                                    const options = {
                                        method: 'POST',
                                        url: `${process.env.REACT_APP_API_URL}/stripe/create-subscription/public`,
                                        headers: {'Content-Type': 'application/json'},
                                        data: {priceId: priceId, customerId: customer_id_bank}
                                      };
                                      
                                      axios.request(options).then(function (responseSub) {
                                        ConfirmSubscription(responseSub.data.clientSecret.id)
                                            .then(() =>{
                                                    retrievePaymentIntent(responseSub.data.clientSecret.id).then(retrieveResponse =>{
                                                        const datas = retrieveResponse.data.retrieve.charges.data[0];
                                                        postDonSAS(
                                                            addresse,
                                                            getUser().email,
                                                            FirstName,
                                                            donor,
                                                            price,
                                                            datas.id,
                                                            datas.object,
                                                            datas.balance_transaction,
                                                            datas.calculated_statement_descriptor,
                                                            datas.currency,
                                                            datas.customer,
                                                            datas.description,
                                                            datas.paid,
                                                            "ACH - EFT",
                                                            datas.source.country,
                                                            datas.source.type,
                                                            datas.receipt_url,
                                                            "pending",
                                                            mailNotify,
                                                            tributeName,
                                                            typeOftribute
                                                        )
                                                        .then(() =>{
                                                            history.push('/success')
                                                        })
                                                        .catch(error =>{
                                                            history.push('/error')
                                                        })
                                                    })
                                                }).catch(error =>{
                                                    history.push('/error')
                                                })
                                      }).catch(function (error) {
                                        history.push('/error')
                                      });
                                })
                            }).catch(error =>{
                                history.push('/error')
                            })
                            
                        });  
                }
            })
            .catch(error =>{
                history.push('/error')
            })
        }else{
            setDisplayNumber(false);
            setTimeout(function(){ setDisplayNumber(true); }, 2000);
        }
}
  
    const toggleTab = (index) =>{
        if(index === 1){
            setPay("Card")
        }else{
            setPay("Rib")
        }
        setToggleState(index);
    }
    return (
        // <div className="page-abonnent">
            <>
             {/* abonnement */}
          
             <Grid.Column width={8} className="mobil-none">
                <Image className="img-piv" src={require('../../src/public/img/img_pivot.PNG')}/>
             </Grid.Column>

                    <Grid.Column width={8}>
                    <div className="content-tabs">
                    <h2>Gift Summary</h2>
                    <Grid centered columns={2} className="donor-info">
                       <Grid.Row>
                           <Grid.Column className='title'>Donor:</Grid.Column>
                           <Grid.Column className='value'>{donor}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                           <Grid.Column className='title'>Amount:</Grid.Column>
                           <Grid.Column className='value'>{price_dollar}</Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row>
                           <Grid.Column className='title'>Fee:</Grid.Column>
                           <Grid.Column className='value'>{currency(fee, {separator: ','}).format()}</Grid.Column>
                        </Grid.Row> */}
                        {/* <Grid.Row>
                           <Grid.Column className='title'>Frequency:</Grid.Column>
                           <Grid.Column className='value'>{subscribe}</Grid.Column>
                        </Grid.Row> */}
                        <Grid.Row>
                           <Grid.Column className='title'>Tribute:</Grid.Column>
                           <Grid.Column className='value'>{tributeYes ? "Yes": "No"} </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                           <Grid.Column className='title'></Grid.Column>
                           <Grid.Column className='value'>In honor of Jane Doe</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <input rel="fees" type="checkbox" id="fees" className="check-input" checked={fees} onChange={handleChangeFees}  /> 
                    <label htmlFor="fees" className='fees'><strong style={{fontWeight: 'bold'}}>I would like to help cover processing fees</strong> 
                    <p style={{fontWeight: '100'}}>(3% of gift amount + $0.30 will be added to your total billing amount)</p></label> 

                    <Grid centered columns={2} className="donor-info">
                    <Grid.Row>
                        <Grid.Column className='title'>Total to be billed:</Grid.Column>
                        <Grid.Column className='value'> {finalPrice} </Grid.Column>
                    </Grid.Row>
                    </Grid>

                    
                    <input rel="billing" type="checkbox" id="bill" className="check-input" checked={billing} onChange={handleChangeBilling}  /> 
                    <label htmlFor="bill" className='bill'><strong style={{fontWeight: 'bold'}}>billing address</strong> </label> 
                    <div style={{display: displaybilling}}><Billing/></div>


                    <div className="card-methd">
                    <p className="methd">Payment Details</p>
                        <div className="bloc-tabs">                    
                            <div className={toggleState === 1 ? "tabs active-tabs" : "tabs" } onClick={ () => toggleTab(1)}>Credit card</div>
                            <div className={toggleState === 2 ? "tabs active-tabs" : "tabs" } onClick={ () => toggleTab(2)}>Bank transfer</div>
                        </div>
                        
                       
                        {/* DEBUT */}
                        <div className="payment-details">
                            <div className={toggleState === 1 ? "content active-content" : "content" }>
                                    {/* {listCard !== undefined ? (<>
                                     {listCard > 0 ? (<SavedCard client={customer_id_bank} prix={price} />
                                        ) : (<div><CardSeparated/></div>)}
                                        </>): (<></>)} */}

                                        { listCard !== undefined ? (<>
                                        {(listCard > 0) && (paymentLst > 0) ? (<SavedCard client={customer_id_bank} prix={price} />):(<><CardSeparated/></>)}
                                        </>):(<><CardSeparated/></>)
                                        }
                            </div>
                        {/* FIN */}

                            <div className={toggleState === 2 ? "content active-content" : "content" }>
                                    {/* <BankPayment/> */}
                                    {listBank !== 0 ? (<>
                                    <BankSavedNumber last4={last4Bank} routing={routingBank} />
                                        </>):(<>

                                    <form id="payment-form" className="col s12" onSubmit={handleSubmitRib}>
                                        <div className="input-group input-group-icon">
                                            <label>Routing number</label>
                                            <input type="text" name="RoutingNumber" placeholder="Routing number ( 9 digit number )" onChange={handleChangeRoutingNumber} />
                                        </div>
                                        <div className="input-group input-group-icon">
                                            <label>Account number</label>
                                            <input type="text" name="AccountNumber " placeholder="Account number ( 000123456789 )" onChange={handleAccountNumber}/>
                                        </div>
                                        <div hidden={displayNumber} >
                                            <center><p style={{ color: 'red'}} >Routing number must be 9 digit !!!</p></center>
                                        </div>
                                        <Button className="btn-pay" style ={{ display: display}} disabled={disabled}><span id="button-text">Submit</span></Button>
                                    </form>  
                                    
                                    </>)}
                            </div> 
                        </div>
                    </div>
            </div>
                    </Grid.Column>
               
         
            </>
           
        // </div>
    );
};

export default Abonnent;