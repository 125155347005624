import React, { Component } from 'react';
import {Button, Container} from 'semantic-ui-react';
import { postDonSAS, retrievePaymentIntent } from '../../utils/axios';
import { getUser } from '../../utils/Common';

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
           isDisable: true
        }
    }

    componentDidMount = () =>{

        const url= window.location.href.split("?")[1]
        console.log(window.location.href.split("?"))
        if (url !== undefined){
            const clientSecret = url.split('&')
            const cli = clientSecret[0].split('=')

            const step1 = localStorage.getItem('allData') || sessionStorage.getItem('allData')
            const bill = localStorage.getItem('billing') || sessionStorage.getItem('billing')
            const step = JSON.parse(step1)

            if((step1 === undefined) || (step1 === null) || (step1 === "")){
                this.setState({isDisable: false})
            }else{
                retrievePaymentIntent(cli[1]).then(resp =>{
                const datas = resp.data.retrieve.charges.data[0]

                postDonSAS(
                    bill === null ? "" : bill.address, // addresse,
                    getUser() ? getUser().email : step.expo.step1.mail,
                    step.expo.step1.firstName, // FirstName,
                    step.expo.step1.lastName, // LastName,
                    step.fees,
                    datas.id,
                    datas.object,
                    datas.balance_transaction,
                    datas.calculated_statement_descriptor,
                    datas.currency,
                    datas.customer,
                    datas.description,
                    datas.paid,
                    "Credit Card",
                    step.expo.step1.country,
                    datas.payment_method_details.card.brand,
                    datas.receipt_url,
                    datas.status,
                    step.expo.step1.mailToNotify, // mailNotify,
                    step.expo.step1.tribute, // tributeName,
                    step.expo.step1.isDedicate
                      ? step.expo.step1.isHonor
                        ? "In Honor Of"
                        : "In Memory Of"
                      : " ",
                    " ", // Will_you_attend__c
                    " ", // Name_of_Guests__c
                    "EOFY22 Appeal", // Campaign__c
                    step.expo.step1.message,
                    localStorage.getItem('periode') || sessionStorage.getItem('periode'),
                    step.expo.step1.adress,
                    bill === null ? "" : bill.city,
                    step.expo.step1.city,
                    step.expo.step1.country,
                    bill === null ? "" : bill.province,
                    step.expo.step1.province,
                    bill === null ? "" : bill.zip,
                    step.expo.step1.zip,
                    " ",//pax
                    "EOFY22 Appeal",//Type_formulaire
                    "Payé"
                ).then(() =>{
                    this.setState({isDisable: false})
                    sessionStorage.removeItem('allData');
                    localStorage.removeItem('allData');
    
                    sessionStorage.removeItem('billing');
                    localStorage.removeItem('billing');
                })
            })
            }
        }else{
            this.setState({isDisable: false})
        }
        
        
        
        
    }


    render() {

        const {isDisable} = this.state
      
        return(
            <div className="bg-successerror">
                <Container text className="content-successeror">
                    <h1>MISAOTRA BETSAKA!</h1>
                    <h2>(Thank you very much!)</h2>
                    <p> 
                    When you give to Pivot, you’re not only supporting the 
                    delivery of lifesaving care for the communities we serve, 
                    but also investing in the future of the country’s public 
                    health system as we work in partnership with the national 
                    government to achieve sustainable universal health coverage for all.
                    </p>
                    <a href="https://www.pivotworks.org/"><Button disabled={isDisable} >Back to Pivot's website</Button></a>
                </Container> 
            </div>
         

        )
    }

}

export default Success
