import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react'

class FooterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

 

    render() {
       
        return(
            <>
                <div className="ui vertical footer segment form-page">
                    <Menu secondary className="navbar-footer">
                        <Menu.Menu  className="social-network">
                            <div className="link-text">
                            <a href="https://www.pivotworks.org/contact/"><Menu.Item><span className="text">Contact us</span></Menu.Item> </a>
                            </div>
                        </Menu.Menu>
                   </Menu>
        
                </div>
            </>

        )
    }

}

export default FooterPage
